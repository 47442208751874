import { useState } from "react";
import "../../styles/Super.css";
import SuperAdminHeader from "./Header";

// Initial Sample Data for customer types and their permissions
const initialData = [
    {
        customerType: "Guest",
        permissions: [
            "Browse Products",
            "Add to Cart",
            "Checkout as Guest",
        ],
    },
    {
        customerType: "Registered",
        permissions: [
            "Browse Products",
            "Add to Cart",
            "Order History",
            "Save Wishlist",
        ],
    },
    {
        customerType: "Wholesale",
        permissions: [
            "Bulk Discounts",
            "Custom Shipping",
            "Tax Exemptions",
        ],
    },
];

// Available customer types
const customerTypes = ["Guest", "Registered", "Wholesale", "VIP", "B2B", "Subscription"];

const CustomerTypePermissions = () => {
    const [permissionsData, setPermissionsData] = useState(initialData);
    const [newCustomerType, setNewCustomerType] = useState("");
    const [newPermissions, setNewPermissions] = useState([]);
    const [selectedCustomerType, setSelectedCustomerType] = useState("");
    const [editingIndex, setEditingIndex] = useState(null);

    // Adding a new customer type with permissions
    const handleAdd = () => {
        if (newCustomerType && newPermissions.length) {
            const newData = [...permissionsData, { customerType: newCustomerType, permissions: newPermissions }];
            setPermissionsData(newData);
            setNewCustomerType("");
            setNewPermissions([]);
        }
    };

    // Editing an existing customer type and permissions
    const handleEdit = (index) => {
        const editedData = [...permissionsData];
        editedData[index] = { customerType: selectedCustomerType, permissions: newPermissions };
        setPermissionsData(editedData);
        setEditingIndex(null);
        setSelectedCustomerType("");
        setNewPermissions([]);
    };

    // Deleting a customer type and its permissions
    const handleDelete = (index) => {
        const updatedData = [...permissionsData];
        updatedData.splice(index, 1);
        setPermissionsData(updatedData);
    };

    // Selecting a customer type to edit
    const handleSelectToEdit = (index) => {
        const dataToEdit = permissionsData[index];
        setEditingIndex(index);
        setSelectedCustomerType(dataToEdit.customerType);
        setNewPermissions(dataToEdit.permissions);
    };

    return (
        <SuperAdminHeader>
            <div className="fixed-top-bar">
                <div className="card">
                    {/* <h5 className="m-2">Invoice</h5> */}
                    <h5 className="m-2">Customer Type Permissions</h5>
                    <div className="card-body">

                        {/* Form for adding or editing customer type permissions */}
                        <div className="card p-3 mb-4">
                            <div className="row">
                                <div className="col-md-4">
                                    <label className="mb-1">Customer Type:</label>
                                    <select
                                        className="form-control"
                                        value={editingIndex !== null ? selectedCustomerType : newCustomerType}
                                        onChange={(e) => editingIndex !== null ? setSelectedCustomerType(e.target.value) : setNewCustomerType(e.target.value)}
                                    >
                                        <option value="">Select Type</option>
                                        {customerTypes.map((type) => (
                                            <option key={type} value={type}>{type}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className="col-md-4">
                                    <label className="mb-1">Permissions (comma separated):</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={newPermissions.join(", ")}
                                        onChange={(e) => setNewPermissions(e.target.value.split(",").map((perm) => perm.trim()))}
                                    />
                                </div>
                                <div className="col-md-4 d-flex align-items-end">
                                    {editingIndex !== null ? (
                                        <button className="btn btn-warning" onClick={() => handleEdit(editingIndex)}>
                                            Update
                                        </button>
                                    ) : (
                                        <button className="btn btn-primary" onClick={handleAdd}>
                                            Add
                                        </button>
                                    )}
                                </div>
                            </div>
                        </div>

                        {/* Displaying the list of customer types and their permissions */}
                        <div className="table-wrapper">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>S.No</th>
                                        <th>Customer Type</th>
                                        <th>Permissions</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {permissionsData.map((data, index) => (
                                        <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td>{data.customerType}</td>
                                            <td>{data.permissions.join(", ")}</td>
                                            <td>
                                                <button className="btn btn-sm btn-secondary mx-1" onClick={() => handleSelectToEdit(index)}>Edit</button>
                                                <button className="btn btn-sm btn-danger mx-1" onClick={() => handleDelete(index)}>Delete</button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </SuperAdminHeader>
    );
};

export default CustomerTypePermissions;
