import { nanoid } from "nanoid"
import React, { useEffect, useState } from "react"
import * as Feather from "react-feather"
import { Link, useNavigate } from "react-router-dom"
import { api, baseURL, customerReqConfig } from "../../include/api"
import { ERROR, SUCCESS } from "../../include/constant"
import { discountPrice, getCustomer, isEmptyObj } from "../../include/function"
import { useNotification } from "../../Toast/ToastProvider"
import Spinner from "../add-ons/Spinner"
import { useCart } from "../context/UserCartCountContext"
import CustomerHeader from "./Header"

const CustomerWishlist = () => {
    const customer = getCustomer();
    const [fetchLoading, setFetchLoading] = useState(true)
    // const [categoryList, setCategoryList] = useState({})
    const [wishlistData, setWishlistData] = useState({})
    const [cartData, setCartData] = useState({})
    const toast = useNotification()
    const { fetchCartCount } = useCart(); // Get totalCartItems from context

    useEffect(() => {
        getCartData()
        getWishlist()
    }, [])

    const getCartData = () => {
        if (customer) {
            api.get('/cart/?customerId=' + customer.id, customerReqConfig()).then(response => {
                if (response.status === 200) {
                    setCartData(response.data.data)
                }
            }).catch(error => {
                setCartData([])
            }).finally()
        } else {
            setCartData([])
        }
    }

    const getWishlist = () => {
        setFetchLoading(true)
        api.get('/wishlist/?customerId=' + customer.id, customerReqConfig()).then(response => {
            if (response.status === 200) {
                setWishlistData(response.data.data)
            }
        }).catch(error => {
            setWishlistData({})
        }).finally(() => setFetchLoading(false))
    }

    return <div className="fixed-top-bar">
        <CustomerHeader enableSearch enableButtons />
        {fetchLoading ? <div className="py-5"><Spinner color="danger" /></div> : <div className="container">
            {/* {!isEmptyObj(categoryList) && categoryList.childs.map(child => {
                return child.products && <ChildCategory child={child} key={nanoid()} />
            })} */}
            {/* {!isEmptyObj(categoryList) && categoryList.childs[1] &&
                <ChildCategory child={categoryList.childs[1]} key={nanoid()} />
            } */}
            <div className="w-100 mt-3">
                <div className="p-3">
                    <h5 className="m-0 fw-bold text-dark">Your Wishlist!</h5>
                </div>
                <div className="container">
                    <div className="row">
                        {wishlistData?.products?.map(product => {
                            return <Product product={product} customer={customer} toast={toast} fetchWishList={getWishlist} cartData={cartData} fetchcart={getCartData} key={nanoid()} fetchCartCount={fetchCartCount} />
                        })}
                    </div>
                </div>
            </div>
        </div>
        }
    </div>
}

const ChildCategory = ({ child }) => {
    return child.products.length > 0 && <div className="w-100 mt-3">
        <div className="p-3">
            <h5 className="m-0 fw-bold text-dark">Your Wishlist!</h5>
        </div>
        <div className="container">
            <div className="row">
                {child.products.map(product => {
                    return <Product product={product} key={nanoid()} />
                })}
            </div>
        </div>
    </div>
}

const Product = ({ product, toast, customer, fetchWishList, cartData, fetchcart, fetchCartCount }) => {
    const navigate = useNavigate()

    const isInCart = () => cartData && cartData[0] ? cartData[0].cartItems?.filter(c => String(c.productId) === String(product.id)).length > 0 : false

    const handleAddToCart = (e) => {
        if (!isEmptyObj(customer)) {
            if (cartData.length > 0 && !isInCart()) {
                //cart exist new item adding
                console.log('innn');
                let productsDisValue = 0;
                if (product.discountMode === 'percent') {
                    //calculating dis amt for given percent using price
                    productsDisValue += parseFloat(product.discountValue) / 100 * parseFloat(product.price)
                } else if (product.discountMode === 'amount') {
                    productsDisValue += parseFloat(product.discountValue)
                }

                let itemsPrice = (cartData[0].itemsPrice ? parseFloat(cartData[0].itemsPrice) : 0) + (product.price ? parseFloat(product.price) : 0);
                let totalDiscountValue = (cartData[0].totalDiscountValue ? parseFloat(cartData[0].totalDiscountValue) : 0) + productsDisValue;
                let totalDeliveryCharge = (cartData[0].totalDeliveryCharge ? parseFloat(cartData[0].totalDeliveryCharge) : 0) + (product.deliveryCharge ? parseFloat(product.deliveryCharge) : 0);

                const data = {
                    customerId: cartData[0].customerId,
                    itemsPrice: itemsPrice,
                    totalDiscountValue: totalDiscountValue,
                    discountMode: product.discountMode ? product.discountMode : cartData[0].discountMode,
                    totalDeliveryCharge: totalDeliveryCharge,
                    totalPrice: itemsPrice - totalDiscountValue + totalDeliveryCharge,
                    // totalPrice: (cartData[0].totalPrice ? parseFloat(cartData[0].totalPrice) : 0) + (product.price ? parseFloat(product.price) : 0) - (product.discountValue) + (product.deliveryCharge),
                    cartItems: [
                        ...cartData[0].cartItems,
                        {
                            productId: product.id,
                            qty: 1,
                            price: product.price,
                            discountMode: product.discountMode,
                            discountValue: product.discountValue,
                            deliveryCharge: product.deliveryCharge,
                        }

                    ]
                }
                //
                api.put('/cart/?id=' + cartData[0].id, data, customerReqConfig()).then(response => {
                    if (response.status === 200) {
                        toast({ type: SUCCESS, message: response.data.message })
                        fetchcart()
                        fetchCartCount()
                    }
                }).catch(error => {
                    error.response ?
                        toast({ type: ERROR, message: error.response.data.message }) :
                        toast({ type: ERROR, message: error.message })
                }).finally()
            } else if (cartData.length > 0 && isInCart()) {
                //cart exist, item also exist qty increase

                let productsDisValue = 0;
                if (product.discountMode === 'percent') {
                    //calculating dis amt for given percent using price
                    productsDisValue += parseFloat(product.discountValue) / 100 * parseFloat(product.price)
                } else if (product.discountMode === 'amount') {
                    productsDisValue += parseFloat(product.discountValue)
                }

                let cartItemIndex = cartData[0].cartItems.findIndex(c => String(c.productId) === String(product.productId))
                cartData[0].cartItems[cartItemIndex].qty += 1;

                let itemsPrice = (cartData[0].itemsPrice ? parseFloat(cartData[0].itemsPrice) : 0) + (product.price ? parseFloat(product.price) : 0);
                let totalDiscountValue = (cartData[0].totalDiscountValue ? parseFloat(cartData[0].totalDiscountValue) : 0) + productsDisValue;
                let totalDeliveryCharge = (cartData[0].totalDeliveryCharge ? parseFloat(cartData[0].totalDeliveryCharge) : 0);

                const data = {
                    customerId: cartData[0].customerId,
                    itemsPrice: itemsPrice,
                    totalDiscountValue: totalDiscountValue,
                    discountMode: (product.discountMode ? product.discountMode : cartData[0].discountMode),
                    totalDeliveryCharge: totalDeliveryCharge,
                    totalPrice: itemsPrice - totalDiscountValue + totalDeliveryCharge,
                    cartItems: [
                        ...cartData[0].cartItems,
                    ]
                }
                //
                api.put('/cart/?id=' + cartData[0].id, data, customerReqConfig()).then(response => {
                    if (response.status === 200) {
                        toast({ type: SUCCESS, message: response.data.message })
                        fetchcart()
                        fetchCartCount()
                    }
                }).catch(error => {
                    error.response ?
                        toast({ type: ERROR, message: error.response.data.message }) :
                        toast({ type: ERROR, message: error.message })
                }).finally()
            } else if (cartData.length === 0 && !isInCart()) {
                //No cart exist new cart and item adding

                let totalDiscountValue = 0;
                if (product.discountMode === 'percent') {
                    //calculating dis amt for given percent using price
                    totalDiscountValue += parseFloat(product.discountValue) / 100 * parseFloat(product.price)
                } else if (product.discountMode === 'amount') {
                    totalDiscountValue += parseFloat(product.discountValue)
                }
                const data = {
                    customerId: customer ? customer.id : '0',
                    itemsPrice: product.price,
                    totalDiscountValue: totalDiscountValue,
                    discountMode: product.discountMode,
                    totalDeliveryCharge: product.deliveryCharge,
                    totalPrice: parseFloat(product.price) - (product.discountValue ? parseFloat(product.discountValue) : 0) + (product.deliveryCharge ? parseFloat(product.deliveryCharge) : 0),
                    cartItems: [
                        {
                            productId: product.id,
                            qty: 1,
                            price: product.price,
                            discountMode: product.discountMode,
                            discountValue: product.discountValue,
                            deliveryCharge: product.deliveryCharge,
                        }
                    ]
                }
                //
                api.post('/cart/', data, customerReqConfig()).then(response => {
                    if (response.status === 200) {
                        fetchcart()
                        toast({ type: SUCCESS, message: response.data.message })
                        // setOrderPlaced(true)
                        fetchCartCount()
                    }
                }).catch(error => {
                    error.response ?
                        toast({ type: ERROR, message: error.response.data.message }) :
                        toast({ type: ERROR, message: error.message })
                }).finally()
            }
        } else if (isEmptyObj(customer)) {
            navigate('/login')

            // if (!isInCart()) {
            //     let cartItem = {
            //         id: id,
            //         qty: 1,
            //         // productId: id,
            //         price: product.price,
            //         discountMode: product.discountMode,
            //         discountValue: product.discountValue,
            //         deliveryCharge: product.deliveryCharge,
            //     }
            //     let cart = [...localCart, cartItem]
            //     setLocalCart(cart)
            //     setCart(cart)//local storage 
            // }
        }

    }

    const handleRemoveItemFromWishlist = () => {
        if (customer.id && product.id) {
            api.delete(`/wishlist/?customerId=${customer.id}&productId=${product.id}`, customerReqConfig()).then(response => {
                if (response.status === 200) {
                    fetchWishList()
                    toast({ type: SUCCESS, message: response.data.message })
                    // setOrderPlaced(true)
                }
            }).catch(error => {
                error.response ?
                    toast({ type: ERROR, message: error.response.data.message }) :
                    toast({ type: ERROR, message: error.message })
            })
        }
    }

    return <div className="col-12 col-sm-6 col-lg-4 col-xl-3">
        <div className="text-center d-block p-3 border my-2 text-dark">
            <div className="text-end">
                <i><Feather.Heart fill="#EA5757" stroke="#EA5757" /></i>
            </div>
            <Link to={"/product/" + product.id} >

                <img className="fit-contain img-nor hover-scale" src={product.mainImage && baseURL + '/' + product.mainImage} alt="" />
                <h6 className="my-2 text-truncate">{product.name}</h6>
                {!product.discountValue || product.discountValue === '' || Number(product.discountValue) === 0
                    ?
                    <span>
                        <h6 className="fw-bold">&#8377;{Math.ceil(product.price)}</h6>
                        {/* <div className="hstack gap-1">
                            <button className="btn text-primary fw-bold add-to-cart">Add To Cart</button>
                            <button className="btn text-danger fw-bold add-to-cart my-1" onClick={handleRemoveItemFromWishlist}><i className="far fa-trash-alt  font-weight-normal" /> Remove</button>
                        </div> */}
                    </span>
                    :
                    <h6 className="fw-bold">
                        <span>&#8377;{Math.ceil(discountPrice(product.price, product.discountValue, product.discountMode))}</span>
                        <del className="text-muted mx-2">&#8377;{Math.ceil(product.price)}</del>
                        <span className="text-success">{product.discountLabel}</span>
                        {/* <div className="hstack gap-1">
                            <button className="btn text-primary fw-bold add-to-cart">Add To Cart</button>
                            <button className="btn text-danger fw-bold  my-1" onClick={handleRemoveItemFromWishlist}><i className="far fa-trash-alt  font-weight-normal" /> Remove</button>
                        </div> */}
                        <div>
                        </div>
                    </h6>
                }
            </Link>
            <div className="hstack gap-2">
                {cartData.length > 0
                    ? cartData[0]?.editable === 1
                        ? <><button className="btn text-primary fw-bold add-to-cart" onClick={handleAddToCart}>Add To Cart</button>
                            <button className="btn text-danger fw-bold  my-1" onClick={handleRemoveItemFromWishlist}><i className="far fa-trash-alt  font-weight-normal" /> Remove</button>
                        </>
                        : <button className="btn text-danger fw-bold  my-1" style={{ marginLeft: '65px' }} onClick={handleRemoveItemFromWishlist}><i className="far fa-trash-alt  font-weight-normal" /> Remove</button>
                    : <><button className="btn text-primary fw-bold add-to-cart" onClick={handleAddToCart}>Add To Cart</button>
                        <button className="btn text-danger fw-bold  my-1" onClick={handleRemoveItemFromWishlist}><i className="far fa-trash-alt  font-weight-normal" /> Remove</button>
                    </>}
            </div>
        </div>
    </div>
}

export default CustomerWishlist
