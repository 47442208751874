import React, {  useEffect } from "react";
import { Gift, LogOut, ShoppingCart, User, DollarSign, FileText, List, Map, Settings, BarChart2 } from 'react-feather';
import { getAdmin } from '../../include/function';

const user = getAdmin();

const rolesWithView = user.roleProperties
    ?.filter(rp => rp.pageUrl && rp.view === "true")
    .map(mrp => mrp.pageUrl);

let menuContent = [];

menuContent.push({
    key: 'dashboard',
    url: "/admin/dashboard",
    isTitle: false,
    text: "Dashboard",
    Icon: BarChart2
});

if (user.createdBy === 'super' || rolesWithView?.includes("/admin/order") || user.roleProperties?.length === 1) {
    menuContent.push({
        key: 'order',
        url: "/admin/order",
        isTitle: false,
        text: "Order",
        Icon: ShoppingCart,
    });
}

if (user.createdBy === 'super' || rolesWithView?.includes("/user/user-management")) {
    menuContent.push({
        key: 'usermanagement',
        url: "/user/user-management",
        isTitle: false,
        text: "User Management",
        Icon: User,
    });
}

menuContent.push(
    {
        key: "sales",
        isTitle: false,
        text: "Sales",
        Icon: DollarSign,
        children: [
            ...(user.createdBy === 'super' || rolesWithView?.includes("/admin/sales/orders")
            ?[{
                key: "orders",
                url: "/admin/sales/orders",
                isTitle: false,
                text: "Orders",
                Icon: List,
                parentKey: 'sales'
            }]
            : [])
        ]
    },
    {
        key: "catalogue",
        isTitle: false,
        text: "Catalogue",
        Icon: Map,
        children: [
            ...(user.createdBy === 'super' || rolesWithView?.includes("/admin/category")
            ? [{
                key: "category",
                url: "/admin/category",
                isTitle: false,
                text: "Category",
                Icon: List,
                parentKey: 'catalogue'
            }]
            : []),
            ...(user.createdBy === 'super' || rolesWithView?.includes("/admin/brand")
            ? [{
                key: "brand",
                url: "/admin/brand",
                isTitle: false,
                text: "Brand",
                Icon: List,
                parentKey: 'catalogue'
            }]
            : []),
            ...(user.createdBy === 'super' || rolesWithView?.includes("/admin/product")
                ? [{
                    key: "products",
                    url: "/admin/product",
                    isTitle: false,
                    text: "Products",
                    Icon: List,
                    parentKey: 'catalogue'
                }]
                : [])
        ]
    },
    {
        key: "reports",
        isTitle: false,
        text: "Reports",
        Icon: FileText,
        children: [
            ...(user.createdBy === 'super' || rolesWithView?.includes("/admin/reports/products")
            ? [{
                key: "products",
                url: "/admin/reports/products",
                isTitle: false,
                text: "Products",
                Icon: Settings,
                parentKey: 'reports'
            }]
            : []),
            ...(user.createdBy === 'super' || rolesWithView?.includes("/admin/reports/orders")
            ? [{
                key: "orders",
                url: "/admin/reports/orders",
                isTitle: false,
                text: "Orders",
                Icon: Settings,
                parentKey: 'reports'
            }]
            : []),
            ...(user.createdBy === 'super' || rolesWithView?.includes("/admin/reports/items")
            ? [{
                key: "items",
                url: "/admin/reports/items",
                isTitle: false,
                text: "Items",
                Icon: Settings,
                parentKey: 'reports'
            }]
            : [])
        ]
    },
    {
        key: 'profile',
        url: "/admin/profile",
        isTitle: false,
        text: "Profile",
        Icon: User,
    },
    {
        key: 'logout',
        url: "/admin/logout",
        isTitle: false,
        text: "Logout",
        Icon: LogOut,
    }
);

const getUserMenuItems = () => {
    return menuContent;
};

export default getUserMenuItems;
