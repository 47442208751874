import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import Papa from "papaparse";
import { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { api, superAdminReqConfig } from "../../include/api";
import "../../styles/Super.css";
import Flyout from "../common/Flyout";
import SuperAdminHeader from "./Header";

const Invoice = ({ from }) => {
    const [fetchLoading, setFetchLoading] = useState(true);
    const [invoiceData, setInvoiceData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [fromDate, setFromDate] = useState("");
    const [toDate, setToDate] = useState("");
    const [shippingDatas, setShippingDatas] = useState([]);
    const [settingsData, setSettingsData] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const [isFlyoutVisible, setIsFlyoutVisible] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [invObj, setInvObj] = useState(null);
    const [cusObj, setCusObj] = useState(null);

    const [shippingData, setShippingData] = useState({
        trackingNumber: "",
        carrier: "",
        shippingStatus: "Shipped",
        shippingMethod: "",
        shippingCost: "",
        loadingCharge: "",
        unloadingCharge: "",
        address: "",
        city: "",
        state: ""
    });

    const [orderId, setOrderId] = useState(null);
    const getInvoice = (afterGet) => {
        setFetchLoading(true);
        api.get('/super_sales_invoice/', superAdminReqConfig()).then(response => {
            if (response.status === 200) {
                setInvoiceData(response.data.data);
                setFilteredData(response.data.data);
                if (afterGet) afterGet(response.data.data);
            }
        }).catch(error => {
            setInvoiceData([]);
            setFilteredData([]);
            if (afterGet) afterGet([]);
        }).finally(() => setFetchLoading(false));
    };

    useEffect(() => {
        api.get('/settings/', superAdminReqConfig()).then(response => {
            if (response.status === 200) {
                setSettingsData(response.data.data)
            }
        }).catch(error => {
            setSettingsData([])
        })
    }, [])

    const getShippingData = () => {
        api.get('/shipping/', superAdminReqConfig()).then(response => {
            if (response.status === 200) {
                setShippingDatas(response.data.data);
            }
        }).catch(error => {
            console.error("Failed to fetch shipping data", error);
        });
    };

    useEffect(() => {
        getInvoice();
        getShippingData();
    }, []);

    const toggleFlyout = (invoice, from) => {
        setIsFlyoutVisible(!isFlyoutVisible)
        if (from === 'invoice') {
            setInvObj(invoice)
            setCusObj(null)
        }
        else if (from === 'customer') {
            setCusObj(invoice)
            setInvObj(null)
        }
        else {
            setInvObj(null)
            setCusObj(null)
        }
    };

    // Handle Date Filter
    const handleFilter = () => {
        const from = new Date(fromDate);
        const to = new Date(toDate);

        const filtered = invoiceData.filter((data) => {
            const lastUpdated = new Date(data.date);
            return lastUpdated >= from && lastUpdated <= to;
        });

        setFilteredData(filtered);
    };

    // // Handle Search Filter
    // const handleSearch = (query) => {
    //     setSearchQuery(query);

    //     const filtered = invoiceData.filter((data) => {
    //         return (
    //             data.order_id?.toLowerCase().includes(query?.toLowerCase()) ||
    //             data.customer_name?.toLowerCase().includes(query?.toLowerCase()) ||
    //             data.payment_method?.toLowerCase().includes(query?.toLowerCase())
    //         );
    //     });

    //     setFilteredData(filtered);
    // };

    // const handleOpenModal = (id, address, city, state) => {
    const handleOpenModal = (id, customerId, address, city, state) => {
        setOrderId(id);
        setShippingData((prevData) => ({
            ...prevData,
            customerId: customerId,
            address: address,
            city: city,
            state: state
        }));
        setShowModal(true);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setShippingData((prevData) => ({
            ...prevData,
            [name]: value
        }));
    };

    const handleSubmit = () => {
        const data = {
            orderId,
            ...shippingData
        };
        api.post('/shipping/', data, superAdminReqConfig())
            .then(response => {
                if (response.status === 200) {
                    toast.success(response.message);
                    setShowModal(false);
                    getInvoice();
                    getShippingData()
                }
            })
            .catch(error => {
                console.error("Error while submitting shipping data", error);
            });
    };

    // Handle Search Filter
    const handleSearch = (query) => {
        setSearchQuery(query);

        const filtered = invoiceData.filter((data) => {
            return (
                data.orderId?.toString().toLowerCase().includes(query?.toLowerCase()) ||
                data.name?.toLowerCase().includes(query?.toLowerCase()) ||
                data.paymentMode?.toLowerCase().includes(query?.toLowerCase())
            );
        });

        setFilteredData(filtered);
    };


    // PDF Download
    const downloadPDF = () => {
        const doc = new jsPDF();
        const title = "Invoice Report";

        doc.setFontSize(18);

        const pageWidth = doc.internal.pageSize.getWidth();
        const titleWidth = doc.getTextWidth(title);
        const titleX = (pageWidth - titleWidth) / 2;

        doc.text(title, titleX, 10);
        autoTable(doc, {
            head: [['S.No', 'Order Id', 'Order Date', 'Payment Method', 'Status', 'Total Amount', 'Address']],
            body: filteredData.map((od, i) => [
                i + 1,
                od.orderId,
                od.date,
                od.paymentMode,
                od.status,
                od.totalAmount,
                `${od.name}\nPhone no: ${od.phoneNumber}\n${od.address}\n${od.city}\n${od.state}`
            ])
        });
        doc.save('invoice.pdf');
    };


    // CSV Download
    const downloadCSV = () => {
        const csvData = filteredData.map((od, i) => ({
            'S.No': i + 1,
            'Order Id': od.orderId,
            'Order Date': od.date,
            'Payment Method': od.paymentMode,
            'Status': od.status,
            'Total Amount': od.totalAmount,
            'Address': `${od.name}, Phone no: ${od.phoneNumber}, ${od.address}, ${od.city}, ${od.state}`
        }));

        const csv = Papa.unparse(csvData);
        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'invoice.csv');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };


    return <>
        <Flyout isVisible={isFlyoutVisible} onClose={() => toggleFlyout({})} orderId={invObj?.orderId} customerId={cusObj?.customerId}>
            {/* Content for Flyout */}
            {/* <p>Flyout content goes here!</p> */}
            {invObj && <div><h4 className="my-2">Invoice Details</h4>
                <div className="card mt-2">
                    <label className="m-1">Order Id: {invObj.orderId}</label>
                    <label className="m-1">Order Date: {invObj.date}</label>
                    <label className="m-1">Customer: {invObj.name}</label>
                    <label className="m-1">Status: {invObj.status}</label>
                    <label className="m-1">Payment Method: {invObj.paymentMode}</label>
                    <label className="m-1">Total Amount: {invObj.totalAmount}</label>
                </div></div>}

            {cusObj && <div><h4 className="my-2">Customer Details</h4>
                <div className="card mt-2">
                    <label className="m-1">Customer Name: {cusObj?.name}</label>
                    <label className="m-1">Address: {cusObj?.address || ''},<br />{cusObj.city || ''},<br />{cusObj.state || ''},<br />{cusObj.phoneNumber || ''}<br /></label>
                    <label className="m-1">Loyal Points: 5</label>
                </div></div>}

        </Flyout >
        <SuperAdminHeader>
            <div className="fixed-top-bar">
                <div className="card">
                    <h5 className="m-2">Invoice</h5>
                    <div className="card-body">
                        {/* {/ Date Filters /} */}
                        <div className="filter-section shadow p-2 rounded">
                            <div className=" row d-flex">
                                <div className="date-filter col-lg-4 col-sm-6 col-xl-4">
                                    <label>From Date:</label>
                                    <input
                                        className="form-control"
                                        type="date"
                                        value={fromDate}
                                        onChange={(e) => setFromDate(e.target.value)}
                                    />
                                </div>
                                <div className="date-filter col-lg-4 col-sm-6 col-xl-4">
                                    <label>To Date:</label>
                                    <input
                                        className="form-control"
                                        type="date"
                                        value={toDate}
                                        onChange={(e) => setToDate(e.target.value)}
                                    />
                                </div>
                                <div className="mt-2 col-lg-4 col-sm-6 col-xl-4">
                                    <button className="mx-2 my-3 btn btn-primary" onClick={handleFilter}>
                                        Apply Filter
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="card my-4 row">
                            <div className="row ">
                                <div className="search-section m-3 col-8">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Search by Order ID, Customer Name, or Payment Method"
                                        value={searchQuery}
                                        onChange={(e) => handleSearch(e.target.value)}
                                    />
                                </div>
                                <div className="col m-3 ">
                                    {from === 'reports' && (
                                        <>
                                            <button
                                                className="btn btn-success mx-2"
                                                onClick={downloadPDF}
                                                style={{ fontSize: "14px" }}
                                            >
                                                <i className="fa-solid fa-download"></i> PDF
                                            </button>
                                            <button
                                                className="btn btn-success mx-2"
                                                onClick={downloadCSV}
                                                style={{ fontSize: "14px" }}
                                            >
                                                <i className="fa-solid fa-download"></i> CSV
                                            </button>
                                        </>
                                    )}

                                </div>
                            </div>

                            <div className="table-wrapper">
                                <table className="table ">
                                    <thead>
                                        <tr>
                                            <th>S.No</th>
                                            <th>Order Id</th>
                                            <th>Order Date</th>
                                            <th>Customer</th>
                                            <th>Address</th>
                                            <th>Status</th>
                                            <th>Payment Method</th>
                                            <th>Total Amount</th>
                                            {from === 'sales' && (<th>Action</th>)}
                                        </tr >
                                    </thead >
                                    <tbody>
                                        {
                                            filteredData.map((od, i) => {
                                                const isShippingAdded = shippingDatas.some(shipping => shipping.orderId === od.orderId);
                                                return (
                                                    <tr key={od.orderId}>
                                                        <td>{i + 1}</td>
                                                        <td className="cursor-pointer" onClick={() => { toggleFlyout(od, 'invoice') }}>{od.orderId}</td>
                                                        <td>{od.date}</td>
                                                        <td className="cursor-pointer" onClick={() => { toggleFlyout(od, 'customer') }}>{od.name}</td>
                                                        <td>
                                                            Phone no: {od.phoneNumber}
                                                            <br />
                                                            {od.address}
                                                            <br />
                                                            {od.city}
                                                            <br />
                                                            {od.state}
                                                        </td>
                                                        <td>{od.status}</td>
                                                        <td>{od.paymentMode}</td>
                                                        <td>{od.totalAmount}</td>
                                                        <td>
                                                            {from === 'sales' && (
                                                                <>
                                                                    {isShippingAdded ?
                                                                        <button className="btn btn-success">
                                                                            Added to Shipping
                                                                        </button>
                                                                        :
                                                                        <button
                                                                            className="btn btn-primary"
                                                                            onClick={() => handleOpenModal(od.orderId, od.customerId, od.address, od.city, od.state)}
                                                                        >
                                                                            Add to Shipping
                                                                        </button>
                                                                    }
                                                                </>
                                                            )}
                                                        </td >
                                                    </tr >
                                                );
                                            })
                                        }
                                    </tbody >
                                </table >
                            </div >
                        </div >
                    </div >
                </div >
            </div >
        </SuperAdminHeader >

        {/* Shipping Modal */}
        < Modal Modal show={showModal} onHide={() => setShowModal(false)}>
            <Modal.Header closeButton>
                <Modal.Title>Add Shipping Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form>
                    <div className="row">
                        <div className="form-group col-sm-6 col-md-6 col-lg-6 mb-3">
                            <label>Tracking Number</label>
                            <input
                                type="text"
                                className="form-control"
                                name="trackingNumber"
                                value={shippingData.trackingNumber}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="form-group col-sm-6 col-md-6 col-lg-6 mb-3">
                            <label>Carrier</label>
                            <select
                                className="form-control"
                                name="carrier"
                                value={shippingData.carrier}
                                onChange={handleChange}
                            >
                                <option value="">Select Carrier</option>
                                {settingsData && settingsData
                                    ?.filter((sd) => sd.head === 'Shipping Methods')
                                    ?.map((sd) => (
                                        <option key={sd.key} value={sd.key}>
                                            {sd.key}
                                        </option>
                                    ))}
                            </select>
                        </div>
                        <div className="form-group col-sm-6 col-md-6 col-lg-6 mb-3">
                            <label>Shipping Method</label>
                            <select
                                className="form-control"
                                name="shippingMethod"
                                value={shippingData.shippingMethod}
                                onChange={handleChange}
                            >
                                <option value="">Select Method</option>
                                <option value="Standard">Standard</option>
                                <option value="Express">Express</option>
                                <option value="Next-Day">Next-Day</option>
                                <option value="Economy">Economy</option>
                            </select>
                        </div>
                        <div className="form-group col-sm-6 col-md-6 col-lg-6 mb-3">
                            <label>Shipping Cost</label>
                            <input
                                type="number"
                                className="form-control"
                                name="shippingCost"
                                value={shippingData.shippingCost}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="form-group col-sm-6 col-md-6 col-lg-6 mb-3">
                            <label>Loading Charge</label>
                            <input
                                type="number"
                                className="form-control"
                                name="loadingCharge"
                                value={shippingData.loadingCharge}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="form-group col-sm-6 col-md-6 col-lg-6 mb-3">
                            <label>Unloading Charge</label>
                            <input
                                type="number"
                                className="form-control"
                                name="unloadingCharge"
                                value={shippingData.unloadingCharge}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="form-group col-sm-6 col-md-6 col-lg-6 mb-3">
                            <label>Address</label>
                            <input
                                type="text"
                                className="form-control"
                                name="address"
                                value={shippingData.address}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="form-group col-sm-6 col-md-6 col-lg-6 mb-3">
                            <label>City</label>
                            <input
                                type="text"
                                className="form-control"
                                name="city"
                                value={shippingData.city}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="form-group col-sm-6 col-md-6 col-lg-6 mb-3">
                            <label>State</label>
                            <input
                                type="text"
                                className="form-control"
                                name="state"
                                value={shippingData.state}
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                </form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => setShowModal(false)}>
                    Close
                </Button>
                <Button variant="primary" onClick={handleSubmit}>
                    Save
                </Button>
            </Modal.Footer>
        </Modal >
        <ToastContainer position="top-right" />
    </>;
};

export default Invoice;
