import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import Papa from "papaparse";
import { useEffect, useState } from "react";
import { api, superAdminReqConfig } from "../../include/api";
import "../../styles/Super.css";
import SuperAdminHeader from "./Header";

const ItemsReport = () => {
    const [fetchLoading, setFetchLoading] = useState(true);
    const [itemsData, setItemsData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [fromDate, setFromDate] = useState("");
    const [toDate, setToDate] = useState("");
    const [searchQuery, setSearchQuery] = useState("");

    const getShopedProducts = (afterGet) => {
        setFetchLoading(true);
        api.get('/sold_product_details/', superAdminReqConfig()).then(response => {
            if (response.status === 200) {
                setItemsData(response.data.data);
                setFilteredData(response.data.data);
                if (afterGet) afterGet(response.data.data);
            }
        }).catch(error => {
            setItemsData([]);
            setFilteredData([]);
            if (afterGet) afterGet([]);
        }).finally(() => setFetchLoading(false));
    };

    useEffect(() => {
        getShopedProducts();
    }, []);

    // Function to handle filtering by date range
    const handleFilter = () => {
        const from = new Date(fromDate);
        const to = new Date(toDate);

        const filtered = itemsData.filter((item) => {
            const soldDate = new Date(item.date);
            return soldDate >= from && soldDate <= to;
        });

        setFilteredData(filtered);
    };

    // Handle Search Filter
    const handleSearch = (query) => {
        setSearchQuery(query);

        const filtered = itemsData.filter((data) => {
            return (
                data.productId?.toString().toLowerCase().includes(query?.toLowerCase()) ||
                data.name?.toLowerCase().includes(query?.toLowerCase())
            );
        });

        setFilteredData(filtered);
    };

    // PDF Download
    const downloadPDF = () => {
        const doc = new jsPDF();
        const title = "Items Report";

        doc.setFontSize(18);

        const pageWidth = doc.internal.pageSize.getWidth();
        const titleWidth = doc.getTextWidth(title);
        const titleX = (pageWidth - titleWidth) / 2;

        doc.text(title, titleX, 10);
        autoTable(doc, {
            head: [['S.No', 'Item Id', 'Item Name', 'Quantity Sold', 'Total Sales Amount', 'Last Sold Date']],
            body: filteredData.map((item, i) => [
                i + 1,
                item.productId,
                item.name,
                item.totalQty,
                item.totalAmount,
                item.date
            ])
        });
        doc.save('Items.pdf');
    };

    // CSV Download
    const downloadCSV = () => {
        const csvData = filteredData.map((item, i) => ({
            'S.No': i + 1,
            'Item Id': item.productId,
            'Item Name': item.name,
            'Quantity Sold': item.totalQty,
            'Total Sales Amount': item.totalAmount,
            'Last Sold Date': item.date
        }));

        const csv = Papa.unparse(csvData);
        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'Items.csv');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <SuperAdminHeader>
            <div className="fixed-top-bar">
                <div className="card">
                    <h5 className="m-2">Items Report</h5>
                    <div className="card-body">

                        {/* Date Filters */}
                        <div className="filter-section shadow p-2 rounded">
                            <div className="row d-flex">
                                <div className="date-filter col-lg-4 col-sm-6 col-xl-4">
                                    <label>From Date:</label>
                                    <input
                                        className="form-control"
                                        type="date"
                                        value={fromDate}
                                        onChange={(e) => setFromDate(e.target.value)}
                                    />
                                </div>
                                <div className="date-filter col-lg-4 col-sm-6 col-xl-4">
                                    <label>To Date:</label>
                                    <input
                                        className="form-control"
                                        type="date"
                                        value={toDate}
                                        onChange={(e) => setToDate(e.target.value)}
                                    />
                                </div>
                                <div className="mt-2 col-lg-4 col-sm-6 col-xl-4">
                                    <button className="mx-2 my-3 btn btn-primary" onClick={handleFilter}>
                                        Apply Filter
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div className="row ">
                            <div className="search-section m-3 col-8">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Search by Product ID or Product Name"
                                    value={searchQuery}
                                    onChange={(e) => handleSearch(e.target.value)}
                                />
                            </div>
                            <div className="col m-3">
                                <button
                                    className="btn btn-success mx-2"
                                    onClick={downloadPDF}
                                    style={{ fontSize: "14px" }}
                                >
                                    <i class="fa-solid fa-download"></i> PDF
                                </button>
                                <button
                                    className="btn btn-success mx-2"
                                    onClick={downloadCSV}
                                    style={{ fontSize: "14px" }}
                                >
                                    <i class="fa-solid fa-download"></i> CSV
                                </button>
                            </div>
                        </div>

                        {/* Items Table */}
                        <div className="table-wrapper">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>S.No</th>
                                        <th>Item ID</th>
                                        <th>Item Name</th>
                                        <th>Quantity Sold</th>
                                        <th>Total Sales Amount</th>
                                        <th>Last Sold Date</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {filteredData.map((item, index) => (
                                        <tr key={item.productId}>
                                            <td>{index + 1}</td>
                                            <td>{item.productId}</td>
                                            <td>{item.name}</td>
                                            <td>{item.totalQty}</td>
                                            <td>{item.totalAmount.toFixed(2)}</td>
                                            <td>{item.date}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </SuperAdminHeader>
    );
};

export default ItemsReport;
