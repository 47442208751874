import React from "react"
import { useParams } from "react-router-dom"
import { EDIT } from "../../include/constant"
import ShopForm from "../common/ShopForm"
import SuperAdminHeader from "./Header"

const SuperAdminEditShop = () => {
    const { id } = useParams()
    return <SuperAdminHeader >
          <div className="fixed-top-bar"> 
        <ShopForm
            id={id}
            title="Edit Shop"
            type={EDIT}
        />
    </div>
    </SuperAdminHeader>
}

export default SuperAdminEditShop