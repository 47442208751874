import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { api } from '../../include/api';

// const navItems = [
// { url: '/deal-of-the-day', text: 'Deal of the day' },
// { url: '/hot-deals', text: 'Hot Deals', icon: '🔥' },
// { url: '/best-sellers', text: 'Best Sellers' },
// { url: '/new-arrivals', text: 'New Arrivals' },
// { url: '/sell', text: 'Sell' },
// { url: '/books', text: 'Books', icon: '📚' },
// { url: '/customer-service', text: 'Customer Service' },
// { url: '/fresh', text: 'Fresh', icon: '🥦' },
// ];

const CustomerSecondNav = ({ setSection }) => {
    const [hoverIndex, setHoverIndex] = useState(null);
    const [fetchLoading, setFetchLoading] = useState(true)
    const [categoryList, setCategoryList] = useState([])
    const [serviceCategoryList, setServiceCategoryList] = useState([])
    // navItems as a state variable
    const [navItems, setNavItems] = useState([]);
    // console.log(navItems);

    useEffect(() => {
        getServiceCategory()
        getHomeCategory()
    }, [])

    const getHomeCategory = () => {
        // setFetchLoading(true)
        api.get('/category/?flag=getHomeAndChilds').then(response => {
            if (response.status === 200) {
                setCategoryList(response.data.data[0])
            }
        }).catch(error => {
            setCategoryList({})
        }).finally()
    }

    const getServiceCategory = (afterGet) => {
        setFetchLoading(true)
        api.get("/service_category/").then(response => {
            if (response.status === 200) {
                setServiceCategoryList(response.data.data)
                if (afterGet) afterGet(response.data.data)
            }
        }).catch(error => {
            setServiceCategoryList([])
            if (afterGet) afterGet([])
        }).finally(() => setFetchLoading(false))
    }

    useEffect(() => {
        if (categoryList) {
            const updatedNavItems = [...navItems]; // Make a copy of current navItems

            categoryList?.childs?.forEach(c => {
                let isObjExists = updatedNavItems.find(f => f.text === c.name);
                if (!isObjExists) {
                    updatedNavItems.push({ url: `/#${c.name}`, text: c.name, type: 'Heads' });
                }
            });

            setNavItems(updatedNavItems); // Update the state with new items
        }
    }, [categoryList]);

    useEffect(() => {
        if (serviceCategoryList) {
            const updatedNavItems = [...navItems]; // Make a copy of current navItems

            serviceCategoryList.forEach(sc => {
                let isObjExists = updatedNavItems.find(f => f.text === sc.serviceCatName);
                if (!isObjExists) {
                    updatedNavItems.push({ url: `/service_category/${sc.id}`, text: sc.serviceCatName, type: 'Service' });
                }
            });

            setNavItems(updatedNavItems); // Update the state with new items
        }
    }, [serviceCategoryList]);

    const navItemStyle = (isHovered) => ({
        position: 'relative',
        fontWeight: 'bold',
        paddingBottom: '5px',
        color: isHovered ? '#007bff' : 'black',
        textDecoration: 'none',
        transition: 'color 0.3s ease',
        borderBottom: isHovered ? '2px solid #007bff' : 'none',
    });

    return (
        <div className="" style={{ paddingTop: "5px", backgroundColor: "#B1B1B1" }}>
            <nav
                className="customer-second-nav d-flex justify-content-center align-items-center bg-light p-2 shadow-sm pb-0"
                style={{
                    marginTop: '20px',
                    boxShadow: '0 -2px 5px rgba(0, 0, 0, 0.1)',
                }}
            >
                {navItems.map((item, index) => (
                    <Link
                        key={index}
                        onClick={item.type === 'Heads' ? () => setSection(item.text) : ''}
                        to={item.url}
                        className="nav-item text-decoration-none mx-3"
                        style={navItemStyle(index === hoverIndex)}
                        onMouseEnter={() => setHoverIndex(index)}
                        onMouseLeave={() => setHoverIndex(null)}
                    >
                        {item.icon && <span className="me-1">{item.icon}</span>}
                        {item.text}
                    </Link>
                ))}
            </nav>
        </div>
    );
};

export default CustomerSecondNav;
