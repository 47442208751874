import React from "react"
import { ADD } from "../../include/constant"
import UserForm from "./UserForm"
import AdminHeader from "./Header"

const SubAdminAddShop = () => {
    return <AdminHeader >
        <div className="fixed-top-bar">
        <UserForm
            title="Add User"
            type={ADD}
        />
    </div>
    </ AdminHeader>
}

export default SubAdminAddShop