import React from "react"
import { ADD } from "../../include/constant"
import ServiceCategoryForm from "../common/ServiceCategoryForm"
import SuperAdminHeader from "./Header"

const SuperAdminAddServiceCategory = () => {
    return <SuperAdminHeader >
        <div className="fixed-top-bar">
            <ServiceCategoryForm
                title={"Add Service Category"}
                type={ADD}
            // from={from}
            />
        </div>
    </SuperAdminHeader>
}

export default SuperAdminAddServiceCategory