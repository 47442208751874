import { nanoid } from "nanoid"
import React, { useEffect, useState } from "react"
import { Link, useNavigate, useParams } from "react-router-dom"
import { api, baseURL, customerReqConfig } from "../../include/api"
import { SUCCESS } from "../../include/constant"
import { getCustomer, getServiceCart, isEmptyObj, setServiceCart } from "../../include/function"
import { useNotification } from "../../Toast/ToastProvider"
import Spinner from "../add-ons/Spinner"
import CustomerHeader from "./Header"

const ServiceCategory = () => {
    const { id } = useParams()
    const customer = getCustomer();
    const [fetchLoading, setFetchLoading] = useState(true)
    // const [categoryList, setCategoryList] = useState({})
    // const [wishlistData, setWishlistData] = useState({})
    // const [cartData, setCartData] = useState({})
    const [localServiceCart, setLocalServiceCart] = useState(getServiceCart())
    const [serviceCategory, setServiceCategory] = useState([])
    const [servicesList, setServicesList] = useState([])
    const toast = useNotification()

    useEffect(() => {
        if (id) getServiceCatProductsData()
        getServiceCategories()
    }, [id])

    const getServiceCatProductsData = () => {
        // if (customer) {
        api.get('/service/?category_id=' + id, customerReqConfig()).then(response => {
            if (response.status === 200) {
                setServicesList(response.data.data)
            }
        }).catch(error => {
            setServicesList([])
        }).finally()
        // } else {
        //     setServicesList([])
        // }
    }

    const getServiceCategories = () => {
        setFetchLoading(true)
        api.get('/service_category/?id=' + id, customerReqConfig()).then(response => {
            if (response.status === 200) {
                setServiceCategory(response.data.data)
            }
        }).catch(error => {
            setServiceCategory([])
        }).finally(() => setFetchLoading(false))
    }

    console.log(servicesList);
    console.log(serviceCategory);


    return <div className="fixed-top-bar">
        <CustomerHeader enableSearch enableButtons />
        {fetchLoading ? <div className="py-5"><Spinner color="danger" /></div> : <div className="container">
            {/* {!isEmptyObj(categoryList) && categoryList.childs.map(child => {
                return child.products && <ChildCategory child={child} key={nanoid()} />
            })} */}
            {/* {!isEmptyObj(categoryList) && categoryList.childs[1] &&
                <ChildCategory child={categoryList.childs[1]} key={nanoid()} />
            } */}
            <div className="w-100 mt-3">
                <div className="p-3">
                    <h5 className="m-0 fw-bold text-dark">Services</h5>
                </div>
                <div className="container">
                    <div className="row">
                        {servicesList?.map(service => {
                            return <Service service={service} serviceCategory={serviceCategory} customer={customer} toast={toast} serviceList={servicesList} fetchcart={getServiceCatProductsData} key={nanoid()} localServiceCart={localServiceCart} setLocalServiceCart={setLocalServiceCart} />
                        })}
                    </div>
                </div>
            </div>
        </div>
        }
    </div>
}


const Service = ({ service, toast, customer, serviceList, fetchcart, serviceCategory, localServiceCart, setLocalServiceCart }) => {
    console.log(customer);


    const navigate = useNavigate()

    const isInCart = () => localServiceCart && localServiceCart[0] ? localServiceCart[0].serviceCartItems?.filter(c => String(c.serviceId) === String(service.id)).length > 0 : false
    // const isInCart = () => cartData && cartData[0] ? cartData[0].cartItems?.filter(c => String(c.productId) === String(service.id)).length > 0 : false

    // const handleAddToCart = (e) => {
    //     if (!isEmptyObj(customer)) {
    //         // if (cartData.length > 0 && !isInCart()) {
    //         //     //cart exist new item adding
    //         //     const data = {
    //         //         customerId: cartData[0].customerId,
    //         //         itemsPrice: (cartData[0].itemsPrice ? parseFloat(cartData[0].itemsPrice) : 0) + (product.price ? parseFloat(product.price) : 0),
    //         //         totalPrice: (cartData[0].totalPrice ? parseFloat(cartData[0].totalPrice) : 0) + (product.price ? parseFloat(product.price) : 0) - (product.discountValue) + (product.deliveryCharge),
    //         //         totalDiscountValue: cartData[0].totalDiscountValue ? parseFloat(cartData[0].totalDiscountValue) : 0 + product.discountValue ? parseFloat(product.discountValue) : 0,

    //         //         discountMode: product.discountMode,
    //         //         totalDeliveryCharge: cartData[0].totalDeliveryCharge ? parseFloat(cartData[0].totalDeliveryCharge) : 0 + product.deliveryCharge ? parseFloat(product.deliveryCharge) : 0,
    //         //         cartItems: [
    //         //             ...cartData[0].cartItems,
    //         //             {
    //         //                 productId: product.productId,
    //         //                 qty: 1,
    //         //                 price: product.price,
    //         //                 discountMode: product.discountMode,
    //         //                 discountValue: product.discountValue,
    //         //                 deliveryCharge: product.deliveryCharge,
    //         //             }

    //         //         ]
    //         //     }
    //         //     //
    //         //     api.put('/cart/?id=' + cartData[0].id, data, customerReqConfig()).then(response => {
    //         //         if (response.status === 200) {
    //         //             toast({ type: SUCCESS, message: response.data.message })
    //         //             fetchcart()
    //         //         }
    //         //     }).catch(error => {
    //         //         error.response ?
    //         //             toast({ type: ERROR, message: error.response.data.message }) :
    //         //             toast({ type: ERROR, message: error.message })
    //         //     }).finally()
    //         // } else if (cartData.length > 0 && isInCart()) {
    //         //     //cart exist, item also exist qty increase

    //         //     let cartItemIndex = cartData[0].cartItems.findIndex(c => String(c.productId) === String(product.productId))
    //         //     cartData[0].cartItems[cartItemIndex].qty += 1;

    //         //     const data = {
    //         //         customerId: cartData[0].customerId,
    //         //         itemsPrice: cartData[0].itemsPrice ? parseFloat(cartData[0].itemsPrice) : 0 + product.price ? parseFloat(product.price) : 0,
    //         //         totalDiscountValue: cartData[0].totalDiscountValue ? parseFloat(cartData[0].totalDiscountValue) : 0 + product.discountValue ? parseFloat(product.discountValue) : 0,
    //         //         discountMode: product.discountMode,
    //         //         totalDeliveryCharge: cartData[0].totalDeliveryCharge ? parseFloat(cartData[0].totalDeliveryCharge) : 0 + product.deliveryCharge ? parseFloat(product.deliveryCharge) : 0,
    //         //         totalPrice: cartData[0].totalPrice ? parseFloat(cartData[0].totalPrice) : 0 + product.price ? parseFloat(product.price) : 0 - (product.discountValue) + (product.deliveryCharge),
    //         //         cartItems: [
    //         //             ...cartData[0].cartItems,
    //         //         ]
    //         //     }
    //         //     //
    //         //     api.put('/cart/?id=' + cartData[0].id, data, customerReqConfig()).then(response => {
    //         //         if (response.status === 200) {
    //         //             toast({ type: SUCCESS, message: response.data.message })
    //         //             fetchcart()
    //         //         }
    //         //     }).catch(error => {
    //         //         error.response ?
    //         //             toast({ type: ERROR, message: error.response.data.message }) :
    //         //             toast({ type: ERROR, message: error.message })
    //         //     }).finally()
    //         // } else if (cartData.length === 0 && !isInCart()) {
    //         //     //No cart exist new cart and item adding
    //         //     const data = {
    //         //         customerId: customer ? customer.id : '0',
    //         //         itemsPrice: product.price,
    //         //         totalDiscountValue: product.discountValue,
    //         //         discountMode: product.discountMode,
    //         //         totalDeliveryCharge: product.deliveryCharge,
    //         //         totalPrice: parseFloat(product.price) - (product.discountValue ? parseFloat(product.discountValue) : 0) + (product.deliveryCharge ? parseFloat(product.deliveryCharge) : 0),
    //         //         cartItems: [
    //         //             {
    //         //                 productId: product.productId,
    //         //                 qty: 1,
    //         //                 price: product.price,
    //         //                 discountMode: product.discountMode,
    //         //                 discountValue: product.discountValue,
    //         //                 deliveryCharge: product.deliveryCharge,
    //         //             }
    //         //         ]
    //         //     }
    //         //     //
    //         //     api.post('/cart/', data, customerReqConfig()).then(response => {
    //         //         if (response.status === 200) {
    //         //             fetchcart()
    //         //             toast({ type: SUCCESS, message: response.data.message })
    //         //             // setOrderPlaced(true)
    //         //         }
    //         //     }).catch(error => {
    //         //         error.response ?
    //         //             toast({ type: ERROR, message: error.response.data.message }) :
    //         //             toast({ type: ERROR, message: error.message })
    //         //     }).finally()
    //         // }
    //         if (!isInCart()) {
    //             //new item adding
    //             let newCart = {
    //                 id: nanoid(),
    //                 customerId: customer.id,
    //                 name: customer.name,
    //                 totalItems: 1,
    //                 totalAmount: service.serviceCharge,
    //                 serviceCartItems: [
    //                     {
    //                         serviceId: service.id,
    //                         qty: 1,
    //                         price: service.serviceCharge,
    //                     }
    //                 ]
    //             }
    //             let serviceCart = [...localServiceCart, newCart]
    //             setLocalServiceCart(serviceCart)
    //             setServiceCart(serviceCart)//local storage 
    //         } else {
    //             //adding full new cart
    //             let newCart = {
    //                 id: nanoid(),
    //                 customerId: customer.id,
    //                 name: customer.id,
    //                 totalItems: 1,
    //                 totalAmount: service.serviceCharge,
    //                 serviceCartItems: [
    //                     {
    //                         serviceId: service.id,
    //                         qty: 1,
    //                         price: service.serviceCharge,
    //                     }
    //                 ]
    //             }
    //             let serviceCart = [...localServiceCart, newCart]
    //             setLocalServiceCart(serviceCart)
    //             setServiceCart(serviceCart)//local storage 
    //         }

    //     } else if (isEmptyObj(customer)) {
    //         navigate('/login')

    //         // if (!isInCart()) {
    //         //     let cartItem = {
    //         //         id: id,
    //         //         qty: 1,
    //         //         // productId: id,
    //         //         price: product.price,
    //         //         discountMode: product.discountMode,
    //         //         discountValue: product.discountValue,
    //         //         deliveryCharge: product.deliveryCharge,
    //         //     }
    //         //     let cart = [...localCart, cartItem]
    //         //     setLocalCart(cart)
    //         //     setCart(cart)//local storage 
    //         // }
    //     }

    // }

    // Function to handle add to cart
    const handleAddToCart = () => {
        if (!isEmptyObj(customer)) {

            let cartIndex = localServiceCart.findIndex(cart => cart.customerId === customer.id);

            if (cartIndex === -1) {
                // 2) No cart exists, add the full new cart
                let newCart = {
                    id: nanoid(),
                    customerId: customer.id,
                    name: customer.name,
                    totalItems: 1,
                    totalAmount: service.serviceCharge,
                    serviceCartItems: [
                        {
                            serviceId: service.id,
                            name: service.serviceName,
                            qty: 1,
                            price: service.serviceCharge,
                            status: service.status,
                            image: serviceCategory[0]?.imageUrl,
                        }
                    ]
                };
                let serviceCart = [...localServiceCart, newCart];
                setLocalServiceCart(serviceCart);
                setServiceCart(serviceCart); // local storage 
                toast({ type: SUCCESS, message: 'Cart Added successfully' })
            } else {
                // Cart exists
                let existingCart = localServiceCart[cartIndex];
                let itemIndex = existingCart.serviceCartItems.findIndex(item => item.serviceId === service.id);

                if (itemIndex === -1) {
                    // 1) Cart exists but item is new, add the item to serviceCartItems
                    existingCart.serviceCartItems.push({
                        serviceId: service.id,
                        name: service.serviceName,
                        qty: 1,
                        price: service.serviceCharge,
                        status: service.status,
                        image: serviceCategory[0]?.imageUrl,

                    });
                    existingCart.totalItems += 1;
                    existingCart.totalAmount += service.serviceCharge;
                } else {
                    // 3) Cart and item both exist, update qty, totalItems, and totalAmount
                    let existingItem = existingCart.serviceCartItems[itemIndex];
                    existingItem.qty += 1;
                    existingCart.totalItems += 1;
                    existingCart.totalAmount += service.serviceCharge;
                }

                // Update cart in the list and save
                let updatedCarts = [...localServiceCart];
                updatedCarts[cartIndex] = existingCart;
                setLocalServiceCart(updatedCarts);
                setServiceCart(updatedCarts); // local storage
                toast({ type: SUCCESS, message: 'Cart Updated successfully' })
            }
        } else if (isEmptyObj(customer)) {
            navigate('/login')
        };
    }

    return <div className="col-12 col-sm-6 col-lg-4 col-xl-3">
        <div className="text-center d-block p-3 border my-2 text-dark">
            {/* <div> */}
            <Link to={"/service/" + service.id} >

                <img className="fit-contain img-nor hover-scale" src={serviceCategory[0] && baseURL + '/' + serviceCategory[0]?.imageUrl} alt="" />
                <h6 className="my-2 text-truncate">{service.serviceName}</h6>

                <h6 className="fw-bold">
                    {/* <span>&#8377;{Math.ceil(discountPrice(service.price, service.discountValue, service.discountMode))}</span> */}
                    <span className="text-success mx-2">&#8377;{Math.ceil(service.serviceCharge)}</span>
                    {/* <span className="text-success">{service.discountLabel}</span> */}
                    {/* <div className="hstack gap-1">
                            <button className="btn text-primary fw-bold add-to-cart">Add To Cart</button>
                            <button className="btn text-danger fw-bold  my-1" onClick={handleRemoveItemFromWishlist}><i className="far fa-trash-alt  font-weight-normal" /> Remove</button>
                        </div> */}
                    <div>
                    </div>
                </h6>
                <div className="text-dark text-uppercase  fw-normal">Charge Type: {service.chargeType}</div>
                <div className="text-dark text-uppercase  fw-normal">Resource Count: {service.resourceCount}</div>
                <div className="text-dark text-uppercase  fw-normal">Contact Person: {service.contactPerson}</div>
                <div className="text-dark text-uppercase  fw-normal">Contact No: {service.contactNo}</div>

                {/* </div> */}
            </Link>
            <div className="">
                <button className={`fw-bold add-to-cart ${isInCart() ? 'btn btn-primary text-light' : 'btn text-primary'}`} onClick={isInCart() ? () => { } : handleAddToCart}>{isInCart() ? 'Added' : 'Add Service To Cart'}</button>
                {/* <button className="btn text-danger fw-bold  my-1" onClick={handleRemoveItemFromWishlist}><i className="far fa-trash-alt  font-weight-normal" /> Remove</button> */}
            </div>
        </div>
    </div >
}

export default ServiceCategory
