import React from "react";

const AdminFooter = ({ isSidebarMinimized }) => {
    const currentYear = new Date().getFullYear();

    const footerStyle = {
        position: "fixed",
        marginLeft: isSidebarMinimized ? "80px" : "250px",
        left: 0,
        bottom: 0,
        width: "100%",
        padding: "10px 0",
        backgroundColor: "#f8f9fa",
        boxShadow: "0 -2px 5px rgba(0, 0, 0, 0.1)",
    };

    return (
        <footer className="footer bg-light" style={footerStyle}>
            <div className="container m-0 row" >
                <div className="f col-6">
                    Copyright &copy; {currentYear}. All rights reserved.
                </div>
                {/* <div className="powered-by mt-2 col-6 text-end">
          Powered By{" "}
          <a href="http://www.mindzcube.com" target="_blank" rel="noopener noreferrer">
            <img
              src={process.env.PUBLIC_URL + "/img/mindzcube.png"}
              height="30px"
              alt="MindzCube"
            />
          </a>
        </div> */}
            </div>
        </footer>
    );
};



export default AdminFooter;
