import React from "react"
import { ADD } from "../../include/constant"
import ProductForm from "../common/ProductForm"
import AdminHeader from "./Header"

const AdminAddProduct = () => {
    return <AdminHeader >
        <div className="fixed-top-bar">
        <ProductForm
            title="Add Product"
            type={ADD}
        />
    </div>
    </AdminHeader>
}

export default AdminAddProduct