const Error = ({ mainText, secondaryText, width = 100, height = 100 }) => {
    return <div className="w-100 py-5 text-center bg-white">
        <svg width={width} height={height} id="Layer_1" enableBackground="new 0 0 480.011 480.011" viewBox="0 0 480.011 480.011" xmlns="http://www.w3.org/2000/svg">
            <path d="m459.846 366.295h-24.84v-260c0-17.67-14.33-32-32-32h-326c-17.67 0-32 14.33-32 32v260h-24.84c-8.07 0-13.93 7.64-11.67 15.3 5.57 18.93 20.99 48.7 63.01 48.7h337c42.02 0 57.44-29.77 63.01-48.7 2.26-7.66-3.6-15.3-11.67-15.3z" fill="#eef7ff" />
            <path d="m459.846 366.295c8.07 0 13.93 7.64 11.67 15.3-5.57 18.93-20.99 48.7-63.01 48.7h-337c-42.02 0-57.44-29.77-63.01-48.7-2.26-7.66 3.6-15.3 11.67-15.3h127.01c8.35 0 16.48 2.76 22.98 7.93 6.41 5.11 14.54 8.07 23.14 8.07h93.42c8.34 0 16.47-2.76 22.97-7.93 6.42-5.11 14.55-8.07 23.15-8.07z" fill="#585966" />
            <path d="m435.006 106.295v260h-103.19c-8.47 0-16.48 2.93-22.81 8-6.47 5.18-14.52 8-22.81 8 0 0-92.666-.001-92.808-.002-9.135-.098-10.75-13.091-1.951-15.548 39.862-11.131 86.308-33.594 122.859-78.549 43.949-54.057 61.193-125.379 50.141-195.395-1.534-9.716 5.959-18.505 15.795-18.505h22.774c17.673-.001 32 14.326 32 31.999z" fill="#cbe3fa" />
            <path d="m368.856 243.225c11.56 20.03-2.89 45.07-26.02 45.07h-206.1c-23.13 0-37.58-25.04-26.02-45.07l103.05-178.48c11.56-20.03 40.47-20.03 52.04 0z" fill="#ffe773" />
            <path d="m216.826 382.295h69.89c8.34 0 16.47-2.76 22.97-7.93 6.42-5.11 14.55-8.07 23.15-8.07 0 0 127.582.01 127.864.029 7.589.514 6.999 11.971-.607 11.971h-105.257c-8.6 0-16.73 2.96-23.15 8.07-6.5 5.17-14.63 7.93-22.97 7.93h-91.89c-7.916 0-7.956-12 0-12z" fill="#6e6f80" />
            <path d="m147.646 378.295h-105.48c-8.07 0-13.93 7.64-11.67 15.3 2.801 9.511-9.496 14.308-14.079 6.525-3.782-6.423-6.28-12.945-7.921-18.525-2.26-7.66 3.6-15.3 11.67-15.3h127.48c7.864 0 7.988 12 0 12z" fill="#6e6f80" />
            <path d="m235.327 99.361c-73.093 126.595-94.622 163.882-94.701 164.02-11.309 19.723-44.358 4.877-29.911-20.155l93.456-161.863c11.837-20.501 43.274-2.99 31.156 17.998z" fill="#fff0a6" />
            <g>
                <path d="m459.85 358.295h-127.015c-10.192 0-20.18 3.483-28.126 9.807-5.018 3.994-11.408 6.193-17.995 6.193h-93.417c-6.587 0-13.037-2.247-18.162-6.326-7.838-6.239-17.767-9.674-27.959-9.674h-57.113c-4.418 0-8 3.582-8 8s3.582 8 8 8h57.113c6.587 0 12.978 2.199 17.995 6.193 7.945 6.324 17.934 9.807 28.126 9.807h93.417c10.191 0 20.121-3.436 27.958-9.674 5.125-4.079 11.575-6.326 18.162-6.326h127.016c2.704 0 4.744 2.494 3.995 5.039-5.771 19.599-20.057 42.961-55.342 42.961h-336.994c-35.287 0-49.572-23.362-55.342-42.961-.749-2.543 1.289-5.039 3.995-5.039 24.84 0 22.897.035 23.758-.082 4.898.667 9.086-3.145 9.086-7.918v-260c0-13.234 10.766-24 24-24h77.291c4.418 0 8-3.582 8-8s-3.582-8-8-8h-77.291c-22.056 0-40 17.944-40 40v252h-16.844c-13.446 0-23.101 12.793-19.343 25.558 5.986 20.332 23.131 54.442 70.691 54.442h336.993c47.559 0 64.704-34.109 70.691-54.442 3.751-12.743-5.879-25.558-19.344-25.558z" />
                <path d="m136.737 296.295h206.096c29.277 0 47.606-31.68 32.948-57.067l-76.086-131.785c-2.209-3.827-7.102-5.137-10.928-2.928s-5.137 7.102-2.928 10.928l76.086 131.785c8.483 14.693-2.106 33.067-19.092 33.067h-206.096c-16.965 0-27.584-18.357-19.091-33.067l103.047-178.485c8.482-14.691 29.69-14.711 38.183 0 6.72 11.639 5.319 9.222 5.631 9.731 1.435 2.333 3.912 3.736 6.596 3.81.632.017 129.21.011 131.903.011 13.233 0 24 10.766 24 24v218.226c0 4.418 3.582 8 8 8s8-3.582 8-8v-218.226c0-22.056-17.944-40-40-40h-127.068l-3.205-5.552c-14.639-25.355-51.239-25.387-65.896 0l-103.048 178.485c-14.639 25.356 3.635 57.067 32.948 57.067z" />
                <path d="m248.006 125.295c0-4.418-3.582-8-8-8s-8 3.582-8 8v78c0 4.418 3.582 8 8 8s8-3.582 8-8z" />
                <path d="m240.006 228.295c-4.418 0-8 3.582-8 8v1c0 4.418 3.582 8 8 8s8-3.582 8-8v-1c0-4.418-3.582-8-8-8z" />
            </g>
        </svg>
        <h3 className="text-capitalize mt-3">{mainText}</h3>
        <h6 className="text-capitalize">{secondaryText}</h6>
    </div>
}

export default Error