import { ContentState, convertToRaw, EditorState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from 'html-to-draftjs';
import { useEffect, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import { api, superAdminReqConfig } from "../../include/api";
import { ERROR, SUCCESS } from "../../include/constant";
import { useNotification } from "../../Toast/ToastProvider";
import SuperAdminHeader from "./Header";

const initialPageData = {
    title: "",
    url: "",
    status: "Active",
    content: "",
    created_by: ""
};

const SuperPagesComponent = () => {
    const toast = useNotification();
    const [pagesData, setPagesData] = useState([]);
    const [formData, setFormData] = useState(initialPageData);
    const [isEditing, setIsEditing] = useState(false);
    const [editorState, setEditorState] = useState(EditorState.createEmpty())


    // Handle input changes for the form
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const onEditorStateChange = (value) => {
        setEditorState(value)
    }

    const handleSubmit = () => {
        if (formData) {
            if (isEditing) {
                // Update page
                api.put(`/pages/?id=${formData.id}`, formData, superAdminReqConfig())
                    .then(response => {
                        if (response.status === 200) {
                            toast({ type: SUCCESS, message: response.data.message });
                            getPages();
                            setFormData(initialPageData);
                        }
                    }).catch(error => {
                        error.response
                            ? toast({ type: ERROR, message: error.response.data.message })
                            : toast({ type: ERROR, message: error.message });
                    });
            } else {

                // Add new page
                api.post('/pages/', formData, superAdminReqConfig())
                    .then(response => {
                        if (response.status === 200) {
                            toast({ type: SUCCESS, message: response.data.message });
                            getPages();
                            setFormData(initialPageData);
                        }
                    }).catch(error => {
                        error.response
                            ? toast({ type: ERROR, message: error.response.data.message })
                            : toast({ type: ERROR, message: error.message });
                    });
            }
        }
        setFormData(initialPageData);
        setIsEditing(false);
    };

    // Edit page
    const handleEdit = (page) => {
        setFormData(page);
        setIsEditing(true);
        // setFormData({
        //     ...formData,
        //     content:
        // })
        setEditorState(() => {
            const contentBlock = htmlToDraft(page.content);
            if (contentBlock) {
                const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
                const editorState = EditorState.createWithContent(contentState);
                return editorState
            }
            return EditorState.createEmpty()
        })
    };

    // Delete page
    const handleDelete = (id) => {
        api.delete(`/pages/?id=${id}`, superAdminReqConfig())
            .then(response => {
                if (response.status === 200) {
                    toast({ type: SUCCESS, message: response.data.message });
                    getPages();
                }
            }).catch(error => {
                error.response
                    ? toast({ type: ERROR, message: error.response.data.message })
                    : toast({ type: ERROR, message: error.message });
            });
    };

    // Fetch pages data from API
    const getPages = () => {
        api.get('/pages/', superAdminReqConfig())
            .then(response => {
                if (response.status === 200) {
                    setPagesData(response.data.data);
                }
            })
            .catch(error => {
                setPagesData([]);
            });
    };

    // Fetch data on component mount
    useEffect(() => {
        getPages();
    }, []);

    useEffect(() => {
        setFormData({
            ...formData,
            content: (draftToHtml(convertToRaw(editorState.getCurrentContent())))
        });
        // setFormData()
    }, [editorState])

    return (
        <>
            <SuperAdminHeader>
                <div className="fixed-top-bar">
                    <div className="card">
                        <h5 className="m-2">Pages</h5>
                        <div className="card-body">

                            {/* Page Form */}
                            <div className="form-section mb-4 shadow p-3">
                                <div className="row">
                                    <div className="col-lg-4">
                                        <label className="mb-2 mt-3">Page Title:</label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            name="title"
                                            value={formData.title}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                    <div className="col-lg-4">
                                        <label className="mb-2 mt-3">Page URL:</label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            name="url"
                                            value={formData.url}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                    <div className="col-lg-4">
                                        <label className="mb-2 mt-3">Status:</label>
                                        <select
                                            className="form-control"
                                            name="status"
                                            value={formData.status}
                                            onChange={handleInputChange}
                                        >
                                            <option value="Active">Active</option>
                                            <option value="Inactive">Inactive</option>
                                        </select>
                                    </div>
                                    <div className="col-lg-12">
                                        <label className="mb-2 mt-3">Content:</label>
                                        {/* <textarea
                                            className="form-control"
                                            name="content"
                                            value={formData.content}
                                            onChange={handleInputChange}
                                            rows="5"
                                        ></textarea> */}
                                        <Editor
                                            editorState={editorState}
                                            wrapperClassName=""
                                            editorClassName="border rounded-bottom p-3 lh-sm"
                                            onEditorStateChange={onEditorStateChange}
                                        />
                                    </div>
                                    <div className="col-lg-4">
                                        <button
                                            className="btn btn-primary mt-5 mb-1"
                                            onClick={handleSubmit}
                                        >
                                            {isEditing ? "Update" : "Add"} Page
                                        </button>
                                    </div>
                                </div>
                            </div>

                            {/* Data Table */}
                            <div className="table-wrapper">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>S.No</th>
                                            <th className="text-truncate">Page Title</th>
                                            <th className="text-truncate">URL</th>
                                            <th className="text-truncate">Status</th>
                                            <th className="text-truncate">Content</th>
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {pagesData.map((page, i) => (
                                            <tr key={page.id}>
                                                <td>{i + 1}</td>
                                                <td>{page.title}</td>
                                                <td>{page.url}</td>
                                                <td>
                                                    <div className={`badge rounded-pill fs-6 fw-normal ${page.status === 'Active' ? 'bg-success' : 'bg-danger'}`}>
                                                        {page.status}
                                                    </div>
                                                </td>
                                                <td>{page.content}</td>
                                                <td className="d-flex gap-1">
                                                    <button
                                                        className="btn btn-warning btn-sm"
                                                        onClick={() => handleEdit(page)}
                                                    >
                                                        Edit
                                                    </button>
                                                    <button
                                                        className="btn btn-danger btn-sm mx-2"
                                                        onClick={() => handleDelete(page.id)}
                                                    >
                                                        Delete
                                                    </button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </SuperAdminHeader>
        </>
    );
};

export default SuperPagesComponent;
