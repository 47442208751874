import React from "react"
import { useParams } from "react-router-dom"
import { EDIT } from "../../include/constant"
import UserForm from "./UserForm"
import AdminHeader from "./Header"

const SubAdminEditShop = () => {
    const { id } = useParams()
    return <AdminHeader >
          <div className="fixed-top-bar"> 
        <UserForm
            id={id}
            title="Edit User"
            type={EDIT}
        />
    </div>
    </AdminHeader>
}

export default SubAdminEditShop