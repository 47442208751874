import React, { useEffect, useState } from "react"
import { SUCCESS } from "../include/constant"

const Toast = props => {
    const [exit, setExit] = useState(false)
    const [width, setWidth] = useState(0)
    const [intervalID, setIntervalID] = useState(null)

    useEffect(() => {
        if (width === 100) {
            handleCloseNotification()
        }
    })

    useEffect(() => {
        handleStartTimer()
    }, [])

    const handleStartTimer = () => {
        const id = setInterval(() => {
            setWidth(prev => {
                if (prev < 100) {
                    return prev + 0.5
                }

                clearInterval(id)
                return prev
            })
        }, 20)

        setIntervalID(id)
    }

    const handlePauseTimer = () => {
        clearInterval(intervalID)
    }

    const handleCloseNotification = () => {
        handlePauseTimer()
        setExit(true)
        setTimeout(() => {
            props.dispatch({
                type: "REMOVE_NOTIFICATION",
                id: props.id
            })
        }, 400)
    }

    return (
        <div onMouseEnter={handlePauseTimer} onMouseLeave={handleStartTimer} onClick={handleCloseNotification} className={`notification-item rounded shadow mb-3 overflow-hidden ${props.type === SUCCESS ? "bg-success" : "bg-danger"} ${exit && "exit"}`}>
            <p className="m-0 px-3 py-2 text-white">{props.message}</p>
            <div className={"bar"} style={{ width: `${width}%` }} />
        </div>
    )
}

export default Toast
