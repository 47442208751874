// src/context/CartContext.js
import React, { createContext, useContext, useEffect, useState } from "react";
import { api, customerReqConfig } from "../../include/api";
import { getCustomer } from "../../include/function";

// Create the CartContext
const CartCountContext = createContext();

// CartProvider component
export const CartCountProvider = ({ children }) => {
    const customer = getCustomer();
    const [totalCartItems, setTotalCartItems] = useState(0);

    // Function to fetch cart count
    const fetchCartCount = () => {
        if (customer && customer.id) {
            api.get(`/cart/?custId=${customer.id}`, customerReqConfig())
                .then((response) => {
                    if (response.status === 200) {
                        setTotalCartItems(response.data.data.totalItems || 0);
                    }
                })
                .catch(() => {
                    setTotalCartItems(0);
                });
        }
    };

    useEffect(() => {
        fetchCartCount();
    }, []);

    return (
        <CartCountContext.Provider value={{ totalCartItems, fetchCartCount }}>
            {children}
        </CartCountContext.Provider>
    );
};

// Custom hook for accessing cart context
export const useCart = () => {
    return useContext(CartCountContext);
};
