import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { api, baseURL } from "../../include/api";

const SearchBar = () => {
    const [searchQuery, setSearchQuery] = useState("");
    const [results, setResults] = useState([]);
    const [loading, setLoading] = useState(false);
    const [suggestions, setSuggestions] = useState([]);
    const [activeSuggestionIndex, setActiveSuggestionIndex] = useState(0);
    const [showSuggestions, setShowSuggestions] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const handler = setTimeout(() => {
            if (searchQuery) {
                handleSearch();
            } else {
                setResults([]);
            }
        }, 300);

        return () => {
            clearTimeout(handler);
        };
    }, [searchQuery]);

    const handleSearch = async () => {
        setLoading(true);
        try {
            const response = await api.get(`/search/?q=${searchQuery}`);
            const services = response.data.data.services || [];
            const products = response.data.data.products || [];
            const categories = response.data.data.categories || [];
            const brands = response.data.data.brands || [];

            const combinedResults = [
                ...services.map(item => ({ ...item, type: 'service' })),
                ...products.map(item => ({ ...item, type: 'product' })),
                ...categories.map(item => ({ ...item, type: 'category' })),
                ...brands.map(item => ({ ...item, type: 'brand' }))
            ];

            setResults(combinedResults);
            setSuggestions(combinedResults);
            setShowSuggestions(true);
        } catch (error) {
            console.error("Error fetching search results:", error);
            setResults([]);
            setSuggestions([]);
        } finally {
            setLoading(false);
        }
    };

    const handleSuggestionClick = (item) => {
        if (item.type === "product") {
            navigate(`/product/${item.id}`);
        } else if (item.type === "category") {
            navigate(`/category/${item.id}`);
        } else if (item.type === "brand") {
            navigate(`/category/${item.id}`);
        } else if (item.type === "service") {
            navigate(`/service_category/${item.id}`);
        }
        setSearchQuery(item.name);
        setResults([item]);
        setShowSuggestions(false);
    };

    const handleKeyDown = (event) => {
        if (event.key === "ArrowDown") {
            setActiveSuggestionIndex((prev) => (prev + 1) % suggestions.length);
        } else if (event.key === "ArrowUp") {
            setActiveSuggestionIndex((prev) => (prev - 1 + suggestions.length) % suggestions.length);
        } else if (event.key === "Enter") {
            const selectedSuggestion = suggestions[activeSuggestionIndex];
            if (selectedSuggestion) {
                handleSuggestionClick(selectedSuggestion);
            }
        }
    };

    return (
        <div className="search-container position-relative mt-2">
            <form onSubmit={(e) => { e.preventDefault(); handleSearch(); }} className="d-flex align-items-center">
                <button
                    type="submit"
                    className="search-btn"
                    style={{
                        height: "110%",
                        // borderTopLeftRadius: "2px",
                        // borderBottomLeftRadius: "2px",
                        border: "none",
                        background: "#f1f1f1",
                        cursor: "pointer",
                        padding: "10px 10px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        fontSize: "14px"
                    }}
                >
                    <i className="fa fa-search" style={{ color: "#555" }}></i>
                </button>
                <input
                    className="form-control search-input"
                    type="search"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    placeholder="Search for Products, Brands, Services, and More"
                    onFocus={() => setShowSuggestions(true)}
                    onKeyDown={handleKeyDown}
                    style={{
                        width: "290%",
                        outline: "none",
                        boxShadow: "none",
                        borderRadius: '0px',
                        // borderTopRightRadius: "2px",
                        // borderBottomRightRadius: "2px",
                        border: "none",
                        padding: "9px 9px 9px 0px",
                        backgroundColor: "#f1f1f1",
                        fontSize: "14px",
                        color: "#333",
                    }}
                />
            </form>
            {loading && <div>Loading...</div>}
            {showSuggestions && suggestions.length > 0 ? (
                <div className="suggestions-container overflow-auto" style={{ position: "absolute", zIndex: 10, width: "100%", backgroundColor: "#fff", border: "1px solid #ccc" }}>
                    {suggestions?.map((item, index) => (
                        <div
                            key={index}
                            className={`suggestion-item d-flex align-items-center p-2 ${index === activeSuggestionIndex ? 'active' : ''}`}
                            onClick={() => handleSuggestionClick(item)}
                            style={{
                                cursor: "pointer",
                                backgroundColor: index === activeSuggestionIndex ? "#f0f0f0" : "#fff",
                            }}
                        >
                            {item.mainImage || item.imageUrl ? (
                                <img
                                    src={baseURL + "/" + (item.mainImage || item.imageUrl)}
                                    alt={item.name}
                                    style={{ width: "50px", height: "50px", marginRight: "10px" }}
                                />
                            ) : (
                                <i className="fa fa-search" style={{ fontSize: "25px", marginRight: "10px", color: "#555" }}></i>
                            )}
                            <div>
                                <h6>{item.name || item.serviceCatName}</h6>
                                <p>{item.type.charAt(0).toUpperCase() + item.type.slice(1)}</p>
                            </div>
                        </div>
                    ))}
                </div>
            ) :
                searchQuery.length > 0 && (
                    <div className="suggestions-container" style={{ position: "absolute", zIndex: 10, width: "100%", backgroundColor: "#fff", border: "1px solid #ccc" }}>
                        {/* {suggestions.map((item, index) => ( */}
                        <div
                            key={1}
                            className={`suggestion-item d-flex align-items-center p-2 `}
                            // onClick={() => handleSuggestionClick(item)}
                            style={{
                                cursor: "pointer",
                                // backgroundColor: index === activeSuggestionIndex ? "#f0f0f0" : "#fff",
                            }}
                        >

                            <i className="fa fa-search" style={{ fontSize: "25px", marginRight: "10px", color: "#555" }}></i>

                            <div className="m-1">
                                <h6 className="">Oops! No Mathes Found...</h6>
                                {/* <p>{item.type.charAt(0).toUpperCase() + item.type.slice(1)}</p> */}
                            </div>
                        </div>
                        {/* ))} */}
                    </div>
                )}
        </div>
    );
};

export default SearchBar;
