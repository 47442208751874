import { useEffect, useState } from "react";
import { Col, Row, Card } from "react-bootstrap"; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBoxOpen, faBox, faUserSlash, faUserFriends } from '@fortawesome/free-solid-svg-icons';
import BarChart from "../charts/apex-charts/BarChart";
import LineChart from "../charts/apex-charts/LineChart";
import AdminHeader from "./Header";
import { api, adminReqConfig } from "../../include/api";
import { getAdmin } from '../../include/function';

const AdminDashboard = () => {
    const user = getAdmin();
    const [lastYearSalesData, setLastYearSalesData] = useState([]);
    const [lastYearOrderData, setLastYearOrderData] = useState({});
    const [counts, setCounts] = useState({});
    
    const currentYear = new Date().getFullYear();
    const lastYear = currentYear - 1;

    console.log(lastYearSalesData);
    console.log(lastYearOrderData);

    useEffect(() => {
        const fetchCounts = async () => {
            try {
                const response = await api.get(`/dashboard_cards/?shopId=${user.id}`, adminReqConfig());
                setCounts(response.data.data[0]); 
            } catch (error) {
                console.error("Error fetching counts:", error);
            }
        };

        const fetchSalesData = async () => {
            try {
                const response = await api.get(`/sales/?shopId=${user.id}`, adminReqConfig());
                const salesData = response.data.data.map(sale => sale.totalSales);
                console.log("Sales Data:", salesData); 
                setLastYearSalesData(salesData);
            } catch (error) {
                console.error("Error fetching sales data:", error);
            }
        };

        const fetchOrdersData = async () => {
            try {
                const response = await api.get(`/product_for_year/?shopId=${user.id}`, adminReqConfig());
                const ordersData = {};
                response.data.data.forEach(order => {
                    const productName = order.productName;
                    const monthYear = order.monthYear;
                    const quantity = order.totalQty;

                    if (!ordersData[productName]) {
                        ordersData[productName] = new Array(12).fill(0); 
                    }

                    const monthIndex = new Date(monthYear).getMonth();
                    ordersData[productName][monthIndex] += quantity;
                });
                console.log("Order Data:", ordersData); 
                setLastYearOrderData(ordersData);
            } catch (error) {
                console.error("Error fetching orders data:", error);
            }
        };

        fetchSalesData();
        fetchOrdersData();
        fetchCounts();
    }, []);

    return (
        <>
            <AdminHeader>
                <div className="fixed-top-bar">
                    <h4 className="mx-2 my-3">Dashboard</h4>

                    {/* Cards Section */}
                    <Row className="mx-3 my-3 d-flex justify-content-between">
                        <Col className="text-center">
                            <Card>
                                <Card.Body>
                                    <FontAwesomeIcon icon={faBoxOpen} style={{ color: 'blue', fontSize: '2rem' }} />
                                    <Card.Title>Products</Card.Title>
                                    <Card.Text>{counts.totalProducts || 0}</Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col className="text-center">
                            <Card>
                                <Card.Body>
                                    <FontAwesomeIcon icon={faBox} style={{ color: 'blue', fontSize: '2rem' }} />
                                    <Card.Title>Inactive Products</Card.Title>
                                    <Card.Text>{counts.inactiveProductCount || 0}</Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col className="text-center">
                            <Card>
                                <Card.Body>
                                    <FontAwesomeIcon icon={faBoxOpen} style={{ color: 'blue', fontSize: '2rem' }} />
                                    <Card.Title>Active Products</Card.Title>
                                    <Card.Text>{counts.activeProductCount || 0}</Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col className="text-center">
                            <Card>
                                <Card.Body>
                                    <FontAwesomeIcon icon={faUserSlash} style={{ color: 'blue', fontSize: '2rem' }} />
                                    <Card.Title>Inactive Users</Card.Title>
                                    <Card.Text>{counts.inactiveUsers || 0}</Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col className="text-center">
                            <Card>
                                <Card.Body>
                                    <FontAwesomeIcon icon={faUserFriends} style={{ color: 'blue', fontSize: '2rem' }} />
                                    <Card.Title>Very Active Users</Card.Title>
                                    <Card.Text>{counts.veryActiveUsers || 0}</Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>

                    {/* Bar Chart */}
                    <div className="row mx-3 my-3">
                        <Col xl={12}>
                            <div className="card rounded p-3">
                                <BarChart
                                    datasets={[{ name: 'Amt. Of Sales', data: lastYearSalesData }]}
                                    colors={['#FF4560']}
                                    labels={['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']}
                                    title={`Sales Overview for ${lastYear}`} 
                                />
                            </div>
                        </Col>
                    </div>

                    {/* Line Chart */}
                    <div className="row m-3">
                        <Col xl={12} className="mb-5">
                            <div className="card rounded p-3">
                                <LineChart
                                    datasets={Object.keys(lastYearOrderData).map(product => ({
                                        name: product,
                                        data: lastYearOrderData[product],
                                    }))}
                                    colors={['#FF4560', '#4D47A2', '#DC2AAC', '#008FFB', '#FF9800']}
                                    labels={['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']}
                                    title={`Orders Overview for ${lastYear}`}  
                                />
                            </div>
                        </Col>
                    </div>
                </div>
            </AdminHeader>
        </>
    );
};

export default AdminDashboard;
