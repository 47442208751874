import React from 'react';
import Chart from 'react-apexcharts';

// interface dataSet {
//     name: string,
//     data: number[],
// }

// interface Iprops {
//     datasets: dataSet[]
//     labels: string[]
//     title: string
//     colors: string[]
// }
// simple line chart
const LineChart = (props) => {
    // default options
    const { colors, datasets, labels, title } = props
    const apexLineChartWithLables = {
        chart: {
            height: 380,
            type: 'area',
            zoom: {
                enabled: false,
            },
            toolbar: {
                show: true,
            },
        },
        colors: colors,
        dataLabels: {
            enabled: false,
        },
        stroke: {
            width: [1, 1],
            curve: 'straight',//smooth
        },
        title: {
            text: '',
            align: 'left',
            style: {
                fontSize: '14px',
            },
        },
        grid: {
            row: {
                colors: ['transparent', 'transparent'], // takes an array which will be repeated on columns
                opacity: 0.2,
            },
            borderColor: '#f1f3fa',
        },
        markers: {
            size: 3,
        },
        xaxis: {
            categories: labels,
            title: {
                text: '',
            },
        },
        yaxis: {
            title: {
                text: '',
            }
        },
        legend: {
            offsetY: 0,
        },
        responsive: [
            {
                options: {
                    chart: {
                        toolbar: {
                            show: false,
                        },
                    },
                    legend: {
                        show: false,
                    },
                    stroke: {
                        curve: 'smooth'
                    },
                },
            },
        ],
    };

    // chart data
    // const apexLineChartWithLablesData = [
    //     {
    //         name: 'High - 2018',
    //         data: [403540.35, 584287.1, 294573.87, 176220.78, 487164.73, 314826.53, 1995.23],
    //     },
    //     {
    //         name: 'Low - 2018',
    //         data: [584287, 176220, 1995.23, 487164.73, 584287, 314826.53, 1995.23],
    //     },
    // ];
    return (
        <>
            <h4 className="header-title mb-3">{title}</h4>
            <Chart
                options={apexLineChartWithLables}
                series={datasets}
                type="line"
                className="apex-charts"
                height={400}
            />
        </>
    );
};

export default LineChart;
