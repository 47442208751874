import React from "react"
import { ADD } from "../../include/constant"
import CategoryForm from "../common/CategoryForm"
import SuperAdminHeader from "./Header"

const SuperAdminAddCategory = ({ from }) => {
    return <SuperAdminHeader >
        <div className="fixed-top-bar">
            <CategoryForm
                title={"Add " + from}
                type={ADD}
                from={from}
            />
        </div>
    </SuperAdminHeader>
}

export default SuperAdminAddCategory