import '@fortawesome/fontawesome-free/css/all.css'
import 'mdb-react-ui-kit/dist/css/mdb.min.css'
import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import NetworkStatusIndicator from './components/add-ons/NetworkStatusIndicator'
import { CartCountProvider } from './components/context/UserCartCountContext'
import './index.css'
import reportWebVitals from './reportWebVitals'
import './styles/Toast.css'
import ToastProvider from './Toast/ToastProvider'

ReactDOM.render(
    <React.StrictMode>
        <CartCountProvider>
            <ToastProvider>
                <App />
                <NetworkStatusIndicator />
            </ToastProvider>
        </CartCountProvider>
    </React.StrictMode>,
    document.getElementById('root')
)

reportWebVitals()
