import React from "react"
import { customerReqConfig } from "../../include/api"
import Login from "../common/Login"

const CustomerLogin = ({ handleCustomer }) => {
    const apiUrl = '/login/'
    const reqConfig = customerReqConfig()
    const signUpUrl = '/register'

    return <Login
        handleUser={handleCustomer}
        apiUrl={apiUrl}
        reqConfig={reqConfig}
        signUpUrl={signUpUrl}
    />
}

export default CustomerLogin