import React from "react"
import { ADD } from "../../include/constant"
import SlideForm from "../common/SlideForm"
import SuperAdminHeader from "./Header"

const SuperAdminAddSlide = () => {
    return <SuperAdminHeader >
        <div className="fixed-top-bar">
        <SlideForm
            title="Add Slide"
            type={ADD}
        />
     </div>
    </SuperAdminHeader>
}

export default SuperAdminAddSlide