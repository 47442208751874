import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import Papa from "papaparse";
import { useEffect, useState } from "react";
import { api, superAdminReqConfig } from "../../include/api";
import "../../styles/Super.css";
import SuperAdminHeader from "./Header";

const UserFinancialReport = () => {
    const [fetchLoading, setFetchLoading] = useState(true);
    const [userData, setUserData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [fromDate, setFromDate] = useState("");
    const [toDate, setToDate] = useState("");
    const [searchQuery, setSearchQuery] = useState("");

    const getUserFinancial = () => {
        setFetchLoading(true);
        api.get('/user_financial/', superAdminReqConfig())
            .then(response => {
                if (response.status === 200) {
                    setUserData(response.data.data);
                    setFilteredData(response.data.data); // Set filteredData initially
                }
            })
            .catch(() => setUserData([]))
            .finally(() => setFetchLoading(false));
    };

    useEffect(() => {
        getUserFinancial();
    }, []);

    // Function to handle filtering by date range
    const handleFilter = () => {
        if (!fromDate || !toDate) {
            setFilteredData(userData); // Reset to all data if no date selected
            return;
        }

        const from = new Date(fromDate);
        const to = new Date(toDate);

        const filtered = userData.filter((user) => {
            const purchaseDate = new Date(user.date);
            return purchaseDate >= from && purchaseDate <= to;
        });

        setFilteredData(filtered);
    };

    // Handle Search Filter
    const handleSearch = (query) => {
        setSearchQuery(query);

        const filtered = userData.filter((data) => {
            return (
                data.userId?.toString().toLowerCase().includes(query.toLowerCase()) ||
                data.userName?.toLowerCase().includes(query.toLowerCase())
            );
        });

        setFilteredData(filtered);
    };

    // PDF Download
    const downloadPDF = () => {
        const doc = new jsPDF();
        const title = "Users Financial Report";

        doc.setFontSize(18);

        const pageWidth = doc.internal.pageSize.getWidth();
        const titleWidth = doc.getTextWidth(title);
        const titleX = (pageWidth - titleWidth) / 2;

        doc.text(title, titleX, 10);
        autoTable(doc, {
            head: [['S.No', 'User Id', 'User Name', 'Total Purchases', 'Total Spent ($)', 'Last Purchase Date']],
            body: filteredData.map((od, i) => [
                i + 1,
                od.userId,
                od.userName,
                od.totalPurchases,
                od.totalSpent.toFixed(2),
                od.date
            ])
        });
        doc.save('user_financial.pdf');
    };

    // CSV Download
    const downloadCSV = () => {
        const csvData = filteredData.map((od, i) => ({
            'S.No': i + 1,
            'User Id': od.userId,
            'User Name': od.userName,
            'Total Purchases': od.totalPurchases,
            'Total Spent ($)': od.totalSpent.toFixed(2),
            'Last Purchase Date': od.date
        }));

        const csv = Papa.unparse(csvData);
        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'user_financial.csv');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <SuperAdminHeader>
            <div className="fixed-top-bar">
                <div className="card">
                    <h5 className="m-2">User Financial Report</h5>
                    <div className="card-body">
                        <div className="filter-section shadow p-2 rounded">
                            <div className="row d-flex">
                                <div className="date-filter col-lg-4 col-sm-6 col-xl-4">
                                    <label>From Date:</label>
                                    <input
                                        className="form-control"
                                        type="date"
                                        value={fromDate}
                                        onChange={(e) => {
                                            setFromDate(e.target.value);
                                            handleFilter();
                                        }}
                                    />
                                </div>
                                <div className="date-filter col-lg-4 col-sm-6 col-xl-4">
                                    <label>To Date:</label>
                                    <input
                                        className="form-control"
                                        type="date"
                                        value={toDate}
                                        onChange={(e) => {
                                            setToDate(e.target.value);
                                            handleFilter();
                                        }}
                                    />
                                </div>
                                <div className="mt-2 col-lg-4 col-sm-6 col-xl-4">
                                    <button className="mx-2 my-3 btn btn-primary" onClick={handleFilter}>
                                        Apply Filter
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div className="card my-4 row">
                            <div className="row">
                                <div className="search-section m-3 col-8">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Search by User ID or User Name"
                                        value={searchQuery}
                                        onChange={(e) => handleSearch(e.target.value)}
                                    />
                                </div>
                                <div className="col m-3">
                                    <button
                                        className="btn btn-success mx-2"
                                        onClick={downloadPDF}
                                        style={{ fontSize: "14px" }}
                                    >
                                        <i className="fa-solid fa-download"></i> PDF
                                    </button>
                                    <button
                                        className="btn btn-success mx-2"
                                        onClick={downloadCSV}
                                        style={{ fontSize: "14px" }}
                                    >
                                        <i className="fa-solid fa-download"></i> CSV
                                    </button>
                                </div>
                            </div>

                            <div className="table-wrapper">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>S.No</th>
                                            <th>User ID</th>
                                            <th>User Name</th>
                                            <th>Total Purchases</th>
                                            <th>Total Spent ($)</th>
                                            <th>Last Purchase Date</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {filteredData.length > 0 ? (
                                            filteredData.map((user, index) => (
                                                <tr key={user.userId}>
                                                    <td>{index + 1}</td>
                                                    <td>{user.userId}</td>
                                                    <td>{user.userName}</td>
                                                    <td>{user.totalPurchases}</td>
                                                    <td>{user.totalSpent.toFixed(2)}</td>
                                                    <td>{user.date}</td>
                                                </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <td colSpan="6" className="text-center">No data available</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </SuperAdminHeader>
    );
};

export default UserFinancialReport;
