import React from "react"
import { useParams } from "react-router-dom"
import { EDIT } from "../../include/constant"
import ServiceCategoryForm from "../common/ServiceCategoryForm"
import SuperAdminHeader from "./Header"

const SuperAdminEditServiceCategory = ({ from }) => {
    const { id } = useParams()

    return <SuperAdminHeader >
        <div className="fixed-top-bar">
            <ServiceCategoryForm
                id={id}
                title={"Edit Service Category"}
                type={EDIT}
            // from={from}
            />
        </div>
    </SuperAdminHeader>
}

export default SuperAdminEditServiceCategory