import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useNotification } from "../../Toast/ToastProvider";
import { api, superAdminReqConfig } from "../../include/api";
import { ERROR, SUCCESS } from "../../include/constant";
import SuperAdminHeader from "./Header";


const CustomerEditForm = () => {
    const { id } = useParams()
    const toast = useNotification();
    const navigate = useNavigate();
    const [customer, setCustomer] = useState([])
    const imageElement = useRef();
    // const [step, setStep] = useState("signup");

    // const [password, setPassword] = useState('');
    const [name, setName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [emailId, setEmailId] = useState('');
    const [status, setStatus] = useState('');
    const [customerType, setCustomerType] = useState('individual');
    const [proofName, setProofName] = useState('');
    const [gstNo, setGstNo] = useState('');
    const [proofAttachment, setProofAttachment] = useState('');

    const [statusError, setStatusError] = useState('');
    const [nameError, setNameError] = useState('');
    const [phoneNumberError, setPhoneNumberError] = useState('');
    const [emailIdError, setEmailIdError] = useState('');
    const [customerTypeError, setCustomerTypeError] = useState('');
    const [proofNameError, setProofNameError] = useState('');
    const [gstNoError, setGstNoError] = useState('');
    const [proofAttachmentError, setProofAttachmentError] = useState('');

    const [showPassword, setShowPassword] = useState(false);
    const [saving, setSaving] = useState(false);

    useEffect(() => {
        if (id) {
            api.get(`/customer/?id=${id}`, superAdminReqConfig()).then(response => {
                if (response.status === 200) {
                    setCustomer(response.data.data)
                }
            }).catch(error => {
                setCustomer([])
            }).finally()
        }
    }, [])

    useEffect(() => {
        if (customer) {
            setName(customer[0]?.name)
            setPhoneNumber(customer[0]?.phoneNumber)
            setEmailId(customer[0]?.emailId)
            setStatus(customer[0]?.status)
            setCustomerType(customer[0]?.customerType)
            setGstNo(customer[0]?.gstNo ? customer[0]?.gstNo : '')
            setProofName(customer[0]?.proofName ? customer[0]?.proofName : '')
            setProofAttachment(customer[0]?.proofAttachment ? customer[0]?.proofAttachment : '')
        }
    }, [customer])
    console.log(customer);

    const handleCustomerTypeSelection = (type) => {
        setCustomerType(type);
    };

    // const handlePasswordChange = (value) => {
    //     setPasswordError('');
    //     setPassword(value);
    // };

    const handleNameChange = (value) => {
        setNameError('');
        setName(value);
    };

    const handlePhoneNumberChange = (value) => {
        setPhoneNumberError('');
        setPhoneNumber(value);
    };

    const handleStatusChange = (value) => {
        setStatusError('');
        setStatus(value);
    };

    const handleEmailIdChange = (value) => {
        setEmailIdError('');
        setEmailId(value);
    };

    const handleCustomerTypeChange = (value) => {
        setCustomerTypeError('');
        setCustomerType(value);
    };

    const handleProofNameChange = (value) => {
        setProofNameError('');
        setProofName(value);
    };

    const handleGSTNoChange = (value) => {
        setGstNoError('');
        setGstNo(value);
    };

    const handleImage = (image) => {
        setProofAttachmentError('');

        if (image.length > 0) {
            const selectedImage = image[0];

            if (
                selectedImage.type === "image/jpeg" ||
                selectedImage.type === "image/jpg" ||
                selectedImage.type === "image/png" ||
                selectedImage.type === "image/webp"
            ) {
                if (selectedImage.size / 1024 / 1024 <= 3) {
                    const reader = new FileReader();

                    reader.onloadend = (e) => {
                        setProofAttachment(selectedImage);
                    };

                    reader.readAsDataURL(selectedImage);
                } else {
                    setProofAttachmentError('File is too large to upload');
                }
            } else {
                setProofAttachmentError('Unsupported file format (jpeg, jpg, png, and webp are supported)');
            }
        } else {
            setProofAttachmentError('Please choose an image');
        }


        // imageElement.current.value = '';
    };


    const handleCustomerUpdate = () => {
        let error = false;

        if (customerType === '') {
            setCustomerTypeError('Customer type is required');
            error = true;
        }

        if (customerType !== 'individual' && proofName === '') {
            setProofNameError('Proof Name required');
            error = true;
        }

        if (customerType !== 'individual' && gstNo === '') {
            setGstNoError('GST Number required');
            error = true;
        }

        if (customerType !== 'individual' && !proofAttachment) {
            setProofAttachmentError('Proof Attachment required');
            error = true;
        }

        if (!error) {
            setSaving(true);

            // Use FormData to handle file upload
            const formData = new FormData();
            formData.append('userId', String(emailId).trim().toLowerCase());
            // formData.append('password', String(password));
            formData.append('name', String(name).trim());
            formData.append('status', String(status).trim());
            formData.append('phoneNumber', String(phoneNumber).trim());
            formData.append('emailId', String(emailId).trim().toLowerCase());
            formData.append('customerType', String(customerType).trim());
            formData.append('proofName', String(proofName).trim().toLowerCase());
            formData.append('gstNo', String(gstNo).trim().toLowerCase());

            // Append the file
            formData.append('proofAttachment', proofAttachment);

            api.post('/customer/?id=' + id, formData, superAdminReqConfig, {
                // headers: {
                //     'Content-Type': 'multipart/form-data', // Required for file uploads
                // }
            }).then(response => {
                if (response.status === 200) {
                    toast({ type: SUCCESS, message: response.data.message });
                    setName('')
                    setPhoneNumber('')
                    setEmailId('')
                    setStatus('')
                    setCustomerType('')
                    setGstNo('')
                    setProofName('')
                    setProofAttachment('')
                    // let customer = response.data.data.user;
                    // let token = response.data.data.token;
                    // handleCustomer(customer, token);
                    navigate("/super/customer");
                }
            }).catch(error => {
                error.response ?
                    toast({ type: ERROR, message: error.response.data.message }) :
                    toast({ type: ERROR, message: error.message });
            }).finally(() => setSaving(false));
        }
    };

    const handleShowPassword = () => setShowPassword(!showPassword);

    const customerTypes = [
        "individual",
        "engineer",
        "contractor",
        "sub-dealer",
        "builder",
        "promoter",
    ];

    return <>
        <SuperAdminHeader >
            <div className="fixed-top-bar">
                <div className="container my-3">
                    <div className="py-2">
                        <h5 className="m-0">Customer Edit Form</h5>
                    </div>
                    <div className="mt-4 mb-2">
                        <div className="mb-4 row">
                            <div className="col-lg-4 col-sm-4 col-md-4">
                                <label htmlFor="emailId">Email Id</label>
                                <input
                                    type="text"
                                    placeholder="Enter Email Id"
                                    className={`border border-1 rounded w-100 p-2 form-control ${emailIdError !== '' ? 'border-danger' : ''}`}
                                    id="emailId"
                                    onChange={e => handleEmailIdChange(e.target.value)}
                                    value={emailId}
                                    autoComplete="off"
                                />
                                <div className="fw-bold text-danger text-capitalize small" style={{ height: '10px' }}>{emailIdError}</div>
                            </div>
                            {/* <div className=" col-lg-4 col-sm-4 col-md-4">
                                <div className="d-flex align-items-center justify-content-between">
                                    <label htmlFor="password">Password</label>
                                    <Link to="#" className="pe-3" onClick={e => preventClick(e, handleShowPassword)} tabIndex="-1">
                                        {showPassword ? 'Hide' : 'Show'} Password <i className={`fas fa-eye${showPassword ? '-slash' : ''}`} />
                                    </Link>
                                </div>
                                <input
                                    type={showPassword ? "text" : "password"}
                                    placeholder="Enter Password"
                                    className={`border border-1 rounded w-100 p-2 form-control ${passwordError !== '' ? 'border-danger' : ''}`}
                                    id="password"
                                    onChange={e => handlePasswordChange(e.target.value)}
                                    value={password}
                                />
                                <div className="fw-bold text-danger text-capitalize small" style={{ height: '10px' }}>{passwordError}</div>
                            </div> */}
                            <div className=" col-lg-4 col-sm-4 col-md-4">
                                <label htmlFor="name">Name</label>
                                <input
                                    type="text"
                                    placeholder="Enter Name"
                                    className={`border border-1 rounded w-100 p-2 form-control ${nameError !== '' ? 'border-danger' : ''}`}
                                    id="name"
                                    onChange={e => handleNameChange(e.target.value)}
                                    value={name}
                                    autoComplete="off"
                                />
                                <div className="fw-bold text-danger text-capitalize small" style={{ height: '10px' }}>{nameError}</div>
                            </div>
                            <div className=" col-lg-4 col-sm-4 col-md-4">
                                <label htmlFor="phoneNumber">Phone Number</label>
                                <input
                                    type="tel"
                                    placeholder="Enter Phone Number"
                                    className={`border border-1 rounded w-100 p-2 form-control ${phoneNumberError !== '' ? 'border-danger' : ''}`}
                                    id="phoneNumber"
                                    onChange={e => handlePhoneNumberChange(e.target.value)}
                                    value={phoneNumber}
                                    autoComplete="off"
                                />
                                <div className="fw-bold text-danger text-capitalize small" style={{ height: '10px' }}>{phoneNumberError}</div>
                            </div>

                            <div className="col-lg-4 col-sm-4 col-md-4">
                                <label htmlFor="status">Status</label>
                                <select
                                    className={`border border-1 rounded w-100 p-2 form-control ${phoneNumberError !== '' ? 'border-danger' : ''}`}
                                    id="status"
                                    onChange={e => handleStatusChange(e.target.value)} // Ensure this handler updates the status value
                                    value={status}
                                >
                                    <option value="">Select Status</option> {/* Optional default option */}
                                    <option value="active">Active</option>
                                    <option value="inactive">Inactive</option>
                                </select>
                                <div className="fw-bold text-danger text-capitalize small" style={{ height: '10px' }}>
                                    {phoneNumberError}
                                </div>
                            </div>

                        </div>

                        <h4>Select Customer Type</h4>
                        {customerTypeError && <div className="text-danger mb-2">{customerTypeError}</div>}
                        <div className="row ">
                            {customerTypes.map((type, index) => (
                                <div className="col-4 mt-3 text-center text-capitalize">
                                    <CustomerType
                                        key={index}
                                        index={index}
                                        name={type}
                                        onSelect={() => handleCustomerTypeSelection(type)}
                                        isSelected={customerType === type}
                                    />
                                </div>
                            ))}
                        </div>
                        {customerType !== "individual" && (
                            <div className="row my-3 mx-2">
                                <hr className="m-1" style={{ border: "1px solid black" }} />
                                {/* Proof Name Input */}
                                <div className="mb-2 col-lg-4 col-md-4 col-sm-4">
                                    <label htmlFor="proofName">Proof Name</label>
                                    <input
                                        type="text"
                                        placeholder="Enter Proof Name"
                                        className={`border border-1 rounded w-100 p-2 form-control ${proofNameError !== '' ? 'border-danger' : ''}`}
                                        id="proofName"
                                        onChange={e => handleProofNameChange(e.target.value)}
                                        value={proofName}
                                    />
                                    <div className="fw-bold text-danger text-capitalize small">{proofNameError}</div>
                                </div>

                                {/* GST Number Input */}
                                <div className="mb-2 col-lg-4 col-md-4 col-sm-4">
                                    <label htmlFor="gstNo">GST Number</label>
                                    <input
                                        type="text"
                                        placeholder="Enter GST Number"
                                        className={`border border-1 rounded w-100 p-2 form-control ${gstNoError !== '' ? 'border-danger' : ''}`}
                                        id="gstNo"
                                        onChange={e => handleGSTNoChange(e.target.value)}
                                        value={gstNo}
                                    />
                                    <div className="fw-bold text-danger text-capitalize small">{gstNoError}</div>
                                </div>

                                {/* Proof Attachment Input */}
                                <div className="mb-2 col-lg-4 col-md-4 col-sm-4">
                                    <label htmlFor="proofAttachment">Proof Attachment</label>
                                    <input
                                        type="file"
                                        accept=".jpg,.jpeg,.png,.webp"
                                        className={`border border-1 rounded w-100 p-2 form-control ${proofAttachmentError !== '' ? 'border-danger' : ''}`}
                                        id="proofAttachment"
                                        ref={imageElement}
                                        onChange={e => handleImage(e.target.files)}
                                    />
                                    <div className="fw-bold text-danger text-capitalize small">{proofAttachmentError}</div>
                                </div>


                            </div>
                        )}
                        <button type="button" className="btn btn-primary btn-lg btn-block font-decoration-none text-uppercase mb-3 mt-2" onClick={handleCustomerUpdate}>UPDATE</button>
                    </div>
                </div>
            </div>
        </SuperAdminHeader >
    </>
}

const CustomerType = ({ index, name, onSelect, isSelected }) => (
    <label
        className={`form-control form-control-lg cursor-pointer text-truncate ${isSelected ? "bg-primary text-white" : ""
            }`}
        htmlFor={"customerType" + index}
    >
        <span>{name}</span>
        <input
            type="radio"
            name="customerType"
            id={"customerType" + index}
            className="d-none"
            onChange={onSelect}
            checked={isSelected}
        />
    </label>
);
export default CustomerEditForm