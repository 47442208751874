import React, { useState } from "react"

const NetworkStatusIndicator = () => {
    const [online, setOnline] = useState(navigator.onLine)

    setInterval(() => {
        setOnline(navigator.onLine)
    }, 500)

    return !online && <div className="w-100 fixed-bottom p-3 text-white small font-weight-normal bg-dark slide-up-network-indicator">You are currently offline</div>
}

export default NetworkStatusIndicator