import { ContentState, convertToRaw, EditorState } from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'
import { nanoid } from "nanoid"
import React, { useCallback, useEffect, useRef, useState } from "react"
import { Editor } from "react-draft-wysiwyg"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import { Link } from "react-router-dom"
import { adminReqConfig, api, baseURL, superAdminReqConfig } from "../../include/api"
import { ADD, EDIT, ERROR, SUCCESS } from "../../include/constant"
import { getAdmin, isEmptyObj, preventArrow, preventClick, preventWheel, req, tooLong } from "../../include/function"
import { useNotification } from "../../Toast/ToastProvider"
import Spinner from "../add-ons/Spinner"

const ProductForm = ({ type, title, id, from }) => {
    const user = getAdmin()
    const toast = useNotification()

    const mainImageElement = useRef()
    const extraImagesElement = useRef()

    const [saving, setSaving] = useState(false)
    const [fetchingProduct, setFetchingProduct] = useState(type === EDIT)
    const [fetchingCategory, setFetchingCategory] = useState(true)
    const [saveExtraImagesLoading, setSaveExtraImagesLoading] = useState(false)
    const [saveMainImageLoading, setSaveMainImageLoading] = useState(false)
    const [product, setProduct] = useState({})
    const [categoryList, setCategoryList] = useState([])

    const [shopId, setShopId] = useState('')
    const [shopIdError, setShopIdError] = useState('')
    const [fetchingShops, setFetchingShops] = useState(false)
    const [name, setName] = useState('')
    const [category, setCategory] = useState([])
    const [description, setDescription] = useState('')
    const [code, setCode] = useState('')
    const [price, setPrice] = useState('')
    const [mainImage, setMainImage] = useState('')
    const [mainImagePreview, setMainImagePreview] = useState('')
    const [extraImages, setExtraImages] = useState([])
    const [extraImagesPreview, setExtraImagesPreview] = useState([])
    const [discountMode, setDiscountMode] = useState('')
    const [discountValue, setDiscountValue] = useState('')
    const [discountLabel, setDiscountLabel] = useState('')
    const [warrantyMode, setWarrantyMode] = useState('')
    const [warrantyPeriod, setWarrantyPeriod] = useState('')
    const [warrantyLabel, setWarrantyLabel] = useState('')
    const [deliveryCharge, setDeliveryCharge] = useState('')
    const [status, setStatus] = useState('')
    const [nameError, setNameError] = useState('')
    const [categoryError, setCategoryError] = useState('')
    const [descriptionError, setDescriptionError] = useState('')
    const [codeError, setCodeError] = useState('')
    const [priceError, setPriceError] = useState('')
    const [mainImageError, setMainImageError] = useState('')
    const [extraImagesError, setExtraImagesError] = useState('')
    const [discountModeError, setDiscountModeError] = useState('')
    const [discountValueError, setDiscountValueError] = useState('')
    const [discountLabelError, setDiscountLabelError] = useState('')
    const [warrantyModeError, setWarrantyModeError] = useState('')
    const [warrantyPeriodError, setWarrantyPeriodError] = useState('')
    const [warrantyLabelError, setWarrantyLabelError] = useState('')
    const [deliveryChargeError, setDeliveryChargeError] = useState('')
    const [statusError, setStatusError] = useState('')
    const [shops, setShops] = useState([])

    const [editorState, setEditorState] = useState(EditorState.createEmpty())

    const getShops = (afterGet) => {
        setFetchingShops(true)
        api.get('/shop/', superAdminReqConfig()).then(response => {
            if (response.status === 200) {
                setShops(response.data.data)
                if (afterGet) afterGet(response.data.data)
            }
        }).catch(error => {
            setShops([])
            if (afterGet) afterGet([])
        }).finally(() => setFetchingShops(false))
    }

    const getProduct = useCallback(() => {
        setFetchingProduct(true)
        api.get('/product/?id=' + id, adminReqConfig()).then(response => {
            if (response.status === 200) {
                setProduct(response.data.data[0])
            }
        }).catch(error => {
            setProduct([])
        }).finally(() => setFetchingProduct(false))
    }, [id])

    const handleName = (value) => {
        setNameError('')
        setName(value)
    }

    const handleShopId = (value) => {
        setShopIdError('')
        setShopId(value)
    }

    const handleCategory = (value) => {
        setCategoryError('')
        let index = category.findIndex(c => c === value)
        if (index >= 0) setCategory(category.filter(c => c !== value))
        else setCategory([...category, value])
    }

    const handleBrand = (value) => {
        setCategoryError('');
        let brandAlreadyExists = false;
        let id = 0;

        let brandList = categoryList?.filter(d => String(d.parentId) === '-1');

        category.forEach(sc => {
            let obj = brandList.find(f => String(f.id) === String(sc));
            if (obj) {
                brandAlreadyExists = true;
                id = obj.id;
            }
        });

        if (brandAlreadyExists) {
            setCategory([...category.filter(c => c !== id), value]);
        } else {
            setCategory([...category, value]);
        }
    };


    const handleDescription = (value) => {
        setDescriptionError('')
        setDescription(value)
    }

    const handleCode = (value) => {
        setCodeError('')
        setCode(value)
    }

    const handlePrice = (value) => {
        setPriceError('')
        setPrice(value)
    }

    const handleMainImage = (image) => {
        setMainImageError('')
        if (image.length > 0) {
            image = image[0]
            if (image.type === "image/jpeg" || image.type === "image/jpg" || image.type === "image/png" || image.type === "image/webp") {
                if (image.size / 1024 / 1024 <= 3) {
                    let reader = new FileReader()
                    reader.readAsDataURL(image)
                    reader.onloadend = e => setMainImagePreview([reader.result])
                    setMainImage(image)
                } else setMainImageError('Image seems too large')
            } else setMainImageError('jpeg, jpg and png are supported')
        } else setMainImageError('Choose an image')

        mainImageElement.current.value = ''
    }

    const handleExtraImages = (images) => {
        setExtraImagesError('')
        let validImages = [];
        if (images.length > 0) {
            for (let index = 0; index < images.length; index++) {
                const image = images[index]
                if (image.type === "image/jpeg" || image.type === "image/jpg" || image.type === "image/png" || image.type === "image/webp") {
                    if (image.size / 1024 / 1024 <= 3) {
                        validImages.push(image)
                    } else setExtraImagesError('Some images seems too large')
                } else setExtraImagesError('jpeg, jpg and png are supported')
            }
        } else setExtraImagesError('Choose an image')

        if (validImages.length > 0) {
            setExtraImagesPreview([...extraImagesPreview, ...Array.from(validImages).map(image => URL.createObjectURL(image))])
            setExtraImages([...extraImages, ...validImages])
        }

        extraImagesElement.current.value = ''
    }

    const handleDiscountMode = (value) => {
        setDiscountModeError('')
        setDiscountMode(value)
    }

    const handleDiscountValue = (value) => {
        setDiscountValueError('')
        setDiscountValue(value)
    }

    const handleDiscountLabel = (value) => {
        setDiscountLabelError('')
        setDiscountLabel(value)
    }

    const handleWarrantyMode = (value) => {
        setWarrantyModeError('')
        setWarrantyMode(value)
    }

    const handleWarrantyPeriod = (value) => {
        setWarrantyPeriodError('')
        setWarrantyPeriod(value)
    }

    const handleWarrantyLabel = (value) => {
        setWarrantyLabelError('')
        setWarrantyLabel(value)
    }

    const handleDeliveryCharge = (value) => {
        setDeliveryChargeError('')
        setDeliveryCharge(value)
    }

    const handleStatus = (value) => {
        setStatusError('')
        setStatus(value)
    }

    const removeExtraImage = (image) => {
        if (image.imageUrl) {
            api.delete('/product_images/?id=' + image.id, adminReqConfig()).then(response => {
                if (response.status === 200) {
                    removeExtraImageFromArray(image)
                }
            }).catch(error => { })
        } else removeExtraImageFromArray(image)
    }

    const removeExtraImageFromArray = (image) => {
        let index = extraImagesPreview.findIndex(eip => eip === image)
        let removedExtraImages = Array.from(extraImages).filter(ei => ei !== extraImages[index])
        let removedExtraImagesPreview = Array.from(extraImagesPreview).filter(eip => eip !== image)
        setExtraImages(removedExtraImages)
        setExtraImagesPreview(removedExtraImagesPreview)
    }


    const clearFields = () => {
        setName('')
        setCategory([])
        setDescription('')
        setCode('')
        setPrice('')
        setMainImage('')
        setMainImagePreview('')
        setExtraImages([])
        setExtraImagesPreview([])
        setDiscountMode('')
        setDiscountValue('')
        setDiscountLabel('')
        setWarrantyMode('')
        setWarrantyPeriod('')
        setWarrantyLabel('')
        setDeliveryCharge('')
        setStatus('')
        setNameError('')
        setCategoryError('')
        setDescriptionError('')
        setCodeError('')
        setPriceError('')
        setMainImageError('')
        setExtraImagesError('')
        setDiscountModeError('')
        setDiscountValueError('')
        setDiscountLabelError('')
        setWarrantyModeError('')
        setWarrantyPeriodError('')
        setWarrantyLabelError('')
        setDeliveryChargeError('')
        setStatusError('')
    }

    const handleSave = () => {
        let error = false

        const data = {
            id: String(id).trim(),
            shopId: from === 'super' ? shopId : String(user.id).trim(),//for Admin login=shopId is coming as id
            name: String(name).trim(),
            status: String(status).trim() === 'active' ? "not approved" : String(status).trim(),
            category: category,
            description: String(description).trim(),
            code: String(code).trim(),
            price: String(price).trim(),
            mainImage: mainImage,
            extraImages: extraImages,
            discountMode: String(discountMode).trim(),
            discountValue: String(discountValue).trim(),
            discountLabel: String(discountLabel).trim(),
            warrantyMode: String(warrantyMode).trim(),
            warrantyPeriod: String(warrantyPeriod).trim(),
            warrantyLabel: String(warrantyLabel).trim(),
            deliveryCharge: String(deliveryCharge).trim(),
        }
        if (from === 'super' && data.shopId === '') {
            setShopIdError(req('Shop'))
            error = true
        }

        if (data.name === '') {
            setNameError(req('Name'))
            error = true
        } else if (data.name.length > 100) {
            setNameError(tooLong('Name'))
            error = true
        }

        if (data.category.length <= 0) {
            setCategoryError(req('Category'))
            error = true
        }

        if (data.code !== '' && data.code.length > 15) {
            setCodeError(tooLong('Code'))
            error = true
        }

        if (data.price === '') {
            setPriceError(req('Price'))
            error = true
        }

        if (type === ADD && data.mainImage === '') {
            setMainImageError(req('Main image'))
            error = true
        }

        if (data.discountLabel !== '' && data.discountLabel.length > 100) {
            setDiscountLabelError(tooLong('Discount label'))
            error = true
        }

        if (data.warrantyLabel !== '' && data.warrantyLabel.length > 100) {
            setWarrantyLabelError(tooLong('Warranty label'))
            error = true
        }

        if (data.deliveryCharge === '') {
            setDeliveryChargeError(req('Delivery charge'))
            error = true
        }

        if (data.status === '') {
            setStatusError(req('Status'))
            error = true
        }

        if (!error) {
            if (type === ADD) {
                const formData = new FormData()

                formData.append('shopId', data.shopId)
                formData.append('name', data.name)
                formData.append('category', JSON.stringify(data.category))
                formData.append('description', data.description)
                formData.append('code', data.code)
                formData.append('price', data.price)
                formData.append('status', data.status)
                formData.append('discountMode', data.discountMode)
                formData.append('discountValue', data.discountValue)
                formData.append('discountLabel', data.discountLabel)
                formData.append('warrantyMode', data.warrantyMode)
                formData.append('warrantyPeriod', data.warrantyPeriod)
                formData.append('warrantyLabel', data.warrantyLabel)
                formData.append('deliveryCharge', data.deliveryCharge)
                formData.append('mainImage', mainImage)
                for (let index = 0; index < extraImages.length; index++) {
                    formData.append('extraImages[]', extraImages[index])
                }

                setSaving(true)
                api.post('/product/', formData, adminReqConfig()).then(response => {
                    if (response.status === 200) {
                        clearFields()
                        toast({ type: SUCCESS, message: response.data.message })
                    }
                }).catch(error => {
                    if (error.response) {
                        if (error.response.status === 409) setNameError(error.response.data.message)
                        else if (error.response.status === 406) setCodeError(error.response.data.message)
                        else toast({ type: ERROR, message: error.response.data.message })
                    } else toast({ type: ERROR, message: error.message })
                }).finally(() => setSaving(false))
            } else if (type === EDIT) {
                handleSaveExtraImages()
                if (mainImage) handleSaveMainImage()
                setSaving(true)
                api.put('/product/?id=' + data.id + '&flag=updateproduct', data, adminReqConfig()).then(response => {
                    if (response.status === 200) {
                        toast({ type: SUCCESS, message: response.data.message })
                    }
                }).catch(error => {
                    if (error.response) {
                        if (error.response.status === 409) setNameError(error.response.data.message)
                        else if (error.response.status === 406) setCodeError(error.response.data.message)
                        else toast({ type: ERROR, message: error.response.data.message })
                    } else toast({ type: ERROR, message: error.message })
                }).finally(() => setSaving(false))
            }
        }
    }

    const handleSaveExtraImages = () => {
        const images = extraImages.filter(ei => !ei.id)
        if (images.length > 0) {
            const data = new FormData()
            data.append('productId', id)
            for (let index = 0; index < images.length; index++) {
                data.append('images[]', images[index])
            }

            setSaveExtraImagesLoading(true)
            api.post('/product_images/', data, adminReqConfig()).then(response => {
                if (response.status === 200) {
                    toast({ type: SUCCESS, message: response.data.message })
                }
            }).catch(error => {
                error.response ?
                    toast({ type: ERROR, message: error.response.data.message }) :
                    toast({ type: ERROR, message: error.message })
            }).finally(() => setSaveExtraImagesLoading(false))
        }
    }

    const handleSaveMainImage = () => {
        const data = new FormData()
        data.append('mainImage', mainImage)

        setSaveMainImageLoading(true)
        api.post('/product/?id=' + id + '&flag=changemainimage', data, adminReqConfig()).then(response => {
            if (response.status === 200) {
                toast({ type: SUCCESS, message: response.data.message })
            }
        }).catch(error => {
            error.response ?
                toast({ type: ERROR, message: error.response.data.message }) :
                toast({ type: ERROR, message: error.message })
        }).finally(() => setSaveMainImageLoading(false))
    }

    const onEditorStateChange = (value) => {
        setEditorState(value)
    }

    const getCategory = () => {
        setFetchingCategory(true)
        api.get('/category/?flag=exceptHome', adminReqConfig()).then(response => {
            if (response.status === 200) {
                setCategoryList(response.data.data)
            }
        }).catch(error => {
        }).finally(() => setFetchingCategory(false))
    }

    useEffect(() => {
        handleDescription(draftToHtml(convertToRaw(editorState.getCurrentContent())))
    }, [editorState])

    useEffect(() => {
        getCategory()
        getShops()
    }, [])

    useEffect(() => {
        if (type === EDIT) getProduct()
    }, [type, getProduct])

    useEffect(() => {
        if (!isEmptyObj(product)) {
            const category = product.category.reduce((previous, current) => [...previous, current.categoryId], [])
            const extraImages = product.images.reduce((previous, current) => [...previous, { id: current.id, imageUrl: baseURL + '/' + current.imageUrl }], [])

            setName(product.name)
            setCategory(category)
            setEditorState(() => {
                const contentBlock = htmlToDraft(product.description);
                if (contentBlock) {
                    const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
                    const editorState = EditorState.createWithContent(contentState);
                    return editorState
                }
                return EditorState.createEmpty()
            })
            setCode(product.code)
            setPrice(product.price)
            setMainImagePreview(baseURL + '/' + product.mainImage)
            setExtraImages(extraImages)
            setExtraImagesPreview(extraImages)
            setDiscountMode(product.discountMode)
            setDiscountValue(product.discountValue)
            setDiscountLabel(product.discountLabel)
            setWarrantyMode(product.warrantyMode)
            setWarrantyPeriod(product.warrantyPeriod)
            setWarrantyLabel(product.warrantyLabel)
            setDeliveryCharge(product.deliveryCharge)
            setStatus(product.status)
        }
    }, [product])

    return <div className="container my-4">
        <div className="py-3 d-flex justify-content-between align-items-center">
            <h5 className="m-0">{title}</h5>
            {saving || saveExtraImagesLoading || saveMainImageLoading ? <Link to="#" className="btn" onClick={e => e.preventDefault()}>Save</Link> :
                <Link to="#" className="btn btn-primary" onClick={e => preventClick(e, handleSave)}>Save</Link>}
        </div>
        {fetchingProduct || fetchingCategory ? <Spinner color="danger" /> : <>
            <div className="row">
                <div className="col-12 mb-2">
                    <h6 className="m-0">Category</h6>
                    <hr></hr>
                    <div className="row">
                        {categoryList.length > 0 && categoryList.filter(f => String(f.parentId) !== '-1').map((cat, index) => {
                            const isSelected = category.filter(categoryId => cat.id === categoryId).length === 1
                            return <Category
                                name={cat.name}
                                index={index}
                                onSelect={() => handleCategory(cat.id)}
                                isSelected={isSelected}
                                key={nanoid()}
                            />
                        })}
                        <span className="text-danger my-2 small fw-bold">{categoryError}</span>
                        <br />
                    </div>
                </div>
                <div className="col-12 mb-2">
                    <h6 className="m-0">Brand</h6>
                    <hr />
                    <div className="row">
                        {categoryList.length > 0 &&
                            categoryList.filter(f => String(f.parentId) === '-1').map((cat, index) => {
                                const isSelected = category.includes(cat.id);

                                return (
                                    <div className="col-6 col-sm-4 col-md-3 col-lg-2 mb-3" key={nanoid()}>
                                        <label
                                            className={`form-control form-control-lg cursor-pointer text-truncate ${isSelected ? 'bg-primary text-white' : ''}`}
                                            htmlFor={"brand" + index}
                                        >
                                            <span>{cat.name}</span>
                                            <input
                                                type="radio"
                                                name="brand"
                                                id={"brand" + index}
                                                className="d-none"
                                                checked={isSelected}
                                                onChange={() => handleBrand(cat.id)}
                                            />
                                        </label>
                                    </div>
                                );
                            })}
                        <span className="text-danger my-2 small fw-bold">{categoryError}</span>
                        <br />
                    </div>
                </div>
                <hr></hr>
                {from === 'super' && <div className="col-12 col-md-6">
                    <label htmlFor="shopId" className="small">Shop</label>
                    {fetchingShops ?
                        <input type="text" placeholder="Please wait..." disabled readOnly className="form-control form-control-lg" />
                        : <select className={`form-control form-control-lg ${shopIdError !== '' && 'border-danger'}`} id="state" required onChange={e => handleShopId(e.target.value)} value={shopId} >
                            <option value="" disabled>Select Shop</option>
                            {shops && shops.length > 0 && shops.map((shop, index) => {
                                return <option value={shop.id} key={nanoid()}>{shop.name}</option>
                            })}
                        </select>}
                    <span className="text-danger my-2 small fw-bold">{shopIdError}</span>
                    <br />

                    {/* <label htmlFor="name" className="small">Shop</label>
                    <input type="text" className={`form-control form-control-lg ${nameError !== '' && 'border-danger'}`} placeholder="shop" id="name" autoFocus required autoComplete="off" onChange={e => handleShopId(e.target.value)} value={shopId} />
                    <span className="text-danger my-2 small fw-bold">{shopIdError}</span>
                    <br /> */}
                </div>}
                <div className="col-12 col-md-6">
                    <label htmlFor="name" className="small">Name</label>
                    <input type="text" className={`form-control form-control-lg ${nameError !== '' && 'border-danger'}`} placeholder="Name" id="name" autoFocus required autoComplete="off" onChange={e => handleName(e.target.value)} value={name} />
                    <span className="text-danger my-2 small fw-bold">{nameError}</span>
                    <br />
                </div>
                <div className="col-12 col-md-6">
                    <label htmlFor="price" className="small">Price</label>
                    <input type="number" className={`form-control form-control-lg ${priceError !== '' && 'border-danger'}`} placeholder="Price" id="price" required autoComplete="off" onFocus={e => preventWheel(e)} onKeyDown={e => preventArrow(e)} onChange={e => handlePrice(e.target.value)} value={price} />
                    <span className="text-danger my-2 small fw-bold">{priceError}</span>
                    <br />
                </div>
                <div className="col-12 col-lg-4">
                    <label className="small">Status</label>
                    <div className="row">
                        <div className="col-6">
                            <label htmlFor="active" className={`small form-control form-control-lg d-flex justify-content-between align-items-center cursor-pointer 
                                ${status === 'active' && type !== EDIT
                                    ? 'bg-success text-white fw-bold'
                                    : (status === 'not approved')
                                        ? 'bg-danger text-white fw-bold'
                                        : status === 'active'
                                            ? 'bg-success text-white fw-bold'
                                            : ''
                                }`}>

                                <span>{(status === 'inactive' || status === 'active' || type === ADD) ? 'Active' : 'Not Approved'}</span>
                                {(status === 'inactive' && type === EDIT) || ((status === '' || status !== '') && type === ADD) ? (
                                    <input
                                        type="radio"
                                        className="form-check-input"
                                        name="status"
                                        id="active"
                                        checked={status === 'active'}
                                        onChange={e => handleStatus('active')}
                                    />
                                ) : null}
                            </label>
                        </div>


                        <div className="col-6">
                            <label htmlFor="inactive" className={`small form-control form-control-lg d-flex justify-content-between align-items-center cursor-pointer ${status === 'inactive' ? 'bg-danger text-white fw-bold' : ''}`}>
                                <span>Inactive</span>
                                <input type="radio" className="form-check-input" name="status" id="inactive" checked={status === 'inactive'} onChange={e => handleStatus('inactive')} />
                            </label>
                        </div>
                    </div>
                    <span className="text-danger my-2 small fw-bold">{statusError}</span>
                    <br />
                </div>
                <div className="col-12 col-md-6 col-lg-4">
                    <label htmlFor="deliveryCharge" className="small">Delivery Charge</label>
                    <input type="number" className={`form-control form-control-lg ${deliveryChargeError !== '' && 'border-danger'}`} placeholder="Delivery Charge" id="deliveryCharge" required autoComplete="off" onFocus={e => preventWheel(e)} onKeyDown={e => preventArrow(e)} onChange={e => handleDeliveryCharge(e.target.value)} value={deliveryCharge} />
                    <span className="text-danger my-2 small fw-bold">{deliveryChargeError}</span>
                    <br />
                </div>
                <div className="col-12 col-md-6 col-lg-4">
                    <label htmlFor="code" className="small">Code</label>
                    <input type="number" className={`form-control form-control-lg ${codeError !== '' && 'border-danger'}`} placeholder="Code" id="code" required autoComplete="off" onFocus={e => preventWheel(e)} onKeyDown={e => preventArrow(e)} onChange={e => handleCode(e.target.value)} value={code} />
                    <span className="text-danger my-2 small fw-bold">{codeError}</span>
                    <br />
                </div>
                <div className="col-12 col-lg-4">
                    <label className="small">Discount Mode</label>
                    <div className="row">
                        <div className="col-6">
                            <label htmlFor="amount" className={`small form-control form-control-lg d-flex justify-content-between align-items-center cursor-pointer ${discountMode === 'amount' ? 'bg-primary text-white fw-bold' : ''}`}>
                                <span>Amount</span>
                                <input type="radio" className="form-check-input" name="discountMode" id="amount" checked={discountMode === 'amount'} onChange={e => handleDiscountMode('amount')} />
                            </label>
                        </div>
                        <div className="col-6">
                            <label htmlFor="percent" className={`small form-control form-control-lg d-flex justify-content-between align-items-center cursor-pointer ${discountMode === 'percent' ? 'bg-primary text-white fw-bold' : ''}`}>
                                <span>Percent</span>
                                <input type="radio" className="form-check-input" name="discountMode" id="percent" checked={discountMode === 'percent'} onChange={e => handleDiscountMode('percent')} />
                            </label>
                        </div>
                    </div>
                    <span className="text-danger my-2 small fw-bold">{discountModeError}</span>
                    <br />
                </div>
                <div className="col-12 col-md-6 col-lg-4">
                    <label htmlFor="discountValue" className="small">Discount Value</label>
                    <input type="number" className={`form-control form-control-lg ${discountValueError !== '' && 'border-danger'}`} placeholder="Discount Value" id="discountValue" required autoComplete="off" onFocus={e => preventWheel(e)} onKeyDown={e => preventArrow(e)} onChange={e => handleDiscountValue(e.target.value)} value={discountValue} />
                    <span className="text-danger my-2 small fw-bold">{discountValueError}</span>
                    <br />
                </div>
                <div className="col-12 col-md-6 col-lg-4">
                    <label htmlFor="discountLabel" className="small">Discount Label</label>
                    <input type="text" className={`form-control form-control-lg ${discountLabelError !== '' && 'border-danger'}`} placeholder="Discount Label" id="discountLabel" required autoComplete="off" onChange={e => handleDiscountLabel(e.target.value)} value={discountLabel} />
                    <span className="text-danger my-2 small fw-bold">{discountLabelError}</span>
                    <br />
                </div>
                <div className="col-12 col-lg-4">
                    <label className="small">Warranty Mode</label>
                    <div className="row">
                        <div className="col-6">
                            <label htmlFor="month" className={`small form-control form-control-lg d-flex justify-content-between align-items-center cursor-pointer ${warrantyMode === 'month' ? 'bg-primary text-white fw-bold' : ''}`}>
                                <span>Month</span>
                                <input type="radio" className="form-check-input" name="warrantyMode" id="month" checked={warrantyMode === 'month'} onChange={e => handleWarrantyMode('month')} />
                            </label>
                        </div>
                        <div className="col-6">
                            <label htmlFor="year" className={`small form-control form-control-lg d-flex justify-content-between align-items-center cursor-pointer ${warrantyMode === 'year' ? 'bg-primary text-white fw-bold' : ''}`}>
                                <span>Year</span>
                                <input type="radio" className="form-check-input" name="warrantyMode" id="year" checked={warrantyMode === 'year'} onChange={e => handleWarrantyMode('year')} />
                            </label>
                        </div>
                    </div>
                    <span className="text-danger my-2 small fw-bold">{warrantyModeError}</span>
                    <br />
                </div>
                <div className="col-12 col-md-6 col-lg-4">
                    <label htmlFor="warrantyPeriod" className="small">Warranty Period</label>
                    <input type="number" className={`form-control form-control-lg ${warrantyPeriodError !== '' && 'border-danger'}`} placeholder="Warranty Period" id="warrantyPeriod" required autoComplete="off" onFocus={e => preventWheel(e)} onKeyDown={e => preventArrow(e)} onChange={e => handleWarrantyPeriod(e.target.value)} value={warrantyPeriod} />
                    <span className="text-danger my-2 small fw-bold">{warrantyPeriodError}</span>
                    <br />
                </div>
                <div className="col-12 col-md-6 col-lg-4">
                    <label htmlFor="warrantyLabel" className="small">Warranty Label</label>
                    <input type="text" className={`form-control form-control-lg ${warrantyLabelError !== '' && 'border-danger'}`} placeholder="Warranty Label" id="warrantyLabel" required autoComplete="off" onChange={e => handleWarrantyLabel(e.target.value)} value={warrantyLabel} />
                    <span className="text-danger my-2 small fw-bold">{warrantyLabelError}</span>
                    <br />
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <label htmlFor="description" className="small">Description</label>
                    <Editor
                        editorState={editorState}
                        wrapperClassName=""
                        editorClassName="border rounded-bottom px-3 lh-sm"
                        onEditorStateChange={onEditorStateChange}
                    />
                    <span className="text-danger my-2 small fw-bold">{descriptionError}</span>
                    <br />
                </div>
            </div>
            <span className="text-danger fw-bold small me-2">{extraImagesError}</span>
            <span className="text-danger fw-bold small">{mainImageError}</span>
            <br />
            <br />
            <div className="row" style={{ marginBottom: "100px" }}>
                <div className="col-6 col-sm-4 col-md-3 col-lg-2 mb-3">
                    <label htmlFor="image" className={`form-control form-control-lg cursor-pointer d-flex align-items-center justify-content-center p-2 ${mainImageError !== '' && 'border-danger'}`} style={{ height: '150px' }}>
                        {mainImagePreview ? <img src={mainImagePreview} alt="" className="fit-contain w-100 h-100" /> :
                            <span>
                                <i className="fa fa-image" />
                                <span className="ms-3">Main Image</span>
                            </span>}
                    </label>
                    <input type="file" name="image" id="image" onChange={e => handleMainImage(e.target.files)} className="d-none" accept="image/*" ref={mainImageElement} />
                </div>
                <div className="col-6 col-sm-4 col-md-3 col-lg-2 mb-3">
                    <label htmlFor="extraImage" className={`form-control form-control-lg cursor-pointer d-flex align-items-center justify-content-center ${extraImagesError !== '' && 'border-danger'}`} style={{ height: '150px' }}>
                        <i className="fa fa-image" />
                        <span className="ms-3">Add More</span>
                    </label>
                </div>
                {extraImagesPreview.length > 0 && extraImagesPreview.map(image => <ExtraImage
                    src={image}
                    key={nanoid()}
                    onClose={() => removeExtraImage(image)}
                />)}
                <input type="file" name="extraImage" id="extraImage" onChange={e => handleExtraImages(e.target.files)} className="d-none" accept="image/*" ref={extraImagesElement} multiple="multiple" />
            </div>
        </>}
    </div>
}

const ExtraImage = ({ src, onClose }) => <div className="col-6 col-sm-4 col-md-3 col-lg-2 mb-3">
    <div className="position-relative">
        <div className="position-absolute top-0 end-0 m-1 rounded-circle bg-danger d-flex align-items-center justify-content-center cursor-pointer" style={{ width: '20px', height: '20px' }} onClick={() => onClose()}>
            <i className="fas fa-times text-white"></i>
        </div>
    </div>
    <img src={src.imageUrl || src} alt="" className="fit-contain form-control form-control-lg p-2" style={{ height: '150px' }} />
</div>

const Category = ({ index, name, onSelect, isSelected }) => <div className="col-6 col-sm-4 col-md-3 col-lg-2 mb-3">
    <label className={`form-control form-control-lg cursor-pointer text-truncate ${isSelected ? 'bg-primary text-white' : ''}`} htmlFor={"category" + index}>
        <span>{name}</span >
        <input type="radio" name="category" id={"category" + index} className="d-none" onChange={e => onSelect()} />
    </label >
</div >

export default ProductForm