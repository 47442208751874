import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { api } from '../../include/api'
import { ERROR, SUCCESS } from '../../include/constant'
import { preventClick } from '../../include/function'
import { useNotification } from '../../Toast/ToastProvider'

const Login = ({ handleUser, apiUrl, reqConfig, signUpUrl }) => {
    const toast = useNotification()

    const [userId, setUserId] = useState('')
    const [password, setPassword] = useState('')
    const [userIdError, setUserIdError] = useState('')
    const [passwordError, setPasswordError] = useState('')
    const [postLoading, setPostLoading] = useState(false)
    const [showPassword, setShowPassword] = useState(false)
    const [showForgotPassword, setShowForgotPassword] = useState(false);
    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState('');

    const handleUserId = (val) => {
        setUserIdError('')
        setUserId(String(val).trim().toLowerCase())
    }

    const handlePassword = (val) => {
        setPasswordError('')
        setPassword(val)
    }

    const clearFields = () => {
        setUserId('')
        setPassword('')
        setUserIdError('')
        setPasswordError('')
    }

    const handleShowPassword = () => setShowPassword(!showPassword)

    const handleSignIn = () => {
        let error = false
        const data = {
            userId: String(userId).trim().toLowerCase(),
            password: password
        }

        if (data.userId === '') {
            setUserIdError('Enter username')
            error = true
        }

        if (data.password === '') {
            setPasswordError('Enter password')
            error = true
        }

        if (!error) {
            setPostLoading(true)
            api.post(apiUrl, data, reqConfig).then(response => {
                if (response.status === 200) {
                    clearFields()
                    setTimeout(() => {
                        const user = response.data.data.user
                        const token = response.data.data.token
                        handleUser(user, token)
                        toast({ type: SUCCESS, message: response.data.message })
                        window.location.reload();
                    }, 500)
                }
            }).catch(error => {
                error.response ?
                    toast({ type: ERROR, message: error.response.data.message }) :
                    toast({ type: ERROR, message: error.message })
            }).finally(() => {
                setPostLoading(false)
            })
        }
    }

    const handlePasswordReset = () => {
        if (email === '') {
            setEmailError('Enter your email');
            return;
        }

        setPostLoading(true);

        api.post('/auth/password-reset', { email }).then(response => {
            if (response.status === 200) {
                toast({ type: SUCCESS, message: 'Reset link sent to your email' });
                setShowForgotPassword(false);
                setEmail('');
            }
        }).catch(error => {
            error.response ?
                toast({ type: ERROR, message: error.response.data.message }) :
                toast({ type: ERROR, message: error.message });
        }).finally(() => {
            setPostLoading(false);
        });
    };


    // return <div className="vh-100">
    //     <div className="p-4">
    //         <div className="container">
    //             <h2 className="my-0">Welcome Back</h2>
    //             <p className="text-50">Sign in to continue</p>
    //             <form className="mt-5 mb-4" action="#">
    //                 <div className="form-group mb-4">
    //                     <label htmlFor="username" className="">Username</label>
    //                     <input type="text" placeholder="Enter Username" className={`border border-1 rounded w-100 p-2 form-control ${userIdError !== '' ? 'border-danger' : ''}`} id="username" onChange={e => handleUserId(e.target.value)} value={userId} autoComplete="off" />
    //                     <div className="fw-bold text-danger text-capitalize small" style={{ height: '10px' }}>{userIdError}</div>
    //                     <br />
    //                 </div>
    //                 <div className="form-group mb-4">
    //                     <div className="d-flex align-items-center justify-content-between">
    //                         <label htmlFor="password" className="">Password</label>
    //                         <Link to="#" className="pe-3" onClick={e => preventClick(e, handleShowPassword)} tabIndex="-1">{showPassword ? 'Hide' : 'Show'} Password <i className={`fas fa-eye${showPassword ? '-slash' : ''}`} /> </Link>
    //                     </div>
    //                     <div className="position-relative w-100 h-100">
    //                         <input type={showPassword ? "text" : "password"} placeholder="Enter Password" className={`border border-1 rounded w-100 p-2 form-control ${passwordError !== '' ? 'border-danger' : ''}`} id="password" onChange={e => handlePassword(e.target.value)} value={password} />
    //                     </div>
    //                     <div className="fw-bold text-danger text-capitalize small" style={{ height: '10px' }}>{passwordError}</div>
    //                     <br />
    //                 </div>
    //                 {postLoading ? <button className="btn border btn-lg btn-block font-decoration-none text-uppercase no-drop" onClick={e => e.preventDefault()}>SIGN IN</button> :
    //                     <button className="btn btn-primary btn-lg btn-block font-decoration-none text-uppercase" onClick={e => preventClick(e, handleSignIn)}>SIGN IN</button>}
    //                 <br />
    //                 {signUpUrl && <Link to={signUpUrl} className="small fw-bold">Not yet a customer? Create an account</Link>}
    //             </form>
    //         </div>
    //     </div>
    // </div>

    return (
        <div className="vh-100 d-flex justify-content-center align-items-center bg-primary">
            <div className="card p-4 shadow" style={{ maxWidth: '400px', width: '100%' }}>
                <div className="container">
                    <h2 className="my-0">Welcome Back</h2>
                    <p className="text-50">Sign in to continue</p>
                    {!showForgotPassword ? (
                        <form className="mt-5 mb-4" action="#">
                            <div className="form-group mb-4">
                                <label htmlFor="username" className="">Email Id</label>
                                <input type="text" placeholder="Enter Username" className={`border border-1 rounded w-100 p-2 form-control ${userIdError !== '' ? 'border-danger' : ''}`} id="username" onChange={e => handleUserId(e.target.value)} value={userId} autoComplete="off" />
                                <div className="fw-bold text-danger text-capitalize small" style={{ height: '10px' }}>{userIdError}</div>
                                <br />
                            </div>
                            <div className="form-group mb-4">
                                <div className="d-flex align-items-center justify-content-between">
                                    <label htmlFor="password" className="">Password</label>
                                    <Link to="#" className="pe-3" onClick={e => preventClick(e, handleShowPassword)} tabIndex="-1">{showPassword ? 'Hide' : 'Show'} Password <i className={`fas fa-eye${showPassword ? '-slash' : ''}`} /> </Link>

                                </div>
                                <div className="position-relative w-100 h-100">
                                    <input type={showPassword ? "text" : "password"} placeholder="Enter Password" className={`border border-1 rounded w-100 p-2 form-control ${passwordError !== '' ? 'border-danger' : ''}`} id="password" onChange={e => handlePassword(e.target.value)} value={password} />
                                </div>
                                <div className="fw-bold text-danger text-capitalize small" style={{ height: '10px' }}>{passwordError}</div>
                                {/* <Link to="#" className="pe-3" onClick={() => setShowForgotPassword(true)}>Forgot Password?</Link> */}
                                <br />
                            </div>
                            {postLoading ? <button className="btn border btn-lg btn-block font-decoration-none text-uppercase no-drop" onClick={e => e.preventDefault()}>SIGN IN</button> :
                                <button className="btn btn-primary btn-lg btn-block font-decoration-none text-uppercase" onClick={e => preventClick(e, handleSignIn)}>SIGN IN</button>}
                            <br />
                            {signUpUrl && <Link to={signUpUrl} className="small fw-bold">Not yet a customer? Create an account</Link>}
                        </form>
                    ) : (
                        <div className="mt-4">
                            <h4>Reset Password</h4>
                            <div className="form-group mb-4">
                                <label htmlFor="email" className="">Email Address</label>
                                <input type="email" placeholder="Enter Email" className={`border border-1 rounded w-100 p-2 form-control ${emailError !== '' ? 'border-danger' : ''}`} id="email" onChange={e => setEmail(e.target.value)} value={email} autoComplete="off" />
                                <div className="fw-bold text-danger text-capitalize small" style={{ height: '10px' }}>{emailError}</div>
                            </div>
                            <button className="btn btn-primary btn-lg btn-block font-decoration-none text-uppercase" onClick={e => preventClick(e, handlePasswordReset)}>Send Reset Link</button>
                            <br />
                            <button className="btn btn-link" onClick={() => setShowForgotPassword(false)}>Back to Login</button>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )


}

export default Login