import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { api, adminReqConfig } from "../../include/api";
import { isEmptyObj, preventArrow, preventClick, preventWheel, req, tooLong, validateEmail, validatePhoneNumber } from "../../include/function";
import { useNotification } from "../../Toast/ToastProvider";
import { ADD, EDIT, ERROR, SUCCESS } from "../../include/constant";
import Spinner from "../add-ons/Spinner";
import { getAdmin } from "../../include/function";
import { Modal, Button } from "react-bootstrap";

const UserForm = ({ type, title, id }) => {
    const adminUser = getAdmin();
    const toast = useNotification();

    const [saving, setSaving] = useState(false);
    const [fetchingUser, setFetchingUser] = useState(type === EDIT);
    const [roles, setRoles] = useState([]);

    const [user, setUser] = useState({});
    const [userId, setUserId] = useState('');
    const [password, setPassword] = useState('');
    const [name, setName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [emailId, setEmailId] = useState('');
    const [role, setRole] = useState('');

    const [userIdError, setUserIdError] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [nameError, setNameError] = useState('');
    const [phoneNumberError, setPhoneNumberError] = useState('');
    const [emailIdError, setEmailIdError] = useState('');
    const [roleError, setRoleError] = useState('');

    const [showRoleModal, setShowRoleModal] = useState(false);
    const [newRole, setNewRole] = useState('');
    const [newRoleError, setNewRoleError] = useState('');

    useEffect(() => {
        getRoles();
    }, []);

    const getRoles = () => {
        api.get('/role/', adminReqConfig())
            .then((response) => {
                setRoles(response.data.data || []);
            })
            .catch((error) => {
                console.error('Error fetching roles', error);
            });
    };


    const getuser = useCallback((afterGet) => {
        setFetchingUser(true);
        api.get('/user/?id=' + id, adminReqConfig()).then(response => {
            if (response.status === 200) {
                setUser(response.data.data[0]);
                if (afterGet) afterGet(response.data.data);
            }
        }).catch(error => {
            setUser({});
            if (afterGet) afterGet([]);
        }).finally(() => setFetchingUser(false));
    }, [id]);

    const handleUserId = (value) => {
        setUserIdError('');
        setUserId(String(value).trim().toLowerCase());
    };

    const handlePassword = (value) => {
        setPasswordError('');
        setPassword(value);
    };

    const handleName = (value) => {
        setNameError('');
        setName(value);
    };

    const handlePhoneNumber = (value) => {
        setPhoneNumberError('');
        setPhoneNumber(value);
    };

    const handleEmailId = (value) => {
        setEmailIdError('');
        setEmailId(value);
    };

    const handleRole = (value) => {
        setRoleError('');
        setRole(value);
    };

    const clearFields = () => {
        setUserId('');
        setPassword('');
        setName('');
        setPhoneNumber('');
        setEmailId('');
        setRole('');

        setUserIdError('');
        setPasswordError('');
        setNameError('');
        setPhoneNumberError('');
        setEmailIdError('');
        setRoleError('');
    };

    const handleRoleAdd = () => {
        if (newRole.trim() === '') {
            setNewRoleError('Role name is required');
            return;
        }
        api.post('/role/', { roleName: newRole.trim() }, adminReqConfig())
        .then((response) => {
            if (response.data.error) {
                toast({ type: ERROR, message: response.data.message || "Failed to add role" });
            } else {
                setRoles(response.data.data || []); 
                setShowRoleModal(false);
                setNewRole('');
                getRoles();
                toast({ type: SUCCESS, message: response.data.message || "Role added successfully" });
            }
        })
        .catch((error) => {
            console.error('Error adding role', error);
            toast({ type: ERROR, message: "Failed to add role" });
        });
    
    };


    const handleSave = () => {
        let error = false;

        const data = {
            id: String(id).trim(),
            userId: String(userId).trim().toLowerCase(),
            password: String(password).trim(),
            name: String(name).trim(),
            phoneNumber: String(phoneNumber).trim(),
            emailId: String(emailId).trim().toLowerCase(),
            shopId: String(adminUser.id).trim(),
            roleId: String(role).trim(),
        };

        if (type === ADD && data.userId === '') {
            setUserIdError(req('Username'));
            error = true;
        } else if (type === ADD && data.userId.length > 100) {
            setUserIdError(tooLong('Username'));
            error = true;
        }

        if (type === ADD && data.password === '') {
            setPasswordError('Password required');
            error = true;
        } else if (type === ADD && data.password.length < 6) {
            setPasswordError('Password must be at least 6 characters');
            error = true;
        }

        if (data.name === '') {
            setNameError(req('Name'));
            error = true;
        } else if (data.name.length > 500) {
            setNameError(tooLong('Name'));
            error = true;
        }

        if (data.phoneNumber === '') {
            setPhoneNumberError('Phone number required');
            error = true;
        } else if (!validatePhoneNumber(data.phoneNumber)) {
            setPhoneNumberError('Invalid phone number');
            error = true;
        }

        if (data.emailId !== '' && !validateEmail(data.emailId)) {
            setEmailIdError('Invalid email address');
            error = true;
        }

        if (data.role === '') {
            setRoleError(req('Role'));
            error = true;
        }

        if (!error) {
            setSaving(true);
            const apiCall = type === ADD ? api.post : api.put;
            const endpoint = type === ADD ? '/user/' : `/user/?id=${data.id}`;
            apiCall(endpoint, data, adminReqConfig())
                .then(response => {
                    if (response.status === 200) {
                        clearFields();
                        toast({ type: SUCCESS, message: response.data.message });
                    }
                })
                .catch(error => {
                    toast({ type: ERROR, message: error.response ? error.response.data.message : error.message });
                })
                .finally(() => setSaving(false));
        }
    };

    useEffect(() => {
        if (type === EDIT) getuser();
    }, [getuser, type]);

    useEffect(() => {
        if (!isEmptyObj(user)) {
            setUserId(user.userId || '');
            setName(user.name || '');
            setPhoneNumber(user.phoneNumber || '');
            setEmailId(user.emailId || '');
            setRole(user.roleId || '');
        }
    }, [user]);

    return (
        <div className="container my-3">
            <div className="py-3 d-flex justify-content-between align-items-center">
                <h5 className="m-0">{title}</h5>
                {saving ? (
                    <Link to="#" className="btn" onClick={e => e.preventDefault()}>Save</Link>
                ) : (
                    <Link to="#" className="btn btn-primary" onClick={e => preventClick(e, handleSave)}>Save</Link>
                )}
            </div>
            {fetchingUser ? (
                <Spinner color="danger" />
            ) : (
                <>
                    <div className="row">
                        <div className="col-12 col-md-6">
                            <label htmlFor="name" className="small">Name</label>
                            <input
                                type="text"
                                className={`form-control form-control-lg ${nameError !== '' && 'border-danger'}`}
                                placeholder="Name"
                                id="name"
                                autoFocus
                                required
                                autoComplete="off"
                                onChange={e => handleName(e.target.value)}
                                value={name}
                            />
                            <span className="text-danger my-2 small fw-bold">{nameError}</span>
                            <br />
                        </div>
                        <div className="col-12 col-md-6">
                            <label htmlFor="userId" className="small">Username</label>
                            <input
                                type="text"
                                className={`form-control form-control-lg ${userIdError !== '' && 'border-danger'}`}
                                placeholder="Username"
                                id="userId"
                                required
                                autoComplete="off"
                                onChange={e => handleUserId(e.target.value)}
                                value={userId}
                                disabled={type === EDIT}
                                readOnly={type === EDIT}
                            />
                            <span className="text-danger my-2 small fw-bold">{userIdError}</span>
                            <br />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-md-6">
                            <label htmlFor="password" className="small">Password</label>
                            <input
                                type="password"
                                className={`form-control form-control-lg ${passwordError !== '' && 'border-danger'}`}
                                placeholder="Password"
                                id="password"
                                autoComplete="off"
                                onChange={e => handlePassword(e.target.value)}
                                value={password}
                                disabled={type === EDIT}
                                readOnly={type === EDIT}
                            />
                            <span className="text-danger my-2 small fw-bold">{passwordError}</span>
                            <br />
                        </div>
                        <div className="col-12 col-md-6">
                            <label htmlFor="phoneNumber" className="small">Phone Number</label>
                            <input
                                type="text"
                                className={`form-control form-control-lg ${phoneNumberError !== '' && 'border-danger'}`}
                                placeholder="Phone Number"
                                id="phoneNumber"
                                required
                                autoComplete="off"
                                onChange={e => handlePhoneNumber(e.target.value)}
                                value={phoneNumber}
                                onKeyDown={preventArrow}
                                onWheel={preventWheel}
                            />
                            <span className="text-danger my-2 small fw-bold">{phoneNumberError}</span>
                            <br />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-md-6">
                            <label htmlFor="emailId" className="small">Email</label>
                            <input
                                type="email"
                                className={`form-control form-control-lg ${emailIdError !== '' && 'border-danger'}`}
                                placeholder="Email"
                                id="emailId"
                                autoComplete="off"
                                onChange={e => handleEmailId(e.target.value)}
                                value={emailId}
                            />
                            <span className="text-danger my-2 small fw-bold">{emailIdError}</span>
                            <br />
                        </div>
                        <div className="col-12 col-md-6">
                            <label htmlFor="role" className="small">Role</label>
                            <div className="d-flex align-items-center">
                                <select
                                    className={`form-control form-control-lg w-70 me-3 ${roleError !== '' && 'border-danger'}`}
                                    id="role"
                                    required
                                    onChange={e => handleRole(e.target.value)}
                                    value={role}
                                    style={{ flex: 1 }}
                                >
                                    <option value="">Select Role</option>
                                    {roles.map(r => (
                                        <option key={r.id} value={r.id}>{r.roleName}</option>
                                    ))}
                                </select>
                                <button
                                    type="button"
                                    className="btn btn-outline-primary ml-2"
                                    onClick={() => setShowRoleModal(true)}
                                >
                                    +
                                </button>
                            </div>
                            <span className="text-danger my-2 small fw-bold">{roleError}</span>
                            <br />
                        </div>

                    </div>
                </>
            )}

            <Modal show={showRoleModal} onHide={() => setShowRoleModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Add New Role</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <input
                        type="text"
                        className={`form-control ${newRoleError !== '' && 'border-danger'}`}
                        placeholder="Enter role name"
                        value={newRole}
                        onChange={e => {
                            setNewRole(e.target.value);
                            setNewRoleError('');
                        }}
                    />
                    {newRoleError && <span className="text-danger small">{newRoleError}</span>}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowRoleModal(false)}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleRoleAdd}>
                        Add Role
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default UserForm;
