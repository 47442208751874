import { nanoid } from "nanoid"
import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { api, baseURL, superAdminReqConfig } from "../../include/api"
import { ERROR, SUCCESS } from "../../include/constant"
import { useNotification } from "../../Toast/ToastProvider"
import Error from "../add-ons/Error"
import Spinner from "../add-ons/Spinner"
import SuperAdminHeader from "./Header"

const SuperAdminSlide = () => {
    const toast = useNotification()
    const [fetchingSlide, setFetchingSlide] = useState(true)
    const [slides, setSlides] = useState([])

    const getSlides = (afterGet) => {
        setFetchingSlide(true)
        api.get('/slide_show/', superAdminReqConfig()).then(response => {
            if (response.status === 200) {
                setSlides(response.data.data)
                if (afterGet) afterGet(response.data.data)
            }
        }).catch(error => {
            setSlides([])
            if (afterGet) afterGet([])
        }).finally(() => setFetchingSlide(false))
    }

    useEffect(() => {
        getSlides()
    }, [])

    return <SuperAdminHeader >
        <div className="fixed-top-bar">
            <div className="container my-3">
                <div className="py-3 d-flex justify-content-between align-items-center">
                    <h5 className="m-0">Slide</h5>
                    <Link to="/super/add-slide" className="btn btn-success" >Add new slide</Link>
                </div>
                {fetchingSlide ?
                    <Spinner color="danger" spinnerSize="lg" /> :
                    slides.length > 0 ?
                        <div className="row">
                            {slides.map((slide, index) => {
                                return <Slide slide={slide} key={nanoid()} toast={toast} fetchSlides={getSlides} />
                            })}
                        </div> : <Error mainText="No slide found!" secondaryText={<Link to="/super/add-slide">Add New slide</Link>} />}
            </div>
        </div>
    </SuperAdminHeader>
}

const Slide = ({ slide, toast, fetchSlides }) => {

    const handleDeleteSlide = () => {
        if (slide.id) {
            api.delete('/slide_show/?id=' + slide.id, superAdminReqConfig()).then(response => {
                if (response.status === 200) {
                    toast({ type: SUCCESS, message: response.data.message })
                    fetchSlides()
                }
            }).catch(error => {
                error.response ?
                    toast({ type: ERROR, message: error.response.data.message }) :
                    toast({ type: ERROR, message: error.message })
            }).finally()
        }
    }

    return <div className="col-12 col-md-6 col-xl-4 p-2">
        <div className="border shadow-sm w-100 h-100 d-block">
            <div className="img-md w-100 p-3 border-bottom d-flex justify-content-center">
                <img src={baseURL + "/" + slide.imageUrl} alt="" className="fit-contain w-100 h-100" />
            </div>
            <div className="p-2">
                <h6 className="d-flex align-items-center justify-content-between fw-bold">
                    <div className="text-truncate">
                        <i className={`fas fa-circle blink ${slide.status === 'active' ? 'text-success' : 'text-danger'}`} style={{ fontSize: '.7rem' }} />
                        <span className="ps-2">{slide.productName}</span>
                    </div>
                    <Link to={`/super/edit-slide/${slide.id}`} className="small d-flex align-items-center hover-underline">Edit <i className="fas fa-pencil-alt fa-sm ps-2" />
                    </Link>
                    <i className="fas fa-trash-alt fa-sm text-danger" onClick={handleDeleteSlide} />
                </h6>
                <p className="small mb-1 text-truncate text-muted">{slide.shopName}</p>
            </div>
        </div>
    </div>
}

export default SuperAdminSlide