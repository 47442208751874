import { useEffect, useState } from "react";
import { api, superAdminReqConfig } from "../../include/api";
import { ERROR, SUCCESS } from "../../include/constant";
import "../../styles/Super.css";
import { useNotification } from "../../Toast/ToastProvider";
import SuperAdminHeader from "./Header";

const initialFormData = {
    categoryId: "",
    serviceName: "",
    contactPerson: "",
    contactNo: "",
    resourceCount: "",
    status: "Active",
    serviceCharge: "",
    chargeType: ""
};

const ServiceOrManpower = () => {
    const toast = useNotification()
    const [serviceData, setServiceData] = useState([]);
    const [serviceCategoryList, setServiceCategoryList] = useState([]);
    const [formData, setFormData] = useState(initialFormData);
    const [isEditing, setIsEditing] = useState(false);

    // Handle input changes for the form
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = () => {
        if (isEditing) {
            // // Update service
            // const updatedData = serviceData.map((service) =>
            //     service.id === formData.serviceId ? formData : service
            // );
            // setServiceData(updatedData);
            api.put(`/service/?id=${formData.id}`, formData, superAdminReqConfig())
                .then(response => {
                    if (response.status === 200) {
                        // getSettingsdata();
                        toast({ type: SUCCESS, message: response.data.message });
                        getServices();
                        setFormData(initialFormData)
                    }
                }).catch(error => {
                    error.response
                        ? toast({ type: ERROR, message: error.response.data.message })
                        : toast({ type: ERROR, message: error.message });
                });
        } else {
            api.post('/service/', formData, superAdminReqConfig())
                .then(response => {
                    if (response.status === 200) {
                        // getSettingsdata();
                        toast({ type: SUCCESS, message: response.data.message });
                        getServices();
                        setFormData(initialFormData)
                    }
                }).catch(error => {
                    error.response
                        ? toast({ type: ERROR, message: error.response.data.message })
                        : toast({ type: ERROR, message: error.message });
                });
        }
        setFormData(initialFormData);
        setIsEditing(false);
    };

    // Edit service
    const handleEdit = (service) => {
        setFormData(service);
        setIsEditing(true);
    };

    // Delete service
    const handleDelete = (id) => {
        const filteredData = serviceData?.filter((service) => service.id !== id);
        setServiceData(filteredData);
    };

    const getServices = () => {
        api.get('/service/', superAdminReqConfig())
            .then(response => {
                if (response.status === 200) {
                    setServiceData(response.data.data);
                }
            })
            .catch(error => {
                setServiceData([]);
            });
    }

    const getServiceCategory = () => {
        api.get("/service_category/", superAdminReqConfig()).then(response => {
            if (response.status === 200) {
                setServiceCategoryList(response.data.data)
            }
        }).catch(error => {
            setServiceCategoryList([])
        }).finally()
    }

    // Fetch services data from API
    useEffect(() => {
        getServices();
        getServiceCategory()
    }, []);

    return (
        <>
            <SuperAdminHeader>
                <div className="fixed-top-bar">
                    <div className="card">
                        <h5 className="m-2">Service / Manpower</h5>
                        <div className="card-body">

                            {/* Service Form */}
                            <div className="form-section mb-4 shadow p-3">
                                <div className="row">
                                    <div className="col-lg-4">
                                        <label className="mb-2 mt-3">Category </label>
                                        <select
                                            name="categoryId"
                                            value={formData.categoryId}
                                            className="form-control"
                                            type="text"
                                            onChange={handleInputChange}
                                        >
                                            <option value=''>Select service category</option>
                                            {serviceCategoryList.map((scat, i) => {
                                                return <option key={i} value={scat.id}>{scat.serviceCatName}</option>
                                            })}
                                        </select>
                                    </div>
                                    {/* <div className="col-lg-4">
                                        <label className="mb-2 mt-3">Category </label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            name="categoryId"
                                            value={formData.categoryId}
                                            onChange={handleInputChange}
                                        />
                                    </div> */}
                                    <div className="col-lg-4">
                                        <label className="mb-2 mt-3">Service Name:</label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            name="serviceName"
                                            value={formData.serviceName}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                    <div className="col-lg-4">
                                        <label className="mb-2 mt-3">Contact Person:</label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            name="contactPerson"
                                            value={formData.contactPerson}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                    <div className="col-lg-4">
                                        <label className="mb-2 mt-3">Contact No:</label>
                                        <input
                                            className="form-control"
                                            type="number"
                                            name="contactNo"
                                            value={formData.contactNo}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                    <div className="col-lg-4">
                                        <label className="mb-2 mt-3">Resource Count:</label>
                                        <input
                                            className="form-control"
                                            type="number"
                                            name="resourceCount"
                                            value={formData.resourceCount}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                    <div className="col-lg-4">
                                        <label className="mb-2 mt-3">Service Charge:</label>
                                        <input
                                            className="form-control"
                                            type="number"
                                            name="serviceCharge"
                                            value={formData.serviceCharge}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                    <div className="col-lg-4">
                                        <label className="mb-2 mt-3">Charge Type:</label>
                                        <select
                                            className="form-control"
                                            name="chargeType"
                                            value={formData.chargeType}
                                            onChange={handleInputChange}
                                        >
                                            <option value="">Select Charge Type</option>
                                            <option value="daily">Daily</option>
                                            <option value="hourly">Hourly</option>
                                        </select>
                                    </div>
                                    <div className="col-lg-4">
                                        <label className="mb-2 mt-3">Status:</label>
                                        <select
                                            className="form-control"
                                            name="status"
                                            value={formData.status}
                                            onChange={handleInputChange}
                                        >
                                            <option value="Active">Active</option>
                                            <option value="Inactive">Inactive</option>
                                        </select>
                                    </div>
                                    <div className="col-lg-4">
                                        <button
                                            className="btn btn-primary mt-5 mb-1"
                                            onClick={handleSubmit}
                                        >
                                            {isEditing ? "Update" : "Add"} Service
                                        </button>
                                    </div>
                                </div>
                            </div>

                            {/* Data Table */}
                            <div className="table-wrapper">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>S.No</th>
                                            {/* <th className="text-truncate">Service Id</th> */}
                                            <th className="text-truncate">Category</th>
                                            <th className="text-truncate">Service Name</th>
                                            <th className="text-truncate">Contact Person</th>
                                            <th className="text-truncate">Contact No</th>
                                            <th className="text-truncate">Resource Count</th>
                                            <th className="text-truncate">Service Charge</th>
                                            <th className="text-truncate">Charge Type</th>
                                            <th className="text-truncate">Status</th>
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {serviceData.map((service, i) => {
                                            let catName = serviceCategoryList?.find(f => f.id === service.categoryId)?.serviceCatName

                                            return < tr key={service.id} >
                                                <td>{i + 1}</td>
                                                {/* <td>{service.id}</td> */}
                                                <td>{catName}</td>
                                                <td>{service.serviceName}</td>
                                                <td>{service.contactPerson}</td>
                                                <td>{service.contactNo}</td>
                                                <td>{service.resourceCount}</td>
                                                <td>{service.serviceCharge}</td>
                                                <td>{service.chargeType}</td>
                                                <td>{service.status}</td>
                                                <td className="d-flex gap-1">
                                                    <button
                                                        className="btn btn-warning btn-sm"
                                                        onClick={() => handleEdit(service)}
                                                    >
                                                        Edit
                                                    </button>
                                                    <button
                                                        className="btn btn-danger btn-sm mx-2"
                                                        onClick={() => handleDelete(service.id)}
                                                    >
                                                        Delete
                                                    </button>
                                                </td>
                                            </tr>
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </SuperAdminHeader >
        </>
    );
};

export default ServiceOrManpower;
