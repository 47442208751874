import React, { useState } from 'react'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import PageNotFound from './components/add-ons/404'
import AdminAddProduct from './components/admin/AddProduct'
import SubAdminAddShop from './components/admin/AddUser'
import AdminBrand from './components/admin/Brand'
import AdminCategory from './components/admin/Category'
import AdminDashboard from './components/admin/Dashboard'
import AdminEditProduct from './components/admin/EditProduct'
import SubAdminEditShop from './components/admin/EditUser'
import AdminItemsReport from './components/admin/ItemsReport'
import AdminLogin from './components/admin/Login'
import AdminLogout from './components/admin/Logout'
import AdminOrderDetails from './components/admin/OrderDetails'
import AdminOrders from './components/admin/Orders'
import AdminProduct from './components/admin/Product'
import AdminProductReport from './components/admin/ProductsReport'
import AdminProfile from './components/admin/Profile'
import ShopOrders from './components/admin/ShopOrders'
import UsersProfile from './components/admin/UserManagement'
import CustomerCart from './components/customer/Cart'
import CustomerCategory from './components/customer/Category'
import CustomerCheckout from './components/customer/Checkout'
import CustomerHome from './components/customer/Home'
import CustomerLogin from './components/customer/Login'
import CustomerLogout from './components/customer/Logout'
import Notification from './components/customer/Notification'
import CustomerOrderDetails from './components/customer/OrderDetails'
import OrderPlaced from './components/customer/OrderPlaced'
import CustomerOrders from './components/customer/Orders'
import CustomerProduct from './components/customer/Product'
import CustomerProfile from './components/customer/Profile'
import ServiceCategory from './components/customer/ServiceCategory'
import CustomerServiceDetails from './components/customer/ServiceDetails'
import CustomerServiceOrderDetails from './components/customer/ServiceOrderDetails'
import CustomerSignup from './components/customer/Signup'
import CustomerWishlist from './components/customer/Wishlist'
import SuperAdminAddCategory from './components/super/AddCategory'
import SuperAdminAddProduct from './components/super/AddProduct'
import SuperAdminAddServiceCategory from './components/super/AddServiceCategory'
import SuperAdminAddShop from './components/super/AddShop'
import SuperAdminAddSlide from './components/super/AddSlide'
import SuperAdminAddVoucher from './components/super/AddVoucher'
import SuperAdminBrand from './components/super/Brand'
import SuperAdminCategory from './components/super/Category'
import SuperAdminCustomer from './components/super/Customer'
import CustomerEditForm from './components/super/CustomerEditForm'
import CustomerLoyaltyPoints from './components/super/CustomerLoyalPoints'
import CustomerTypePermissions from './components/super/CustomerPermissions'
import SuperAdminDashboard from './components/super/Dashboard'
import SuperAdminEditCategory from './components/super/EditCategory'
import SuperAdminEditServiceCategory from './components/super/EditServiceCategory'
import SuperAdminEditShop from './components/super/EditShop'
import SuperAdminEditSlide from './components/super/EditSlide'
import SuperAdminEditVoucher from './components/super/EditVoucher'
import SuperAdminHomePage from './components/super/HomePage'
import Invoice from './components/super/Invoice'
import ItemsReport from './components/super/ItemsReport'
import SuperAdminLogin from './components/super/Login'
import SuperAdminLogout from './components/super/Logout'
import Orders from './components/super/Orders'
import SuperPagesComponent from './components/super/Pages'
import Payment from './components/super/Payments'
import SuperAdminProduct from './components/super/Products'
import ProductReport from './components/super/ProductsReport'
import SuperAdminServiceCategory from './components/super/ServiceCategory'
import ServiceItemReport from './components/super/ServiceItemReport'
import ServiceOrManpower from './components/super/ServiceOrManpower'
import ServiceReport from './components/super/ServiceReport'
import Settings from './components/super/Settings'
import Shipping from './components/super/Shipping'
import SuperAdminShop from './components/super/Shop'
import SuperAdminSlide from './components/super/Slide'
import Tracking from './components/super/Tracking'
import UserFinancialReport from './components/super/UsersFinancialReport'
import SuperAdminVoucher from './components/super/Voucher'
import { ADMIN, ADMIN_TOKEN, CUSTOMER, CUSTOMER_TOKEN, SUPER_ADMIN, SUPER_ADMIN_TOKEN } from './include/constant'
import { getAdmin, getCustomer, getSuperAdmin, isEmptyObj, setToLocalStorage } from './include/function'
import './styles/App.css'
import './styles/LargeScreen.css'
import './styles/MediumScreen.css'
import './styles/SideNavBar.css'
import './styles/SmallScreen.css'

const App = () => {
    const [customer, setCustomer] = useState(getCustomer())
    const [admin, setAdmin] = useState(getAdmin())
    const [superAdmin, setSuperAdmin] = useState(getSuperAdmin())

    const handleCustomer = (customer, token) => {
        setToLocalStorage(CUSTOMER, customer)
        setToLocalStorage(CUSTOMER_TOKEN, token)
        setTimeout(() => {
            setCustomer(customer)
        }, 500)
    }

    const handleAdmin = (admin, token) => {
        setToLocalStorage(ADMIN, admin)
        setToLocalStorage(ADMIN_TOKEN, token)
        setTimeout(() => {
            setAdmin(admin)
        }, 500)
    }

    const handleSuperAdmin = (superAdmin, token) => {
        setToLocalStorage(SUPER_ADMIN, superAdmin)
        setToLocalStorage(SUPER_ADMIN_TOKEN, token)
        setTimeout(() => {
            setSuperAdmin(superAdmin)
        }, 500)
    }

    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<CustomerHome />} />
                <Route
                    path="/admin/login"
                    element={isEmptyObj(admin) ? <AdminLogin handleAdmin={handleAdmin} /> : <Navigate to="/admin" />}
                />
                <Route
                    path="/admin/logout"
                    element={isEmptyObj(admin) ? <Navigate to="/admin" /> : <AdminLogout setAdmin={setAdmin} />}
                />
                <Route
                    path="/admin/order"
                    element={<AuthRoute user={admin} element={<AdminOrders />} navigate="/admin/login" />}
                />
                <Route
                    path="/admin/order-details/:id"
                    element={<AuthRoute user={admin} element={<AdminOrderDetails />} navigate="/admin/login" />}
                />
                <Route
                    path="/admin/product"
                    element={<AuthRoute user={admin} element={<AdminProduct />} navigate="/admin/login" />}
                />
                <Route
                    path="/admin/add-product"
                    element={<AuthRoute user={admin} element={<AdminAddProduct />} navigate="/admin/login" />}
                />
                <Route
                    path="/admin/edit-product/:id"
                    element={<AuthRoute user={admin} element={<AdminEditProduct />} navigate="/admin/login" />}
                />
                <Route
                    path="/admin/profile"
                    element={<AuthRoute user={admin} element={<AdminProfile />} navigate="/admin/login" />}
                />
                <Route path="/admin" element={<Navigate to="/admin/dashboard" />} />
                <Route
                    path="/admin/dashboard"
                    element={<AuthRoute user={admin} element={<AdminDashboard />} navigate="/admin/login" />}
                />
                <Route
                    path="/admin/sales/orders"
                    element={<AuthRoute user={admin} element={<ShopOrders from='sales' />} navigate="/admin/login" />}
                />
                <Route
                    path="/admin/category"
                    element={<AuthRoute user={admin} element={<AdminCategory from='catalogue' />} navigate="/admin/login" />}
                />
                <Route
                    path="/admin/brand"
                    element={<AuthRoute user={admin} element={<AdminBrand from='catalogue' />} navigate="/admin/login" />}
                />
                <Route
                    path="/admin/reports/orders"
                    element={<AuthRoute user={admin} element={<ShopOrders from='reports' />} navigate="/admin/login" />}
                />
                <Route
                    path="/admin/reports/items"
                    element={<AuthRoute user={admin} element={<AdminItemsReport />} navigate="/super/login" />}
                />
                <Route
                    path="/admin/reports/products"
                    element={<AuthRoute user={admin} element={<AdminProductReport />} navigate="/super/login" />}
                />
                <Route
                    path="/user/user-management"
                    element={<AuthRoute user={admin} element={<UsersProfile />} navigate="/login" />}
                />
                <Route
                    path="/user/user-role"
                    element={<AuthRoute user={admin} element={<UsersProfile />} navigate="/login" />}
                />
                {/* <Route
                    path="/admin/subuser"
                    element={<AuthRoute user={superAdmin} element={<SubUser />} navigate="/super/login" />}
                /> */}
                <Route
                    path="/admin/add-user"
                    element={<AuthRoute user={superAdmin} element={<SubAdminAddShop />} navigate="/super/login" />}
                />
                <Route
                    path="/admin/edit-user/:id"
                    element={<AuthRoute user={superAdmin} element={<SubAdminEditShop />} navigate="/super/login" />}
                />

                <Route
                    path="/super/login"
                    element={isEmptyObj(superAdmin) ? <SuperAdminLogin handleSuperAdmin={handleSuperAdmin} /> : <Navigate to="/super" />}
                />
                <Route
                    path="/super/logout"
                    element={isEmptyObj(superAdmin) ? <Navigate to="/super" /> : <SuperAdminLogout setSuperAdmin={setSuperAdmin} />}
                />
                <Route
                    path="/super/category"
                    element={<AuthRoute user={superAdmin} element={<SuperAdminCategory />} navigate="/super/login" />}
                />
                <Route
                    path="/super/service_category"
                    element={<AuthRoute user={superAdmin} element={<SuperAdminServiceCategory />} navigate="/super/login" />}
                />

                <Route
                    path="/super/brand"
                    element={<AuthRoute user={superAdmin} element={<SuperAdminBrand />} navigate="/super/login" />}
                />
                <Route
                    path="/super/add-service-category"
                    element={<AuthRoute user={superAdmin} element={<SuperAdminAddServiceCategory />} navigate="/super/login" />}
                />
                <Route
                    path="/super/edit-service-category/:id"
                    element={<AuthRoute user={superAdmin} element={<SuperAdminEditServiceCategory />} navigate="/super/login" />}
                />
                <Route
                    path="/super/add-category"
                    element={<AuthRoute user={superAdmin} element={<SuperAdminAddCategory from='Category' />} navigate="/super/login" />}
                />
                <Route
                    path="/super/add-brand"
                    element={<AuthRoute user={superAdmin} element={<SuperAdminAddCategory from='Brand' />} navigate="/super/login" />}
                />
                <Route
                    path="/super/edit-category/:id"
                    element={<AuthRoute user={superAdmin} element={<SuperAdminEditCategory from='Category' />} navigate="/super/login" />}
                />

                <Route
                    path="/super/edit-brand/:id"
                    element={<AuthRoute user={superAdmin} element={<SuperAdminEditCategory from='Brand' />} navigate="/super/login" />}
                />
                <Route
                    path="/super/customer"
                    element={<AuthRoute user={superAdmin} element={<SuperAdminCustomer />} navigate="/super/login" />}
                />
                <Route
                    path="/super/edit-customer/:id"
                    element={<AuthRoute user={superAdmin} element={<CustomerEditForm />} navigate="/super/login" />}
                />
                <Route
                    path="/super/shop"
                    element={<AuthRoute user={superAdmin} element={<SuperAdminShop />} navigate="/super/login" />}
                />
                <Route
                    path="/super/add-shop"
                    element={<AuthRoute user={superAdmin} element={<SuperAdminAddShop />} navigate="/super/login" />}
                />
                <Route
                    path="/super/edit-shop/:id"
                    element={<AuthRoute user={superAdmin} element={<SuperAdminEditShop />} navigate="/super/login" />}
                />
                <Route
                    path="/super/voucher"
                    element={<AuthRoute user={superAdmin} element={<SuperAdminVoucher />} navigate="/super/login" />}
                />
                <Route
                    path="/super/add-voucher"
                    element={<AuthRoute user={superAdmin} element={<SuperAdminAddVoucher />} navigate="/super/login" />}
                />
                <Route
                    path="/super/edit-voucher/:id"
                    element={<AuthRoute user={superAdmin} element={<SuperAdminEditVoucher />} navigate="/super/login" />}
                />
                <Route
                    path="/super/slide"
                    element={<AuthRoute user={superAdmin} element={<SuperAdminSlide />} navigate="/super/login" />}
                />
                <Route
                    path="/super/add-slide"
                    element={<AuthRoute user={superAdmin} element={<SuperAdminAddSlide />} navigate="/super/login" />}
                />
                <Route
                    path="/super/edit-slide/:id"
                    element={<AuthRoute user={superAdmin} element={<SuperAdminEditSlide />} navigate="/super/login" />}
                />
                <Route
                    path="/super/home_page"
                    element={<AuthRoute user={superAdmin} element={<SuperAdminHomePage />} navigate="/super/login" />}
                />
                <Route
                    path="/super/settings"
                    element={<AuthRoute user={superAdmin} element={<Settings />} navigate="/super/login" />}
                />
                <Route
                    path="/super/sales/orders"
                    element={<AuthRoute user={superAdmin} element={<Orders from='sales' />} navigate="/super/login" />}
                />
                <Route
                    path="/super/reports/orders"
                    element={<AuthRoute user={superAdmin} element={<Orders from='reports' />} navigate="/super/login" />}
                />
                <Route
                    path="/super/sales/invoice"
                    element={<AuthRoute user={superAdmin} element={<Invoice from='sales' />} navigate="/super/login" />}
                />
                <Route
                    path="/super/reports/invoice"
                    element={<AuthRoute user={superAdmin} element={<Invoice from='reports' />} navigate="/super/login" />}
                />
                <Route
                    path="/super/sales/payments"
                    element={<AuthRoute user={superAdmin} element={<Payment />} navigate="/super/login" />}
                />
                <Route
                    path="/super/sales/shipping"
                    element={<AuthRoute user={superAdmin} element={<Shipping from='sales' />} navigate="/super/login" />}
                />
                <Route
                    path="/super/reports/shipping"
                    element={<AuthRoute user={superAdmin} element={<Shipping from='reports' />} navigate="/super/login" />}
                />
                <Route
                    path="/super/sales/tracking"
                    element={<AuthRoute user={superAdmin} element={<Tracking />} navigate="/super/login" />}
                />
                <Route
                    path="/super/products"
                    element={<AuthRoute user={superAdmin} element={<SuperAdminProduct />} navigate="/super/login" />}
                />
                <Route
                    path="/super/service_or_manpower"
                    element={<AuthRoute user={superAdmin} element={<ServiceOrManpower />} navigate="/super/login" />}
                />
                <Route
                    path="/super/pages"
                    element={<AuthRoute user={superAdmin} element={<SuperPagesComponent />} navigate="/super/login" />}
                />
                <Route
                    path="/super/customer_type_permissions"
                    element={<AuthRoute user={superAdmin} element={<CustomerTypePermissions />} navigate="/super/login" />}
                />
                <Route
                    path="/super/loyal_points"
                    element={<AuthRoute user={superAdmin} element={<CustomerLoyaltyPoints />} navigate="/super/login" />}
                />
                <Route
                    path="/super/reports/items"
                    element={<AuthRoute user={superAdmin} element={<ItemsReport />} navigate="/super/login" />}
                />
                <Route
                    path="/super/add-product"
                    element={<AuthRoute user={superAdmin} element={<SuperAdminAddProduct />} navigate="/super/login" />}
                />
                <Route
                    path="/super/reports/products"
                    element={<AuthRoute user={superAdmin} element={<ProductReport />} navigate="/super/login" />}
                />
                <Route
                    path="/super/reports/users_financial"
                    element={<AuthRoute user={superAdmin} element={<UserFinancialReport />} navigate="/super/login" />}
                />
                <Route
                    path="/super/reports/service"
                    element={<AuthRoute user={superAdmin} element={<ServiceReport />} navigate="/super/login" />}
                />
                <Route
                    path="/super/reports/service_orders"
                    element={<AuthRoute user={superAdmin} element={<ServiceItemReport />} navigate="/super/login" />}
                />
                <Route path="/super" element={<Navigate to="/super/dashboard" />} />
                <Route path="/super/dashboard" element={<AuthRoute user={superAdmin} element={<SuperAdminDashboard />} navigate="/super/login" />} />

                <Route path="/product/:id" element={<CustomerProduct />} />
                <Route path="/service/:id" element={<CustomerServiceDetails />} />
                <Route path="/product" element={<PageNotFound />} />
                <Route path="/category/:id" element={<CustomerCategory />} />
                <Route path="/service_category/:id" element={<ServiceCategory />} />
                <Route path="/category" element={<PageNotFound />} />
                <Route path="/cart/:id/:flag" element={<CustomerCart />} />
                <Route path="/wishlist" element={<CustomerWishlist />} />
                <Route path="/notification" element={<Notification />} />

                <Route
                    path="/login"
                    element={isEmptyObj(customer) ? <CustomerLogin handleCustomer={handleCustomer} /> : <Navigate to="/" />}
                />
                <Route
                    path="/register"
                    element={isEmptyObj(customer) ? <CustomerSignup handleCustomer={handleCustomer} /> : <Navigate to="/" />}
                />
                <Route
                    path="/logout"
                    element={isEmptyObj(customer) ? <Navigate to="/" /> : <CustomerLogout setCustomer={setCustomer} />}
                />
                <Route
                    path="/checkout/:flag"
                    element={<AuthRoute user={customer} element={<CustomerCheckout />} navigate="/login" />}
                />
                <Route
                    path="/profile/personal-info"
                    element={<AuthRoute user={customer} element={<CustomerProfile />} navigate="/login" />}
                />
                <Route
                    path="/order-details/:id"
                    element={<AuthRoute user={customer} element={<CustomerOrderDetails />} navigate="/login" />}
                />
                <Route
                    path="/service-order-details/:id"
                    element={<AuthRoute user={customer} element={<CustomerServiceOrderDetails />} navigate="/login" />}
                />
                <Route
                    path="/profile/orders"
                    element={<AuthRoute user={customer} element={<CustomerProfile from='profile' />} navigate="/login" />}
                />
                <Route
                    path="/profile/orderPlaced"
                    element={<AuthRoute user={customer} element={<OrderPlaced />} navigate="/login" />}
                />
                <Route
                    path="/orders"
                    element={<AuthRoute user={customer} element={<CustomerOrders from='orders' />} navigate="/login" />}
                />
                <Route
                    path="/profile/address"
                    element={<AuthRoute user={customer} element={<CustomerProfile />} navigate="/login" />}
                />
                <Route
                    path="/profile/change-password"
                    element={<AuthRoute user={customer} element={<CustomerProfile />} navigate="/login" />}
                />
                <Route path="*" element={<PageNotFound />} />
            </Routes>
        </BrowserRouter>
    )
}

const AuthRoute = ({ user, element, navigate }) => {
    return isEmptyObj(user) ? <Navigate to={navigate} /> : element
}

export default App
