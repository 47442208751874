// import { nanoid } from "nanoid"
// import React, { useEffect, useState } from "react"
// import { Link } from "react-router-dom"
// import { api, superAdminReqConfig } from "../../include/api"
// import Error from "../add-ons/Error"
// import Spinner from "../add-ons/Spinner"
// import SuperAdminHeader from "./Header"

// const SuperAdminCustomer = () => {

//     const [customers, setCustomers] = useState([])
//     const [fetchingCustomers, setFetchingCustomers] = useState(true)

//     useEffect(() => getCustomers(), [])

//     const getCustomers = () => {
//         setFetchingCustomers(true)
//         api.get('/customer/', superAdminReqConfig()).then(response => {
//             if (response.status === 200) {
//                 setCustomers(response.data.data)
//             }
//         }).catch(error => {
//             setCustomers([])
//         }).finally(() => setFetchingCustomers(false))
//     }

//     return <SuperAdminHeader >
//         <div className="fixed-top-bar">
//             <div className="container my-3">
//                 <div className="py-3">
//                     <h5 className="m-0">Customer</h5>
//                 </div>
//                 {fetchingCustomers ?
//                     <Spinner color="danger" spinnerSize="lg" /> :
//                     customers.length > 0 ?
//                         <div className="row">
//                             {customers.map((customer, index) => {
//                                 return <Customer customer={customer} key={nanoid()} />
//                             })}
//                         </div> : <Error mainText="No customer found!" />}
//             </div>
//         </div>
//     </SuperAdminHeader>
// }

// const Customer = ({ customer }) => {
//     return <div className="col-12 col-md-6 col-lg-4 col-xl-3 p-2">
//         <div className="border shadow-sm p-2">
//             <h6 className="d-flex align-items-center justify-content-between fw-bold">
//                 <div className="text-truncate">
//                     <i className={`fas fa-circle blink ${customer.status === 'active' ? 'text-success' : 'text-danger'}`} style={{ fontSize: '.7rem' }} />
//                     <span className="ps-2">{customer.name}</span>
//                     {/* <Link to="/super/add-brand" className="btn btn-success" >Edit</Link> */}

//                 </div>
//                 <Link to={`/super/edit-customer/${customer.id}`} className="small d-flex align-items-center hover-underline">Edit <i className="fas fa-pencil-alt fa-sm ps-2" /></Link>
//             </h6>
//             <hr className="my-2" />
//             <p className="small mb-1 text-muted">
//                 <i className="fas fa-mobile-alt fa-md fa-fw text-primary" />
//                 <span className="ps-2 text-truncate">{customer.phoneNumber}</span>
//             </p>
//             <p className="small mb-1 text-muted">
//                 <i className="fas fa-envelope fa-md fa-fw text-primary" />
//                 <span className="ps-2 text-truncate">{customer.emailId}</span>
//             </p>
//             <p className="small mb-1 text-muted">
//                 <i className="fas fa-envelope fa-md fa-fw text-primary" />
//                 <span className="ps-2 text-truncate">{customer.customerType}</span>
//             </p>
//             <div className="text-end">
//                 <Link to={'/cart/' + customer.id + '/super'} className="btn btn-sm btn-danger">View Cart</Link>
//             </div>
//         </div>
//     </div>
// }

// export default SuperAdminCustomer

import { nanoid } from "nanoid";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { api, superAdminReqConfig } from "../../include/api";
import Error from "../add-ons/Error";
import Spinner from "../add-ons/Spinner";
import SuperAdminHeader from "./Header";

const SuperAdminCustomer = () => {
    const [customers, setCustomers] = useState([]);
    const [filteredCustomers, setFilteredCustomers] = useState([]);
    const [fetchingCustomers, setFetchingCustomers] = useState(true);
    const [searchTerm, setSearchTerm] = useState("");

    useEffect(() => {
        getCustomers();
    }, []);

    const getCustomers = () => {
        setFetchingCustomers(true);
        api.get('/customer/', superAdminReqConfig()).then(response => {
            if (response.status === 200) {
                setCustomers(response.data.data);
                setFilteredCustomers(response.data.data);
            }
        }).catch(error => {
            setCustomers([]);
            setFilteredCustomers([]);
        }).finally(() => setFetchingCustomers(false));
    };

    const handleSearch = (e) => {
        const term = e.target.value.toLowerCase();
        setSearchTerm(term);

        const filtered = customers.filter((customer) =>
            customer.name.toLowerCase().includes(term) ||
            customer.emailId.toLowerCase().includes(term) ||
            customer.phoneNumber.toLowerCase().includes(term) ||
            customer.customerType.toLowerCase().includes(term)
        );

        setFilteredCustomers(filtered);
    };

    return (
        <SuperAdminHeader>
            <div className="fixed-top-bar">
                <div className="container my-3">
                    <div className="py-3">
                        <h5 className="m-0">Customer</h5>
                    </div>
                    <input
                        type="text"
                        placeholder="Search by Name, Email, Phone, or Customer Type"
                        className="form-control mb-3"
                        value={searchTerm}
                        onChange={handleSearch}
                    />
                    {fetchingCustomers ? (
                        <Spinner color="danger" spinnerSize="lg" />
                    ) : filteredCustomers.length > 0 ? (
                        <div className="table-responsive">
                            <table className="table table-bordered table-hover">
                                <thead className="table-light">
                                    <tr>
                                        <th>#</th>
                                        <th>Name</th>
                                        <th>Email</th>
                                        <th>Phone</th>
                                        <th>Customer Type</th>
                                        <th>Status</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {filteredCustomers?.map((customer, index) => (
                                        <tr key={nanoid()}>
                                            <td>{index + 1}</td>
                                            <td>{customer.name}</td>
                                            <td>{customer.emailId}</td>
                                            <td>{customer.phoneNumber}</td>
                                            <td>{customer.customerType}</td>
                                            <td>
                                                <span className={`badge ${customer.status === 'active' ? 'bg-success' : 'bg-danger'}`}>
                                                    {customer.status === 'active' ? 'Active' : 'Inactive'}
                                                </span>
                                            </td>
                                            <td>
                                                <Link to={`/cart/${customer.id}/super`} className="btn btn-sm btn-danger me-2">View Cart</Link>
                                                <Link to={`/super/edit-customer/${customer.id}`} className="btn btn-sm btn-primary">Edit</Link>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    ) : (
                        <Error mainText="No customer found!" />
                    )}
                </div>
            </div>
        </SuperAdminHeader>
    );
};

export default SuperAdminCustomer;

