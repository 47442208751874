import React, { useCallback, useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { api, superAdminReqConfig } from "../../include/api"
import { ADD, EDIT, ERROR, SUCCESS } from "../../include/constant"
import { isEmptyObj, preventArrow, preventClick, preventWheel, req, tooLong } from "../../include/function"
import { useNotification } from "../../Toast/ToastProvider"
import Spinner from "../add-ons/Spinner"

const VoucherForm = ({ type, title, id }) => {
    const toast = useNotification()

    const [saving, setSaving] = useState(false)
    const [fetchingVoucher, setFetchingVouocher] = useState(type === EDIT)

    const [voucher, setVoucher] = useState({})
    const [code, setCode] = useState('')
    const [text, setText] = useState('')
    const [mode, setMode] = useState('')
    const [value, setValue] = useState('')
    const [maxCount, setMaxCount] = useState('')
    const [validity, setValidity] = useState('')
    const [status, setStatus] = useState('')

    const [codeError, setCodeError] = useState('')
    const [textError, setTextError] = useState('')
    const [modeError, setModeError] = useState('')
    const [valueError, setValueError] = useState('')
    const [maxCountError, setMaxCountError] = useState('')
    const [validityError, setValidityError] = useState('')
    const [statusError, setStatusError] = useState('')

    const getVoucher = useCallback((afterGet) => {
        setFetchingVouocher(true)
        api.get('/voucher/?id=' + id, superAdminReqConfig()).then(response => {
            if (response.status === 200) {
                setVoucher(response.data.data[0])
                if (afterGet) afterGet(response.data.data[0])
            }
        }).catch(error => {
            setVoucher([])
            if (afterGet) afterGet([])
        }).finally(() => setFetchingVouocher(false))
    }, [id])

    const handleCode = (value) => {
        setCodeError('')
        setCode(String(value).toUpperCase())
    }

    const handleText = (value) => {
        setTextError('')
        setText(value)
    }

    const handleMode = (value) => {
        setModeError('')
        setMode(value)
    }

    const handleValue = (value) => {
        setValueError('')
        setValue(value)
    }

    const handleMaxCount = (value) => {
        setMaxCountError('')
        setMaxCount(value)
    }

    const handleValidity = (value) => {
        setValidityError('')
        setValidity(value)
    }

    const handleStatus = (value) => {
        setStatusError('')
        setStatus(value)
    }

    const clearFields = () => {
        setCode('')
        setText('')
        setMode('')
        setValue('')
        setMaxCount('')
        setValidity('')
        setStatus('')

        setCodeError('')
        setTextError('')
        setModeError('')
        setValueError('')
        setMaxCountError('')
        setValidityError('')
        setStatusError('')
    }

    const handleSave = () => {
        let error = false

        const data = {
            id: String(id).trim(),
            code: String(code).trim().toUpperCase(),
            text: String(text).trim(),
            mode: String(mode).trim(),
            value: String(value).trim(),
            maxCount: String(maxCount).trim(),
            validity: String(validity).trim(),
            status: String(status).trim(),
        }

        if (data.code === '') {
            setCodeError(req('Code'))
            error = true
        } else if (data.code.length !== 6) {
            setCodeError('Code must be 6 characters long')
            error = true
        }

        if (data.text === '') {
            setTextError(req('Text'))
            error = true
        } else if (data.text > 100) {
            setTextError(tooLong('Text'))
            error = true
        }

        if (data.mode === '') {
            setModeError(req('Mode'))
            error = true
        }

        if (data.value === '') {
            setValueError(req('Value'))
            error = true
        }

        if (data.maxCount === '') {
            setMaxCountError(req('Maximum count'))
            error = true
        }

        if (data.validity === '') {
            setValidityError(req('Validity'))
            error = true
        }

        if (data.status === '') {
            setStatusError(req('Status'))
            error = true
        }

        if (!error) {
            if (type === ADD) {
                setSaving(true)
                api.post('/voucher/', data, superAdminReqConfig()).then(response => {
                    if (response.status === 200) {
                        clearFields()
                        toast({ type: SUCCESS, message: response.data.message })
                    }
                }).catch(error => {
                    if (error.response) {
                        if (error.response.status === 409) setCodeError(error.response.data.message)
                        else toast({ type: ERROR, message: error.response.data.message })
                    } else toast({ type: ERROR, message: error.message })
                }).finally(() => setSaving(false))
            } else if (type === EDIT) {
                setSaving(true)
                api.put('/voucher/?id=' + data.id, data, superAdminReqConfig()).then(response => {
                    if (response.status === 200) {
                        toast({ type: SUCCESS, message: response.data.message })
                    }
                }).catch(error => {
                    if (error.response) {
                        if (error.response.status === 409) setCodeError(error.response.data.message)
                        else toast({ type: ERROR, message: error.response.data.message })
                    } else toast({ type: ERROR, message: error.message })
                }).finally(() => setSaving(false))
            }
        }
    }

    useEffect(() => {
        if (type === EDIT) getVoucher()
    }, [getVoucher, type])

    useEffect(() => {
        if (!isEmptyObj(voucher)) {
            setCode(voucher.code)
            setText(voucher.text)
            setMode(voucher.mode)
            setValue(voucher.value)
            setMaxCount(voucher.maxCount)
            setValidity(voucher.validity.split(' ')[0])
            setStatus(voucher.status)
        }
    }, [voucher])

    return <div className="container my-3">
        <div className="py-3 d-flex justify-content-between align-items-center">
            <h5 className="m-0">{title}</h5>
            {saving ? <Link to="#" className="btn" onClick={e => e.preventDefault()}>Save</Link> :
                <Link to="#" className="btn btn-primary" onClick={e => preventClick(e, handleSave)}>Save</Link>}
        </div>
        {fetchingVoucher ? <Spinner color="danger" /> : <>
            <div className="row">
                <div className="col-12 col-sm-6 col-md-4">
                    <label htmlFor="code" className="small">Code</label>
                    <input type="text" className={`form-control form-control-lg ${codeError !== '' && 'border-danger'}`} placeholder="Code" id="code" required autoComplete="off" onChange={e => handleCode(e.target.value)} value={code} disabled={type !== ADD} readOnly={type !== ADD} />
                    <span className="text-danger my-2 small fw-bold">{codeError}</span>
                    <br />
                </div>
                <div className="col-12 col-sm-6 col-md-4">
                    <label htmlFor="value" className="small">Value</label>
                    <input type="number" className={`form-control form-control-lg ${valueError !== '' && 'border-danger'}`} placeholder="Value" id="value" required autoComplete="off" onFocus={e => preventWheel(e)} onKeyDown={e => preventArrow(e)} onChange={e => handleValue(e.target.value)} value={value} />
                    <span className="text-danger my-2 small fw-bold">{valueError}</span>
                    <br />
                </div>
                <div className="col-12 col-sm-6 col-md-4">
                    <label htmlFor="maxCount" className="small">Maximum count</label>
                    <input type="number" className={`form-control form-control-lg ${maxCountError !== '' && 'border-danger'}`} placeholder="Maximum count" id="maxCount" required autoComplete="off" onFocus={e => preventWheel(e)} onKeyDown={e => preventArrow(e)} onChange={e => handleMaxCount(e.target.value)} value={maxCount} />
                    <span className="text-danger my-2 small fw-bold">{maxCountError}</span>
                    <br />
                </div>
                <div className="col-12 col-md-6">
                    <label htmlFor="validity" className="small">Validity</label>
                    <input type="date" className={`form-control form-control-lg ${validityError !== '' && 'border-danger'}`} placeholder="Validity" id="validity" required autoComplete="off" onChange={e => handleValidity(e.target.value)} value={validity} />
                    <span className="text-danger my-2 small fw-bold">{validityError}</span>
                    <br />
                </div>
                <div className="col-12 col-md-6">
                    <label htmlFor="text" className="small">Text</label>
                    <input type="text" className={`form-control form-control-lg ${textError !== '' && 'border-danger'}`} placeholder="Text" id="text" required autoComplete="off" onChange={e => handleText(e.target.value)} value={text} />
                    <span className="text-danger my-2 small fw-bold">{textError}</span>
                    <br />
                </div>
                <div className="col-12 col-md-6">
                    <label className="small">Mode</label>
                    <div className="row">
                        <div className="col-6">
                            <label htmlFor="amount" className={`small form-control form-control-lg d-flex justify-content-between align-items-center cursor-pointer ${mode === 'amount' ? 'bg-primary text-white fw-bold' : ''}`}>
                                <span>Amount</span>
                                <input type="radio" className="form-check-input" name="mode" id="amount" checked={mode === 'amount'} onChange={e => handleMode('amount')} />
                            </label>
                        </div>
                        <div className="col-6">
                            <label htmlFor="percent" className={`small form-control form-control-lg d-flex justify-content-between align-items-center cursor-pointer ${mode === 'percent' ? 'bg-primary text-white fw-bold' : ''}`}>
                                <span>Percent</span>
                                <input type="radio" className="form-check-input" name="mode" id="percent" checked={mode === 'percent'} onChange={e => handleMode('percent')} />
                            </label>
                        </div>
                    </div>
                    <span className="text-danger my-2 small fw-bold">{modeError}</span>
                    <br />
                </div>
                <div className="col-12 col-md-6">
                    <label className="small">Status</label>
                    <div className="row">
                        <div className="col-6">
                            <label htmlFor="active" className={`small form-control form-control-lg d-flex justify-content-between align-items-center cursor-pointer ${status === 'active' ? 'bg-success text-white fw-bold' : ''}`}>
                                <span>Active</span>
                                <input type="radio" className="form-check-input" name="status" id="active" checked={status === 'active'} onChange={e => handleStatus('active')} />
                            </label>
                        </div>
                        <div className="col-6">
                            <label htmlFor="inactive" className={`small form-control form-control-lg d-flex justify-content-between align-items-center cursor-pointer ${status === 'inactive' ? 'bg-danger text-white fw-bold' : ''}`}>
                                <span>Inactive</span>
                                <input type="radio" className="form-check-input" name="status" id="inactive" checked={status === 'inactive'} onChange={e => handleStatus('inactive')} />
                            </label>
                        </div>
                    </div>
                    <span className="text-danger my-2 small fw-bold">{statusError}</span>
                    <br />
                </div>
            </div>
        </>}
    </div>
}
export default VoucherForm