import { nanoid } from "nanoid"
import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { api, baseURL, customerReqConfig } from "../../include/api"

const CustomerCategoryBar = () => {
    const [fetchLoading, setFetchLoading] = useState(true)
    const [categoryList, setCategoryList] = useState([])

    useEffect(() => {
        getCategory()
    }, [])

    const getCategory = () => {
        setFetchLoading(true)
        api.get('/category/?parentId=0', customerReqConfig()).then(response => {
            if (response.status === 200) {
                setCategoryList(response.data.data.filter(cl => cl.name !== 'Home'))
            }
        }).catch(error => {
            setCategoryList([])
        }).finally(() => setFetchLoading(false))
    }



    return <div className="overflow-hidden py-2 border-bottom shadow-sm">
        <div className="container">
            <div className="hide-scrollbar text-center" style={{ overflowX: 'auto', overflowY: 'hidden', whiteSpace: 'nowrap' }}>
                {fetchLoading ?
                    <div className="w-100" style={{ width: '80px', height: '80px' }} /> :
                    categoryList && categoryList.map(category => {
                        return <Category category={category} key={nanoid()} />
                    })}
            </div>
        </div>
    </div>
}

const Category = ({ category }) => {
    return <Link to={'/category/' + category.id} className="d-inline-block mx-3 text-dark hover-primary" style={{ width: '80px', height: '80px' }}>
        <div style={{ width: '100%', height: '100%' }}>
            <img className="fit-contain w-100" style={{ height: '100px', margin: '10px 0' }} src={baseURL + '/' + category.imageUrl} alt="" />
        </div>
        <p className="fw-bold small text-center mt-3">{category.name}</p>
    </Link>
}

export default CustomerCategoryBar