export const CUSTOMER = 'customer'
export const ADMIN = 'admin'
export const SUPER_ADMIN = 'superAdmin'

export const CUSTOMER_TOKEN = 'customerToken'
export const ADMIN_TOKEN = 'adminToken'
export const SUPER_ADMIN_TOKEN = 'superAdminToken'

export const CART = 'cart'
export const SERVICE_CART = 'serviceCart'

export const SUCCESS = 'success'
export const ERROR = 'error'

export const ADD = 'add'
export const EDIT = 'edit'
export const DELETE = 'delete'

export const CHECKOUT = 'checkout'

export const ORDERED = 'ordered'
export const PACKED = 'packed'
export const SHIPPED = 'shipped'
export const DELIVERED = 'delivered'
export const CANCELLED = 'cancelled'
export const CANCEL_REQUEST = 'cancel request'
export const RETURN_APPROVED = 'return approved'
export const RETURN_DECLINED = 'return declined'
export const RETURN_REQUEST = 'return request'
export const RETURN_SHIPPED = 'return shipped'
export const RETURNED = 'returned'
export const RETURN_RECEIVED = 'return received'
export const APPROVE = 'approve'
export const DECLINE = 'decline'