import { nanoid } from "nanoid";
import React from "react";
import { Link, useLocation } from "react-router-dom";

const CustomerSideNavBar = ({ toggleClass, setToggleClass, content }) => {
    const { pathname } = useLocation();

    const handleClose = () => {
        setToggleClass('');
    };

    const wrapperStyles = {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        background: 'rgba(0, 0, 0, 0.5)',
        opacity: toggleClass === 'open' ? 1 : 0,
        visibility: toggleClass === 'open' ? 'visible' : 'hidden',
        transition: 'visibility 0.3s, opacity 0.3s',
        zIndex: 9999
    };

    const sidebarStyles = {
        position: 'fixed',
        top: 0,
        left: toggleClass === 'open' ? 0 : '-300px',
        width: '300px',
        height: '100%',
        backgroundColor: '#fff',
        boxShadow: '2px 0 5px rgba(0, 0, 0, 0.5)',
        transition: 'left 0.3s'
    };

    return (
        <div style={wrapperStyles}>
            <div style={sidebarStyles}>
                <div className="w-100 px-3 py-3 d-flex align-items-center justify-content-between" style={{ backgroundColor: '#2874F0' }}>
                    {/* <h6 className="m-0 text-white text-uppercase">My Shop</h6> */}
                    <Link to="#" className="d-block text-light ms-3">
                        BUILDTON
                    </Link>
                    <div
                        className="ps-3 cursor-pointer"
                        onClick={() => handleClose()}
                    >
                        <i className="fas fa-times text-white" />
                    </div>
                </div>
                {content.map((nav) => {
                    return (
                        <Link
                            to={nav.url}
                            key={nanoid()}
                            className={`d-block border-bottom p-3 small fw-bold ${nav.url === pathname
                                ? 'text-white bg-danger'
                                : 'text-dark'
                                }`}
                            onClick={() => setToggleClass('')}
                        >
                            <i className={`fas fa-${nav.icon}`} />
                            <span className="ps-2 text-uppercase">
                                {nav.text}
                            </span>
                        </Link>
                    );
                })}
            </div>
        </div>
    );
};

export default CustomerSideNavBar;
