import React, { useState, useEffect } from "react";
import Select from "react-select";
import getUserMenuItems from "./AdminMenuContent";
import { api, adminReqConfig } from "../../include/api";
import { getAdmin } from "../../include/function";

const dynamicModules = getUserMenuItems().flatMap(menuItem => {
    if (menuItem.children && menuItem.children.length > 0) {
        return menuItem.children.map(child => ({
            pageParentTitle: menuItem.text || "",
            pageTitle: (child.parentKey ? child.parentKey + "/" : "") + (child.text || ""),
            pageUrl: child.url || "",
            add: false,
            edit: false,
            view: false,
            delete: false,
        }));
    } else if (menuItem.text !== "Dashboard" && menuItem.text !== "Logout" && menuItem.text !== "Profile") {
        return [{
            pageTitle: menuItem.text || "",
            pageUrl: menuItem.url || "",
            add: false,
            edit: false,
            view: false,
            delete: false,
        }];
    } else {
        return []; 
    }
});


const RoleManagement = () => {
    const user = getAdmin()
    const [menuContent, setMenuContent] = useState(dynamicModules);
    const [roles, setRoles] = useState([]);
    const [rolesId, setRolesById] = useState([]);
    const [selectedRole, setSelectedRole] = useState([]);
    const [role, setRole] = useState('');
    const [roleError, setRoleError] = useState('');
    const [editData, setEditData] = useState(null);
    const [id, setId] = useState(null);
    const [formType, setFormType] = useState('ADD'); 

    useEffect(() => {
        getRoles();
    }, []);

    useEffect(() => {
        if (selectedRole.length > 0) {
            getRoleById();
        }
    }, [selectedRole]);

    const getRoles = () => {
        api.get('/role/', adminReqConfig())
            .then((response) => {
                setRoles(response.data.data || []);
            })
            .catch((error) => {
                console.error('Error fetching roles', error);
            });
    };

    const getRoleById = () => {
        const selectedRoleIds = selectedRole.map(role => role.value);
        if (selectedRoleIds.length > 0) {
            api.get('/role_permission/?roleId=' + selectedRoleIds, adminReqConfig())
                .then((response) => {
                    if (response.data.data && response.data.data.length > 0) {
                        setRolesById(response.data.data);
                        setMenuContent(prevMenuContent => 
                            prevMenuContent.map(module => {
                                const roleData = response.data.data.find(data => data.pageTitle === module.pageTitle);
                                return roleData
                                    ? { 
                                        ...module, 
                                        add: roleData.add === "true", 
                                        edit: roleData.edit === "true", 
                                        view: roleData.view === "true", 
                                        delete: roleData.delete === "true"
                                      }
                                    : module;
                            })
                        );
                        setFormType('EDIT');
                        setId(response.data.data[0].id); 
                    } else {
                        setRolesById([]);
                        setFormType('ADD');
                    }
                })
                .catch((error) => {
                    console.error('Error fetching role permissions', error);
                });
        }
    };
    

    const handleRoleChange = (selectedOption) => {
        setSelectedRole(selectedOption || []);
    };

    const handlePermissionChange = (pageTitle, action, checked) => {
        setMenuContent(prevMenuContent =>
            prevMenuContent.map(module =>
                module.pageTitle === pageTitle
                    ? { ...module, [action]: checked }
                    : module
            )
        );
    };

    const handleSave = () => {
        let error = false;

        const roleIds = selectedRole.map(role => role.value)

        const roleProperties = menuContent.map(module => ({
            pageTitle: module.pageTitle,
            pageUrl: module.pageUrl,
            add: module.add,
            edit: module.edit,
            view: module.view,
            delete: module.delete,
            shopId: user.id
        }));

        console.log(roleProperties)

        const roleData = {
            id: id,
            roleId: roleIds ,
            roleProperties: roleProperties,
        };

        if (!error) {
            if (rolesId.length == 0) {
                api.post('/role_permission/', roleData, adminReqConfig())
                    .then(response => {
                        alert('Role added successfully');
                        getRoles();
                        resetStates();
                    })
                    .catch(error => {
                        console.error('Error adding role:', error);
                        alert('Error adding role: ' + error.message);
                    });
            } else if (rolesId.length > 0) {
                api.put(`/role_permission/?roleId=` + roleIds, roleData, adminReqConfig())
                    .then(response => {
                        alert('Role updated successfully');
                        resetStates();
                        setFormType('ADD');
                    })
                    .catch(error => {
                        alert('Error updating role: ' + error.message);
                    });
            }
        }
    };

    const resetStates = () => {
        setRole('');
        setRoleError('');
        setMenuContent(dynamicModules);
        setSelectedRole([]);
        setEditData(null);
        setId(null);
    };

    return (
        <div className="container">
            <h5>Select Role</h5>
            <div className="mb-3">
                <Select
                    options={roles.map(role => ({ value: role.id, label: role.roleName }))}
                    placeholder="Select Roles"
                    isMulti
                    value={selectedRole}
                    onChange={handleRoleChange}
                />
            </div>

            <h4>Assign Permissions</h4>
            {menuContent.length > 0 ? (
                <table className="table">
                    <thead>
                        <tr>
                            <th>Menu</th>
                            <th>View</th>
                            <th>Add</th>
                            <th>Edit</th>
                            <th>Delete</th>
                        </tr>
                    </thead>
                    <tbody>
                        {menuContent.map(menu => (
                            <tr key={menu.pageTitle}>
                                <td>{menu.pageTitle}</td>
                                <td>
                                    <input
                                        type="checkbox"
                                        className="form-check-input"
                                        checked={menu.view}
                                        onChange={(e) => handlePermissionChange(menu.pageTitle, 'view', e.target.checked)}
                                    />
                                </td>
                                <td>
                                    <input
                                        type="checkbox"
                                        className="form-check-input"
                                        checked={menu.add}
                                        onChange={(e) => handlePermissionChange(menu.pageTitle, 'add', e.target.checked)}
                                    />
                                </td>
                                <td>
                                    <input
                                        type="checkbox"
                                        className="form-check-input"
                                        checked={menu.edit}
                                        onChange={(e) => handlePermissionChange(menu.pageTitle, 'edit', e.target.checked)}
                                    />
                                </td>
                                <td>
                                    <input
                                        type="checkbox"
                                        className="form-check-input"
                                        checked={menu.delete}
                                        onChange={(e) => handlePermissionChange(menu.pageTitle, 'delete', e.target.checked)}
                                    />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            ) : (
                <p>No menu content available.</p>
            )}
            {(formType === 'ADD' || formType === 'EDIT') && (
                <div className="d-flex justify-content-end hstack gap-1 mt-2">
                    <button className="btn btn-sm fw-bold btn-secondary" onClick={resetStates}>Add New</button>
                    <button className="btn btn-sm fw-bold btn-success" onClick={handleSave}>{rolesId.length > 0 ? "Update" : "Save"}</button>
                </div>
            )}
        </div>
    );
};

export default RoleManagement;
