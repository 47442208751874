import React , { useState, useEffect } from "react";
import { api, superAdminReqConfig } from "../../include/api";
import 'react-toastify/dist/ReactToastify.css';
import "../../styles/Super.css";
import SuperAdminHeader from "./Header";


const Tracking = () => {
    const [fetchLoading, setFetchLoading] = useState(true);
    const [trackingData, setTrackingData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [fromDate, setFromDate] = useState("");
    const [toDate, setToDate] = useState("");
    const [searchQuery, setSearchQuery] = useState("");

    const getTracking = (afterGet) => {
        setFetchLoading(true);
        api.get('/tracking/', superAdminReqConfig()).then(response => {
            if (response.status === 200) {
                setTrackingData(response.data.data);
                setFilteredData(response.data.data);
                if (afterGet) afterGet(response.data.data);
            }
        }).catch(error => {
            setTrackingData([]);
            setFilteredData([]);
            if (afterGet) afterGet([]);
        }).finally(() => setFetchLoading(false));
    };

    useEffect(() => {
        getTracking();
    }, []);

    // Handle Date Filter
    const handleFilter = () => {
        const from = new Date(fromDate);
        const to = new Date(toDate);

        const filtered = trackingData.filter((data) => {
            const lastUpdated = new Date(data.date);
            return lastUpdated >= from && lastUpdated <= to;
        });

        setFilteredData(filtered);
    };

    // Handle Search Filter
    const handleSearch = (query) => {
        setSearchQuery(query);

        const filtered = trackingData.filter((data) => {
            return (
                data.orderId?.toString().toLowerCase().includes(query?.toLowerCase()) ||
                data.customerName?.toString().toLowerCase().includes(query?.toLowerCase()) ||
                data.description?.toLowerCase().includes(query.toLowerCase())
            );
        });

        setFilteredData(filtered);
    };

    return (
        <>
            <SuperAdminHeader>
                <div className="fixed-top-bar">
                    <div className="card">
                        <h5 className="m-2">Tracking</h5>
                        <div className="card-body">
                            {/* Date Filters */}
                            <div className="filter-section shadow p-2 rounded">
                                <div className="row d-flex">
                                    <div className="date-filter col-lg-4 col-sm-6 col-xl-4">
                                        <label>From Date:</label>
                                        <input
                                            className="form-control"
                                            type="date"
                                            value={fromDate}
                                            onChange={(e) => setFromDate(e.target.value)}
                                        />
                                    </div>
                                    <div className="date-filter col-lg-4 col-sm-6 col-xl-4">
                                        <label>To Date:</label>
                                        <input
                                            className="form-control"
                                            type="date"
                                            value={toDate}
                                            onChange={(e) => setToDate(e.target.value)}
                                        />
                                    </div>
                                    <div className="mt-2 col-lg-4 col-sm-6 col-xl-4">
                                        <button className=" mx-2 my-3 btn btn-primary" onClick={handleFilter}>
                                           Apply Filter
                                        </button>
                                    </div>
                                </div>
                            </div>

                            <div className="card my-4 row">
                                <div className="search-section m-3 col-lg-9 col-md-8 col-xl-8">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Search by Order ID, Customer ID, or Description"
                                        value={searchQuery}
                                        onChange={(e) => handleSearch(e.target.value)}
                                    />
                                </div>

                                {/* Tracking Data Table */}
                                <div className="table-wrapper">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th>S.No</th>
                                                <th>Order Id</th>
                                                <th>Customer Name</th>
                                                <th>Description</th>
                                                <th>Last Updated</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {filteredData.map((tracking, index) => {
                                                // Ensure the description exists and handle cases where it might be a single string
                                                const description = tracking.description || "No description available";
                                                const descriptionLines = description.split('\n');

                                                return (
                                                    <tr key={tracking.tracking_id}>
                                                        <td>{index + 1}</td>
                                                        <td>{tracking.orderId}</td>
                                                        <td>{tracking.customerName}</td>
                                                        <td>
                                                            {descriptionLines.map((line, lineIndex) => {
                                                                if (line.includes("Tracking No:")) {
                                                                    const trackingNumber = line.replace("Tracking No: ", "").trim();
                                                                    return (
                                                                        <React.Fragment key={lineIndex}>
                                                                            Tracking No:{" "}
                                                                            {trackingNumber.startsWith('http') ? (
                                                                                <a
                                                                                    href={trackingNumber}
                                                                                    target="_blank"
                                                                                    rel="noopener noreferrer"
                                                                                >
                                                                                    {trackingNumber}
                                                                                </a>
                                                                            ) : (
                                                                                trackingNumber
                                                                            )}
                                                                            <br />
                                                                        </React.Fragment>
                                                                    );
                                                                } else {
                                                                    // Render other lines with a <br /> to maintain new lines
                                                                    return (
                                                                        <React.Fragment key={lineIndex}>
                                                                            {line}
                                                                            <br />
                                                                        </React.Fragment>
                                                                    );
                                                                }
                                                            })}
                                                        </td>
                                                        <td>{tracking.date}</td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </SuperAdminHeader>
        </>
    );
};

export default Tracking;