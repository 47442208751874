import React from "react"
import { SUPER_ADMIN, SUPER_ADMIN_TOKEN } from "../../include/constant"
import Logout from "../common/Logout"

const SuperAdminLogout = ({ setSuperAdmin }) => {
    return <Logout
        setUser={setSuperAdmin}
        tokenKey={SUPER_ADMIN_TOKEN}
        userKey={SUPER_ADMIN}
    />
}

export default SuperAdminLogout
