import { nanoid } from "nanoid";
import React, { useCallback, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import AdminHeader from "./Header";
import { getAdmin } from "../../include/function";
import SubUser from "./SubUser";
import RoleManagement from "./RoleManagement";

const UsersProfile = () => {
    const { pathname } = useLocation();
    const user = getAdmin();
    const [body, setBody] = useState('');

    const menuContent = useCallback(() => {
        return [
            {
                url: "/user/user-management",
                text: "User Management",
                icon: "user",
                body: <SubUser />
            },
            {
                url: "/user/user-role",
                text: "Role",
                icon: "user",
                body: <RoleManagement />
            }
        ];
    }, []);

    useEffect(() => {
        menuContent().forEach(menu => {
            if (pathname === menu.url) {
                setBody(menu.body);
            }
        });
    }, [menuContent, pathname]);

    return (
        <AdminHeader>
        <div className="fixed-top-bar">
            <div className="container my-3">
                <div className="row">
                    <div className="col-12">
                        <div className="p-2 border d-flex align-items-center shadow-sm mb-3">
                            <img className="img-xs rounded-circle border border-1 me-3" src={process.env.PUBLIC_URL + '/img/profile.png'} alt="" />
                            <h6 className="m-0 text-truncate">Hi, {user.name}</h6>
                        </div>

                        <div className="mb-3 overflow-auto hide-scrollbar" style={{ whiteSpace: "nowrap" }}>
                            {menuContent().map(menu => {
                                return <TopNavBarContent
                                    icon={menu.icon}
                                    text={menu.text}
                                    url={menu.url}
                                    pathname={pathname}
                                    key={nanoid()}
                                />
                            })}
                        </div>

                        <div className="p-2 border shadow-sm mt-3" style={{marginBottom: "10%"}}>
                            {body}
                        </div>
                    </div>
                </div>
            </div>
            </div>
        </AdminHeader>
    );
};

const TopNavBarContent = ({ url, icon, text, pathname }) => {
    return (
        <Link to={url} className={`${pathname === url ? 'prof-side-active' : ''} d-inline-block px-3 py-2`}>
            <i className={`fas fa-${icon} text-primary me-3`} />
            <span className="text-muted small text-uppercase fw-bold">{text}</span>
        </Link>
    );
};

export default UsersProfile;
