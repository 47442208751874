import { nanoid } from "nanoid"
import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { api, adminReqConfig } from "../../include/api"
import Error from "../add-ons/Error"
import Spinner from "../add-ons/Spinner"
import { getAdmin } from "../../include/function"

const SubUser = () => {
    const [fetchLoading, setFetchLoading] = useState(true)
    const [userList, setUserList] = useState([])
    const user = getAdmin()

    const getUsers = (afterGet) => {
        setFetchLoading(true)
        api.get('/user/users/?shopId=' + user.id, adminReqConfig()).then(response => {
            if (response.status === 200) {
                setUserList(response.data.data)
                if (afterGet) afterGet(response.data.data)
            }
        }).catch(error => {
            setUserList([])
            if (afterGet) afterGet([])
        }).finally(() => setFetchLoading(false))
    }

    useEffect(() => {
        getUsers()
    }, [])

    return  <>
        <div className="container my-3">
            <div className="py-3 d-flex justify-content-between align-items-center">
                <h5 className="m-0">Users</h5>
                <Link to="/admin/add-user" className="btn btn-success" >Add new user</Link>
            </div>
            {fetchLoading ?
                <Spinner color="danger" spinnerSize="lg" /> :
                userList.length > 0 ?
                    <div className="row">
                        {userList.map((user, index) => {
                            return <User user={user} key={nanoid()} />
                        })}
                    </div> : <Error mainText="No shop found!" secondaryText={<Link to="/admin/add-user">Add New shop</Link>} />}
        </div>
    </>
}

const User = ({ user }) => {
    return <div className="col-12 col-md-6 col-lg-4 col-xl-3 p-2">
        <div className="border shadow-sm p-2">
            <h6 className="d-flex align-items-center justify-content-between fw-bold">
                <div className="text-truncate">
                    <span className="ps-2">{user.name}</span>
                </div>
                <Link to={`/admin/edit-user/${user.id}`} className="small d-flex align-items-center hover-underline">Edit <i className="fas fa-pencil-alt fa-sm ps-2" />
                </Link>
            </h6>
            <hr className="my-2" />
            <p className="small mb-1 text-muted d-flex align-items-center">
                <i className="fas fa-phone-alt fa-md fa-fw text-primary" />
                <span className="ps-2 text-truncate">{user.phoneNumber}</span>
            </p>
            <p className="small mb-1 text-muted d-flex align-items-center">
                <i className="fas fa-envelope fa-md fa-fw text-primary" />
                <span className="ps-2 text-truncate">{user.emailId}</span>
            </p>
            {/* <p className="small mb-1 text-muted d-flex">
                <i className="fas fa-map-marker-alt fa-md fa-fw text-primary pt-1" />
                <span className="ps-2">{user.area}<br />{user.address}<br />{user.pin}</span>
            </p> */}
        </div>
    </div>
}

export default SubUser