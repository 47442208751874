import React from "react"
import { useParams } from "react-router-dom"
import { EDIT } from "../../include/constant"
import ProductForm from "../common/ProductForm"
import AdminHeader from "./Header"

const AdminEditProduct = () => {
    const { id } = useParams()
    return <AdminHeader >
        <div className="fixed-top-bar">
        <ProductForm
            id={id}
            title="Edit Product"
            type={EDIT}
        />
    </div>
    </AdminHeader>
}

export default AdminEditProduct