import React from 'react';
import Chart from 'react-apexcharts';

// interface dataSet {
//     name: string,
//     data: number[],
// }

// interface Iprops {
//     datasets: dataSet[]
//     labels: string[]
//     title: string
//     colors: string[]
// }
// simple bar chart
const BarChart = (props) => {
    // default options
    const { colors, datasets, labels, title } = props

    const apexBarChartOpts = {
        chart: {
            height: 380,
            type: 'bar',
            toolbar: {
                show: true,
            },
        },
        plotOptions: {
            bar: {
                horizontal: false,
                borderRadius: 4,
                columnWidth: '30%',
                dataLabels: {
                    position: 'top',
                },
            },
        },
        dataLabels: {
            enabled: false,
            offsetX: -6,
            style: {
                fontSize: '12px',
                colors: ['#fff'],
            },
        },
        colors: colors,
        stroke: {
            show: true,
            width: 1,
            colors: ['#fff'],
            curve: 'smooth'
        },

        xaxis: {
            categories: labels,
        },
        legend: {
            offsetY: -10,
        },
        states: {
            hover: {
                filter: {
                    type: 'none',
                },
            },
        },
        grid: {
            borderColor: '#f1f3fa',
        },
    };

    // chart data
    // const apexBarChartData = [
    //     {
    //         name: 'Series 1',
    //         data: [44, 55, 41, 64, 22, 43, 21],
    //     },
    //     {
    //         name: 'Series 2',
    //         data: [53, 32, 33, 52, 13, 44, 32],
    //     },
    // ];

    return (
        <>
            <h4 className="header-title mb-3">{title}</h4>
            <Chart options={apexBarChartOpts} series={datasets} type="bar" className="apex-charts" height={400} />
        </>
    );
};

export default BarChart;
