import { useState, useEffect } from "react";
import { api, superAdminReqConfig } from "../../include/api";
import "../../styles/Super.css";
import SuperAdminHeader from "./Header";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import Papa from "papaparse";


const Payment = () => {
    const [fetchLoading, setFetchLoading] = useState(true);
    const [paymentData, setPaymentData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [fromDate, setFromDate] = useState("");
    const [toDate, setToDate] = useState("");
    const [searchQuery, setSearchQuery] = useState("");

    const getPayment = (afterGet) => {
        setFetchLoading(true);
        api.get('/payment/', superAdminReqConfig()).then(response => {
            if (response.status === 200) {
                setPaymentData(response.data.data);
                setFilteredData(response.data.data);
                if (afterGet) afterGet(response.data.data);
            }
        }).catch(error => {
            setPaymentData([]);
            setFilteredData([]);
            if (afterGet) afterGet([]);
        }).finally(() => setFetchLoading(false));
    };

    useEffect(() => {
        getPayment();
    }, []);

    // Handle Date Filter
    const handleFilter = () => {
        const from = new Date(fromDate);
        const to = new Date(toDate);

        const filtered = paymentData.filter((data) => {
            const paymentDate = new Date(data.date);
            return paymentDate >= from && paymentDate <= to;
        });

        setFilteredData(filtered);
    };

    // Handle Search Filter
    const handleSearch = (query) => {
        setSearchQuery(query);

        const filtered = paymentData.filter((data) => {
            return (
                data.orderId?.toString().toLowerCase().includes(query?.toLowerCase()) ||
                data.customerId?.toString().toLowerCase().includes(query?.toLowerCase()) ||
                data.paymentMode?.toLowerCase().includes(query.toLowerCase()) 
            );
        });

        setFilteredData(filtered);
    };

    return (
        <>
            <SuperAdminHeader>
                <div className="fixed-top-bar">
                    <div className="card">
                        <h5 className="m-2">Payment</h5>
                        <div className="card-body">
                            {/* Date Filters */}
                            <div className="filter-section shadow p-2 rounded">
                                <div className="row d-flex">
                                    <div className="date-filter col-lg-4 col-sm-6 col-xl-4">
                                        <label>From Date:</label>
                                        <input
                                            className="form-control"
                                            type="date"
                                            value={fromDate}
                                            onChange={(e) => setFromDate(e.target.value)}
                                        />
                                    </div>
                                    <div className="date-filter col-lg-4 col-sm-6 col-xl-4">
                                        <label>To Date:</label>
                                        <input
                                            className="form-control"
                                            type="date"
                                            value={toDate}
                                            onChange={(e) => setToDate(e.target.value)}
                                        />
                                    </div>
                                    <div className="mt-2 col-lg-4 col-sm-6 col-xl-4">
                                        <button
                                            className="mx-2 my-3 btn btn-primary"
                                            onClick={handleFilter}
                                        >
                                            Apply Filter
                                        </button>
                                    </div>
                                </div>
                            </div>
                            {/* Search Filter */}
                            <div className="card my-4 row">
                                <div className="search-section m-3 col-lg-9 col-md-8 col-xl-8">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Search by Order ID, Customer ID, or Payment Method"
                                        value={searchQuery}
                                        onChange={(e) => handleSearch(e.target.value)}
                                    />
                                </div>

                                {/* Payments Table */}
                                <div className="table-wrapper">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th>S.No</th>
                                                <th>Order Id</th>
                                                <th>Customer Id</th>
                                                <th>Payment Date</th>
                                                <th>Payment Status</th>
                                                <th>Payment Method</th>
                                                <th>Amount</th>
                                                <th>Currency</th>
                                                <th>Address</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {filteredData.map((od, i) => (
                                                <tr key={od.payment_id}>
                                                    <td>{i + 1}</td>
                                                    <td>{od.orderId}</td>
                                                    <td>{od.customerId}</td>
                                                    <td>{od.date}</td>
                                                    <td>{od.paymentStatus}</td>
                                                    <td>{od.paymentMode}</td>
                                                    <td>{od.amount}</td>
                                                    <td>{od.currency}</td>
                                                    <td>
                                                        {od.address}
                                                        <br />
                                                        {od.city}
                                                        <br />
                                                        {od.state}
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </SuperAdminHeader>
        </>
    );
};

export default Payment;
