import { nanoid } from "nanoid"
import React, { useCallback, useEffect, useState } from "react"
import { Link, useNavigate, useParams } from "react-router-dom"
import { api, baseURL, customerReqConfig } from "../../include/api"
import { ERROR, SUCCESS } from "../../include/constant"
import { getCustomer, getServiceCart, isEmptyObj, setServiceCart } from "../../include/function"
import { useNotification } from "../../Toast/ToastProvider"
import Spinner from "../add-ons/Spinner"
import CustomerHeader from "./Header"

const CustomerServiceDetails = () => {
    const { id } = useParams()
    const toast = useNotification()
    const navigate = useNavigate()
    const [localServiceCart, setLocalServiceCart] = useState(getServiceCart())

    const [fetchLoading, setFetchLoading] = useState(true)
    const [service, setService] = useState({})
    const [selectedImage, setSelectedImage] = useState('')
    const [images, setImages] = useState([])

    const customer = getCustomer();

    const getProduct = useCallback(() => {
        setFetchLoading(true)
        api.get('/service/?id=' + id, customerReqConfig()).then(response => {
            if (response.status === 200) {
                setService(response.data.data[0])
            }
        }).catch(error => {
            setService([])
        }).finally(() => setFetchLoading(false))
    }, [id])

    // const isInCart = () => !isEmptyObj(customer)
    //     ? cartData && cartData[0] ? cartData[0].cartItems?.filter(c => String(c.productId) === String(id)).length > 0 : false
    //     : localCart.filter(c => String(c.id) === String(id)).length > 0
    // const isInCart = () => localCart.filter(c => String(c.id) === String(id)).length > 0

    console.log(service);
    // console.log(isInCart());
    const handleServiceCheckout = async () => {
        let inactiveProducts = localServiceCart[0]?.serviceCartItems?.filter(f => f.status === 'Inactive')
        if (inactiveProducts.length === 0) {

            // setPlaceOrderLoading(true)
            // await setToLocalStorage(CHECKOUT, checkout)
            setTimeout(() => {
                navigate('/checkout/service')
            }, 1000)
        } else toast({ type: ERROR, message: 'Please remove unavaiable services from your cart' })

    }

    const isInCart = () => localServiceCart && localServiceCart[0] ? localServiceCart[0].serviceCartItems?.filter(c => String(c.serviceId) === String(service.id)).length > 0 : false

    const handleAddToCart = (e, from) => {
        if (!isEmptyObj(customer)) {

            let cartIndex = localServiceCart.findIndex(cart => cart.customerId === customer.id);

            if (cartIndex === -1) {
                // 2) No cart exists, add the full new cart
                let newCart = {
                    id: nanoid(),
                    customerId: customer.id,
                    name: customer.name,
                    totalItems: 1,
                    totalAmount: service.serviceCharge,
                    serviceCartItems: [
                        {
                            serviceId: service.id,
                            name: service.serviceName,
                            qty: 1,
                            price: service.serviceCharge,
                            status: service.status,
                            image: service.imageUrl,
                        }
                    ]
                };
                let serviceCart = [...localServiceCart, newCart];
                setLocalServiceCart(serviceCart);
                setServiceCart(serviceCart); // local storage 
                toast({ type: SUCCESS, message: 'Service Cart Added successfully' })
                if (from === 'buynow') handleServiceCheckout()
            } else {
                // Cart exists
                let existingCart = localServiceCart[cartIndex];
                let itemIndex = existingCart.serviceCartItems.findIndex(item => item.serviceId === service.id);

                if (itemIndex === -1) {
                    // 1) Cart exists but item is new, add the item to serviceCartItems
                    existingCart.serviceCartItems.push({
                        serviceId: service.id,
                        name: service.serviceName,
                        qty: 1,
                        price: service.serviceCharge,
                        status: service.status,
                        image: service.imageUrl,

                    });
                    existingCart.totalItems += 1;
                    existingCart.totalAmount += service.serviceCharge;
                } else {
                    // 3) Cart and item both exist, update qty, totalItems, and totalAmount
                    let existingItem = existingCart.serviceCartItems[itemIndex];
                    existingItem.qty += 1;
                    existingCart.totalItems += 1;
                    existingCart.totalAmount += service.serviceCharge;
                }

                // Update cart in the list and save
                let updatedCarts = [...localServiceCart];
                updatedCarts[cartIndex] = existingCart;
                setLocalServiceCart(updatedCarts);
                setServiceCart(updatedCarts); // local storage
                toast({ type: SUCCESS, message: 'Service Cart Updated successfully' })
                if (from === 'buynow') handleServiceCheckout()
            }
        } else if (isEmptyObj(customer)) {
            navigate('/login')
        };
    }

    useEffect(() => {
        getProduct()
    }, [getProduct])

    useEffect(() => {
        if (!isEmptyObj(service)) {
            setImages(service.images ? [service.mainImage, ...service.images.map(image => image.imageUrl)] : [service.imageUrl])
        }
    }, [service])

    return <div className="fixed-top-bar overflow-hidden">
        <CustomerHeader enableSearch enableButtons />
        <div className="container my-3">
            {fetchLoading ? <div className="py-5"><Spinner color="danger" /></div> :
                <div className="row">
                    <div className="col-12 col-xl-4 my-2">
                        <div className="w-100 border border-1 d-flex justify-content-between flex-column">
                            <div className="text-end m-2">
                                {/* Share Icon */}
                                <i
                                    className="fa fa-share-alt cursor-pointer"
                                    onClick={() => {
                                        const productLink = window.location.href; // Get the current page URL
                                        navigator.clipboard.writeText(productLink); // Copy to clipboard
                                        alert('Product link copied to clipboard!'); // Optional: show an alert or custom toast
                                    }}
                                    style={{ fontSize: '17px', color: '#555' }}
                                ></i>
                            </div>
                            <div className="p-3">
                                <div className="row">
                                    <div className="col-3">
                                        {images.map(image => {
                                            const src = baseURL + '/' + image;
                                            return (
                                                <div className="box-sm border border-1 my-2 cursor-pointer hover-border overflow-hidden p-1 scale"
                                                    onMouseEnter={e => setSelectedImage(src)}
                                                    key={nanoid()}>
                                                    <img src={src} className="w-100 h-100 fit-contain" alt="" />
                                                </div>
                                            );
                                        })}
                                    </div>
                                    <div className="col-9">
                                        <img
                                            src={selectedImage !== '' ? selectedImage : baseURL + '/' + service.imageUrl}
                                            className="w-100 fit-contain"
                                            alt=""
                                            style={{ minHeight: '50px', maxHeight: '500px' }}
                                        />
                                    </div>
                                </div>
                            </div>
                            {/* <div className="m-2 d-flex justify-content-between align-items-center">
                                <div>
                                    <i><Feather.Heart size={16} strokeWidth={1.6} /></i>
                                    <Link to='#' className="ms-1 text-primary text-decoration-underline" onClick={wishListHandler}>
                                        Add to Wishlist
                                    </Link>
                                </div>

                            </div> */}
                            <div className="text-center bg-light d-flex justify-content-around border-top">
                                {isInCart()
                                    // ? <button className="btn btn-primary btn-lg btn-block" onClick={e => e.preventDefault()}>Added</button>
                                    ? <Link to="/cart/' '/customer" className="btn btn-primary btn-lg btn-block">Go to cart</Link>
                                    : <>
                                        <div className="">
                                            <button
                                                className="btn text-truncate text-left btn-add-to-cart"
                                                onClick={e => handleAddToCart(e)}
                                            >Add to cart</button>
                                            <button
                                                className="btn text-right btn-buy-now"
                                                onClick={e => handleAddToCart(e, 'buynow')}
                                            >Buy Now</button>
                                        </div>
                                    </>
                                }
                            </div>
                        </div>
                    </div>

                    <div className="col-12 col-xl-8 my-2">
                        <div className="px-3 py-2 border border-1">
                            <h4 className="my-2 text-capitalize">{service.serviceName}</h4>
                            {/* {!product.discountValue || product.discountValue === '' || Number(product.discountValue) === 0 ? */}
                            <h4 className="fw-bold">&#8377;{Math.ceil(service.serviceCharge)}</h4>
                            <div className="text-dark text-uppercase  fw-normal my-2">Charge Type   : {service.chargeType}</div>
                            <div className="text-dark text-uppercase  fw-normal my-2">Resource Count: {service.resourceCount}</div>
                            <div className="text-dark text-uppercase  fw-normal my-2">Contact Person: {service.contactPerson}</div>
                            <div className="text-dark text-uppercase  fw-normal my-2">Contact No    : {service.contactNo}</div>
                            {/* <div>
                                    <span className="h4 fw-bold">&#8377;{Math.ceil(discountPrice(product.price, product.discountValue, product.discountMode))}</span>
                                    <del className="h4 text-muted mx-3">&#8377;{Math.ceil(product.price)}</del>
                                    <span className="fw-bold text-success fs-6">{product.discountLabel}</span>
                                </div>} */}
                            {/* <div className="badge bg-success">{product.rating} &#9733;</div> */}
                            {/* <p className="mt-3 remove-margin-bottom" dangerouslySetInnerHTML={{ __html: product.description }} /> */}
                            <hr />
                            <div>
                                {service.status === 'Active'
                                    ? <span className=" text-success blinking-badge">Service Available</span>
                                    : <span className=" text-danger blinking-badge">Service Currently Unavailable</span>
                                }
                            </div>
                            {/* <ul>
                                {(product.warrantyMode !== '' && Number(product.warrantyMode) !== 0) && <li><span className="my-1 small">{product.warrantyLabel}</span></li>}
                                {(product.deliveryCharge !== '' && Number(product.deliveryCharge) !== 0) && <li><span className="my-1 small">&#8377;{product.deliveryCharge} Delivery charge</span></li>}
                            </ul> */}
                        </div>

                    </div>
                </div>}
        </div>
    </div>
}

export default CustomerServiceDetails