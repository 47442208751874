import React, { useState } from "react"
import { Link } from "react-router-dom"
import { api } from "../../include/api"
import { ERROR, SUCCESS } from "../../include/constant"
import { useNotification } from "../../Toast/ToastProvider"
import Spinner from "../add-ons/Spinner"

const ChangePassword = ({ apiUrl, reqConfig }) => {
    const toast = useNotification()

    const [currentPassword, setCurrentPassword] = useState('')
    const [newPassword, setNewPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [currentPasswordError, setCurrentPasswordError] = useState('')
    const [newPasswordError, setNewPasswordError] = useState('')
    const [confirmPasswordError, setConfirmPasswordError] = useState('')

    const [showPassword, setShowPassword] = useState(false)
    const [loading, setLoading] = useState(false)

    const handleCurrentPassword = (value) => {
        setCurrentPassword(value)
        setCurrentPasswordError('')
    }

    const handleNewPassword = (value) => {
        setNewPassword(value)
        setNewPasswordError('')
    }

    const handleConfirmPassword = (value) => {
        setConfirmPassword(value)
        setConfirmPasswordError('')
    }

    const handleShowPassword = (e) => {
        e.preventDefault()
        setShowPassword(!showPassword)
    }

    const setValueEmpty = () => {
        setCurrentPassword('')
        setNewPassword('')
        setConfirmPassword('')
    }

    const setErrorEmpty = () => {
        setCurrentPasswordError('')
        setNewPasswordError('')
        setConfirmPasswordError('')
    }

    const changePassword = (e) => {
        e.preventDefault()
        let error = false

        const data = {
            currentPassword: String(currentPassword),
            newPassword: String(newPassword),
            confirmPassword: String(confirmPassword),
        }

        if (data.currentPassword === '') {
            setCurrentPasswordError('Current password required')
            error = true
        }

        if (data.newPassword === '') {
            setNewPasswordError('New password required')
            error = true
        } else if (data.newPassword.length < 6) {
            setNewPasswordError('Password must be at least 6 characters')
            error = true
        }

        if (data.confirmPassword === '') {
            setConfirmPasswordError('Confirm password required')
            error = true
        } else if (data.newPassword !== data.confirmPassword) {
            setConfirmPasswordError('Passwords do not match')
            error = true
        }

        if (!error) {
            setErrorEmpty()
            setLoading(true)
            api.put(apiUrl, data, reqConfig).then(response => {
                if (response.status === 200) {
                    setValueEmpty()
                    setErrorEmpty()
                    toast({ type: SUCCESS, message: response.data.message })
                }
            }).catch(error => {
                if (error.response) {
                    if (error.response.status === 403) {
                        setCurrentPasswordError(error.response.data.message)
                    } else if (error.response.status === 409) {
                        setNewPasswordError(error.response.data.message)
                    } else toast({ type: ERROR, message: error.response.data.message })
                } else toast({ type: ERROR, message: error.message })
            }).finally(() => setLoading(false))
        }
    }

    return <div className="container my-3">
        <div className="d-flex align-items-center">
            <h6 className=" m-0">Change Password</h6>
            <Link to="#" onClick={e => handleShowPassword(e)} className="ms-3 small" style={{ lineHeight: '100%' }}>{showPassword ? 'Hide Passwords' : 'Show Passwords'}</Link>
        </div>
        <div className="mt-4">
            <form >
                <div className="row">
                    <div className="col-12 col-md-8 col-lg-6 col-xl-4">
                        <input type={showPassword ? 'text' : 'password'} placeholder="Type Current Password" className={`form-control form-control-lg ${currentPasswordError !== '' ? 'border-danger' : ''}`} onChange={e => handleCurrentPassword(e.target.value)} value={currentPassword} />
                        <span className="text-danger small fw-bold">{currentPasswordError}</span>
                        <br />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-md-8 col-lg-6 col-xl-4">
                        <input type={showPassword ? 'text' : 'password'} placeholder="Type New Password" className={`form-control form-control-lg ${newPasswordError !== '' ? 'border-danger' : ''}`} onChange={e => handleNewPassword(e.target.value)} value={newPassword} />
                        <span className="text-danger small fw-bold">{newPasswordError}</span>
                        <br />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-md-8 col-lg-6 col-xl-4">
                        <input type={showPassword ? 'text' : 'password'} placeholder="Retype New Password" className={`form-control form-control-lg ${confirmPasswordError !== '' ? 'border-danger' : ''}`} onChange={e => handleConfirmPassword(e.target.value)} value={confirmPassword} />
                        <span className="text-danger small fw-bold">{confirmPasswordError}</span>
                        <br />
                    </div>
                </div>
                {loading ?
                    <button className='btn btn-primary btn-lg d-flex align-items-center' onClick={e => e.preventDefault()}>
                        <Spinner spinnerSize="sm" color="light" />
                        <span className="ms-3">Change Password</span>
                    </button> :
                    <button type="submit" className='btn btn-primary btn-lg' onClick={e => changePassword(e)}>Change Password</button>}
            </form>
        </div>
    </div>
}

export default ChangePassword