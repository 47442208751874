import React from "react";
import { Link } from "react-router-dom";

const AdminTopNavBar = ({ toggleSidebar, isSidebarMinimized, homeLink }) => {
    return (
        <nav
            className="main-header navbar navbar-expand navbar-blue navbar-light"
            style={{
                position: "fixed",         // Fixed position
                top: 0,                    // Positioned at the top
                width: "100%",             // Full width of the page
                marginLeft: isSidebarMinimized ? "80px" : "250px",
                height: "75px",
                zIndex: 1030,
                backgroundColor: "white",
            }}
        >
            <ul className="navbar-nav">
                <li className="nav-item">
                    <Link className="nav-link" to="#" role="button" onClick={toggleSidebar}>
                        <i className="fas fa-bars text-dark fw-bold" style={{ fontSize: "20px" }}></i>
                    </Link>
                </li>
            </ul>
            <h4 className="mt-1 d-flex align-items-center justify-content-center h-100 text-truncate">
                {/* <Link to={homeLink} className="text-dark fw-bold text-uppercase">
          My Shop!
        </Link> */}
                <Link
                    to="/"
                    className="text-white fw-bold text-uppercase"
                >
                    <img
                        src={process.env.PUBLIC_URL + "/img/appname.png"}
                        height="30px"
                        width="150px"
                        alt="appname"
                    />
                </Link>
            </h4>
        </nav>
    );
};

export default AdminTopNavBar;
