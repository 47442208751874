import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import Papa from "papaparse";
import { useEffect, useState } from "react";
import { adminReqConfig, api } from "../../include/api";
import { getAdmin } from '../../include/function';
import "../../styles/Super.css";
import AdminHeader from "./Header";

const ShopOrders = ({ from }) => {
    const user = getAdmin();
    const [fetchLoading, setFetchLoading] = useState(true);
    const [orderData, setOrderData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [fromDate, setFromDate] = useState("");
    const [toDate, setToDate] = useState("");
    const [searchQuery, setSearchQuery] = useState("");

    const getOrders = (afterGet) => {
        setFetchLoading(true);
        api.get(`/order_for_super/?shopId=${user.id}`, adminReqConfig()).then(response => {
            if (response.status === 200) {
                setOrderData(response.data.data);
                setFilteredData(response.data.data);
                if (afterGet) afterGet(response.data.data);
            }
        }).catch(error => {
            setOrderData([]);
            setFilteredData([]);
            if (afterGet) afterGet([]);
        }).finally(() => setFetchLoading(false));
    };

    useEffect(() => {
        getOrders();
    }, []);

    // Handle Date Filter
    const handleFilter = () => {
        const from = new Date(fromDate);
        const to = new Date(toDate);

        const filtered = orderData.filter((data) => {
            const orderDate = new Date(data.date);
            return orderDate >= from && orderDate <= to;
        });

        setFilteredData(filtered);
    };

    // Handle Search Filter
    const handleSearch = (query) => {
        setSearchQuery(query);

        const filtered = orderData.filter((data) => {
            return (
                data.orderId?.toString().toLowerCase().includes(query?.toLowerCase()) ||
                data.name?.toLowerCase().includes(query?.toLowerCase()) ||
                data.paymentMode?.toLowerCase().includes(query?.toLowerCase())
            );
        });

        setFilteredData(filtered);
    };

    // PDF Download
    const downloadPDF = () => {
        const doc = new jsPDF();
        const title = "Order Report";

        doc.setFontSize(18);

        const pageWidth = doc.internal.pageSize.getWidth();
        const titleWidth = doc.getTextWidth(title);
        const titleX = (pageWidth - titleWidth) / 2;

        doc.text(title, titleX, 10);
        autoTable(doc, {
            head: [['S.No', 'Order Id', 'Order Date', 'Payment Method', 'Status', 'Total Amount']],
            body: filteredData.map((od, i) => [
                i + 1,
                od.orderId,
                od.date,
                od.paymentMode,
                od.status,
                od.totalAmount
            ])
        });
        doc.save('orders.pdf');
    };

    // CSV Download
    const downloadCSV = () => {
        const csvData = filteredData.map((od, i) => ({
            'S.No': i + 1,
            'Order Id': od.orderId,
            'Order Date': od.date,
            'Payment Method': od.paymentMode,
            'Status': od.status,
            'Total Amount': od.totalAmount
        }));

        const csv = Papa.unparse(csvData);
        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'orders.csv');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <>
            <AdminHeader>
                <div className="fixed-top-bar">
                    <div className="card">
                        <h5 className="m-2">Orders</h5>
                        <div className="card-body">
                            {/* Date Filters */}
                            <div className="filter-section shadow p-2 rounded">
                                <div className="row d-flex">
                                    <div className="date-filter col-lg-4 col-sm-6 col-xl-4">
                                        <label>From Date:</label>
                                        <input
                                            className="form-control"
                                            type="date"
                                            value={fromDate}
                                            onChange={(e) => setFromDate(e.target.value)}
                                        />
                                    </div>
                                    <div className="date-filter col-lg-4 col-sm-6 col-xl-4">
                                        <label>To Date:</label>
                                        <input
                                            className="form-control"
                                            type="date"
                                            value={toDate}
                                            onChange={(e) => setToDate(e.target.value)}
                                        />
                                    </div>
                                    <div className="mt-2 col-lg-4 col-sm-6 col-xl-4">
                                        <button
                                            className="mx-2 my-3 btn btn-primary"
                                            onClick={handleFilter}
                                        >
                                            Apply Filter
                                        </button>
                                    </div>
                                </div>
                            </div>
                            {/* Search Filter */}
                            <div className="card my-4 row">
                                <div className="row ">
                                    <div className="search-section m-3 col-8">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Search by Order ID, Customer Name, or Payment Method"
                                            value={searchQuery}
                                            onChange={(e) => handleSearch(e.target.value)}
                                        />
                                    </div>
                                    <div className="col m-3 ">
                                        {from === 'reports' && (
                                            <>
                                                <button
                                                    className="btn btn-success mx-2"
                                                    onClick={downloadPDF}
                                                    style={{ fontSize: "14px" }}
                                                >
                                                    <i className="fa-solid fa-download"></i> PDF
                                                </button>
                                                <button
                                                    className="btn btn-success mx-2"
                                                    onClick={downloadCSV}
                                                    style={{ fontSize: "14px" }}
                                                >
                                                    <i className="fa-solid fa-download"></i> CSV
                                                </button>
                                            </>
                                        )}

                                    </div>
                                </div>

                                {/* Loader */}
                                {fetchLoading ? (
                                    <div className="text-center">
                                        <div className="spinner-border" role="status">
                                            <span className="visually-hidden">Loading...</span>
                                        </div>
                                    </div>
                                ) : (
                                    /* Orders Table */
                                    <div className="table-wrapper">
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th>S.No</th>
                                                    <th>Order Id</th>
                                                    <th>Order Date</th>
                                                    <th>Payment Method</th>
                                                    <th>Status</th>
                                                    <th>Total Amount</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {filteredData.map((od, i) => (
                                                    <tr key={od.orderId}>
                                                        <td>{i + 1}</td>
                                                        <td>{od.orderId}</td>
                                                        <td>{od.date}</td>
                                                        <td>{od.paymentMode}</td>
                                                        <td>{od.status}</td>
                                                        <td>{od.totalAmount}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </AdminHeader>
        </>
    );
};

export default ShopOrders;
