import React, { createContext, useContext, useReducer } from "react"
import { v4 } from "uuid"
import Toast from "./Toast"

const NotificationContext = createContext()

const ToastProvider = (props) => {
    const [state, dispatch] = useReducer((state, action) => {
        switch (action.type) {
            case "ADD_NOTIFICATION":
                return [{ ...action.payload }, ...state].slice(0, 5)
            case "REMOVE_NOTIFICATION":
                return state.filter(el => el.id !== action.id)
            default:
                return state
        }
    }, [])

    return <NotificationContext.Provider value={dispatch}>
        <div className={"notification-wrapper"}>
            {state.map((note) => {
                return <Toast dispatch={dispatch} key={note.id} {...note} />
            })}
        </div>
        {props.children}
    </NotificationContext.Provider>
}

export const useNotification = () => {
    const dispatch = useContext(NotificationContext)

    return (props) => {
        dispatch({
            type: "ADD_NOTIFICATION",
            payload: {
                id: v4(),
                ...props
            }
        })
    }
}

export default ToastProvider