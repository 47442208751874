import React, { useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { api } from "../../include/api";
import { ERROR, SUCCESS } from "../../include/constant";
import { preventClick, validateEmail, validatePhoneNumber } from "../../include/function";
import { useNotification } from "../../Toast/ToastProvider";

const CustomerType = ({ index, name, onSelect, isSelected }) => (
    <label
        className={`form-control form-control-lg cursor-pointer text-truncate ${isSelected ? "bg-primary text-white" : ""
            }`}
        htmlFor={"customerType" + index}
    >
        <span>{name}</span>
        <input
            type="radio"
            name="customerType"
            id={"customerType" + index}
            className="d-none"
            onChange={onSelect}
            checked={isSelected}
        />
    </label>
);

const OTPInput = ({ onSubmit, resendOTP, sentOTP }) => {
    // const [otp, setOtp] = useState(new Array(6).fill(""));
    const [otp, setOtp] = useState(sentOTP.toString().split());
    const [error, setError] = useState("");
    const inputRefs = useRef([]);

    const handleChange = (e, index) => {
        const value = e.target.value;
        if (/^[0-9]$/.test(value)) {
            const newOtp = [...otp];
            newOtp[index] = value;
            setOtp(newOtp);
            setError("");

            if (index < 5 && value) {
                inputRefs.current[index + 1].focus();
            }
        } else if (value === "") {
            const newOtp = [...otp];
            newOtp[index] = "";
            setOtp(newOtp);
        }
    };

    const handleKeyDown = (e, index) => {
        if (e.key === "Backspace" && otp[index] === "") {
            if (index > 0) {
                inputRefs.current[index - 1].focus();
            }
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const enteredOTP = otp.join("");
        // if (enteredOTP.length < 6) {
        //     setError("Please enter a 6-digit OTP.");
        //     return;
        // }
        onSubmit(enteredOTP);
    };

    return (
        <div className="d-flex flex-column align-items-center">
            <h4>Enter OTP</h4>
            <p>We've sent a 6-digit OTP to your phone number.</p>
            <form onSubmit={handleSubmit} className="d-flex justify-content-center mb-3">
                {/* {otp.map((value, index) => (
                    <input
                        key={index}
                        ref={(el) => (inputRefs.current[index] = el)}
                        type="text"
                        className="form-control mx-1 text-center"
                        maxLength="1"
                        value={otp[index]}
                        onChange={(e) => handleChange(e, index)}
                        onKeyDown={(e) => handleKeyDown(e, index)}
                        style={{ width: "50px", height: "50px", fontSize: "24px" }}
                        autoFocus={index === 0}
                    />
                ))} */}
                <input
                    type="text"
                    placeholder=""
                    className={`border border-1 rounded w-100 p-2 form-control`}
                    value={sentOTP}
                    autoComplete="off"
                />

                <button type="submit" className="btn btn-primary ms-3">
                    Verify
                </button>
            </form>
            {/* {error && <div className="text-danger mb-2">{error}</div>} */}
            <button className="btn btn-link" onClick={resendOTP}>
                Resend OTP
            </button>
        </div>
    );
};

const CustomerSignup = ({ handleCustomer }) => {
    const toast = useNotification();
    const navigate = useNavigate();

    const imageElement = useRef();

    const [step, setStep] = useState("signup");

    const [password, setPassword] = useState('');
    const [name, setName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [emailId, setEmailId] = useState('');
    const [customerType, setCustomerType] = useState('individual');
    const [proofName, setProofName] = useState('');
    const [gstNo, setGstNo] = useState('');
    const [proofAttachment, setProofAttachment] = useState('');

    const [passwordError, setPasswordError] = useState('');
    const [nameError, setNameError] = useState('');
    const [phoneNumberError, setPhoneNumberError] = useState('');
    const [emailIdError, setEmailIdError] = useState('');
    const [customerTypeError, setCustomerTypeError] = useState('');
    const [proofNameError, setProofNameError] = useState('');
    const [gstNoError, setGstNoError] = useState('');
    const [proofAttachmentError, setProofAttachmentError] = useState('');

    const [showPassword, setShowPassword] = useState(false);
    const [saving, setSaving] = useState(false);
    const [otpSent, setOtpSent] = useState(false);

    const [sentOTP, setSentOTP] = useState("");

    const handlePasswordChange = (value) => {
        setPasswordError('');
        setPassword(value);
    };

    const handleNameChange = (value) => {
        setNameError('');
        setName(value);
    };

    const handlePhoneNumberChange = (value) => {
        setPhoneNumberError('');
        setPhoneNumber(value);
    };

    const handleEmailIdChange = (value) => {
        setEmailIdError('');
        setEmailId(value);
    };

    const handleCustomerTypeChange = (value) => {
        setCustomerTypeError('');
        setCustomerType(value);
    };

    const handleProofNameChange = (value) => {
        setProofNameError('');
        setProofName(value);
    };

    const handleGSTNoChange = (value) => {
        setGstNoError('');
        setGstNo(value);
    };

    const handleImage = (image) => {
        setProofAttachmentError('');

        if (image.length > 0) {
            const selectedImage = image[0];

            if (
                selectedImage.type === "image/jpeg" ||
                selectedImage.type === "image/jpg" ||
                selectedImage.type === "image/png" ||
                selectedImage.type === "image/webp"
            ) {
                if (selectedImage.size / 1024 / 1024 <= 3) {
                    const reader = new FileReader();

                    reader.onloadend = (e) => {
                        setProofAttachment(selectedImage);
                    };

                    reader.readAsDataURL(selectedImage);
                } else {
                    setProofAttachmentError('File is too large to upload');
                }
            } else {
                setProofAttachmentError('Unsupported file format (jpeg, jpg, png, and webp are supported)');
            }
        } else {
            setProofAttachmentError('Please choose an image');
        }


        // imageElement.current.value = '';
    };

    const handleShowPassword = () => setShowPassword(!showPassword);

    const handleSignup = () => {
        let error = false;
        const data = {
            userId: String(emailId).trim().toLowerCase(),
            password: String(password),
            name: String(name).trim(),
            phoneNumber: String(phoneNumber).trim(),
            emailId: String(emailId).trim().toLowerCase(),
            customerType: String(customerType).trim(),
        };

        if (data.password === '') {
            setPasswordError('Password required');
            error = true;
        } else if (data.password.length < 6) {
            setPasswordError('Password must be at least 6 characters');
            error = true;
        }

        if (data.name === '') {
            setNameError('Name required');
            error = true;
        }

        if (data.phoneNumber === '') {
            setPhoneNumberError('Phone number required');
            error = true;
        } else if (!validatePhoneNumber(data.phoneNumber)) {
            setPhoneNumberError('Invalid phone number');
            error = true;
        }

        if (data.emailId !== '' && !validateEmail(data.emailId)) {
            setEmailIdError('Invalid email address');
            error = true;
        }

        if (!error) {
            setSaving(true);
            api.post('/otp/', { phoneNumber: data.phoneNumber })
                .then(response => {
                    if (response.status === 200) {
                        console.log(response.data.data.otp);
                        setSentOTP(response.data.data.otp)
                        setOtpSent(true);
                        setStep("otp");
                        toast({ type: SUCCESS, message: "OTP sent to your phone number." });
                    }
                })
                .catch(error => {
                    error.response ?
                        toast({ type: ERROR, message: error.response.data.message }) :
                        toast({ type: ERROR, message: error.message });
                })
                .finally(() => setSaving(false));
        }
    };

    const handleVerifyOTP = (enteredOTP) => {
        setSaving(true);
        api.put('/otp/', { phoneNumber, otp: enteredOTP })
            .then(response => {
                if (response.status === 200) {
                    setStep("customerType");
                    toast({ type: SUCCESS, message: "OTP verified successfully." });
                }
            })
            .catch(error => {
                error.response ?
                    toast({ type: ERROR, message: error.response.data.message }) :
                    toast({ type: ERROR, message: error.message });
            })
            .finally(() => setSaving(false));
    };

    const handleResendOTP = () => {
        setSaving(true);
        api.post('/otp/', { phoneNumber })
            .then(response => {
                if (response.status === 200) {
                    toast({ type: SUCCESS, message: "OTP resent to your phone number." });
                }
            })
            .catch(error => {
                error.response ?
                    toast({ type: ERROR, message: error.response.data.message }) :
                    toast({ type: ERROR, message: error.message });
            })
            .finally(() => setSaving(false));
    };

    const handleCustomerTypeSelection = (type) => {
        setCustomerType(type);
    };

    const handleFinalSignup = () => {
        let error = false;

        if (customerType === '') {
            setCustomerTypeError('Customer type is required');
            error = true;
        }

        if (customerType !== 'individual' && proofName === '') {
            setProofNameError('Proof Name required');
            error = true;
        }

        if (customerType !== 'individual' && gstNo === '') {
            setGstNoError('GST Number required');
            error = true;
        }

        if (customerType !== 'individual' && !proofAttachment) {
            setProofAttachmentError('Proof Attachment required');
            error = true;
        }

        if (!error) {
            setSaving(true);

            // Use FormData to handle file upload
            const formData = new FormData();
            formData.append('userId', String(emailId).trim().toLowerCase());
            formData.append('password', String(password));
            formData.append('name', String(name).trim());
            formData.append('phoneNumber', String(phoneNumber).trim());
            formData.append('emailId', String(emailId).trim().toLowerCase());
            formData.append('customerType', String(customerType).trim());
            formData.append('proofName', String(proofName).trim().toLowerCase());
            formData.append('gstNo', String(gstNo).trim().toLowerCase());

            // Append the file
            formData.append('proofAttachment', proofAttachment);

            api.post('/signup/', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data', // Required for file uploads
                }
            })
                .then(response => {
                    if (response.status === 200) {
                        let customer = response.data.data.user;
                        let token = response.data.data.token;
                        handleCustomer(customer, token);
                        toast({ type: SUCCESS, message: response.data.message });
                        navigate("/");
                    }
                })
                .catch(error => {
                    error.response ?
                        toast({ type: ERROR, message: error.response.data.message }) :
                        toast({ type: ERROR, message: error.message });
                })
                .finally(() => setSaving(false));
        }
    };

    const customerTypes = [
        "individual",
        "engineer",
        "contractor",
        "sub-dealer",
        "builder",
        "promoter",
    ];

    return (
        <div className="vh-100 d-flex justify-content-center align-items-center bg-primary">
            <div className="card p-4 shadow" style={{ maxWidth: '500px', width: '100%' }}>
                <div className="container">
                    <h2 className="my-0">Sign Up</h2>
                    <p className="text-50">Create an account to continue</p>

                    {step === "signup" && (
                        <form className="mt-5 mb-4" onSubmit={(e) => e.preventDefault()}>
                            <div className="mb-4">
                                <div>
                                    <label htmlFor="emailId">Email Id</label>
                                    <input
                                        type="text"
                                        placeholder="Enter Email Id"
                                        className={`border border-1 rounded w-100 p-2 form-control ${emailIdError !== '' ? 'border-danger' : ''}`}
                                        id="emailId"
                                        onChange={e => handleEmailIdChange(e.target.value)}
                                        value={emailId}
                                        autoComplete="off"
                                    />
                                    <div className="fw-bold text-danger text-capitalize small" style={{ height: '10px' }}>{emailIdError}</div>
                                </div>
                                <div className="mt-3">
                                    <div className="d-flex align-items-center justify-content-between">
                                        <label htmlFor="password">Password</label>
                                        <Link to="#" className="pe-3" onClick={e => preventClick(e, handleShowPassword)} tabIndex="-1">
                                            {showPassword ? 'Hide' : 'Show'} Password <i className={`fas fa-eye${showPassword ? '-slash' : ''}`} />
                                        </Link>
                                    </div>
                                    <input
                                        type={showPassword ? "text" : "password"}
                                        placeholder="Enter Password"
                                        className={`border border-1 rounded w-100 p-2 form-control ${passwordError !== '' ? 'border-danger' : ''}`}
                                        id="password"
                                        onChange={e => handlePasswordChange(e.target.value)}
                                        value={password}
                                    />
                                    <div className="fw-bold text-danger text-capitalize small" style={{ height: '10px' }}>{passwordError}</div>
                                </div>
                                <div className="mt-3">
                                    <label htmlFor="name">Name</label>
                                    <input
                                        type="text"
                                        placeholder="Enter Name"
                                        className={`border border-1 rounded w-100 p-2 form-control ${nameError !== '' ? 'border-danger' : ''}`}
                                        id="name"
                                        onChange={e => handleNameChange(e.target.value)}
                                        value={name}
                                        autoComplete="off"
                                    />
                                    <div className="fw-bold text-danger text-capitalize small" style={{ height: '10px' }}>{nameError}</div>
                                </div>
                                <div className="mt-3">
                                    <label htmlFor="phoneNumber">Phone Number</label>
                                    <input
                                        type="tel"
                                        placeholder="Enter Phone Number"
                                        className={`border border-1 rounded w-100 p-2 form-control ${phoneNumberError !== '' ? 'border-danger' : ''}`}
                                        id="phoneNumber"
                                        onChange={e => handlePhoneNumberChange(e.target.value)}
                                        value={phoneNumber}
                                        autoComplete="off"
                                    />
                                    <div className="fw-bold text-danger text-capitalize small" style={{ height: '10px' }}>{phoneNumberError}</div>
                                </div>
                            </div>
                            <button type="button" className="btn btn-primary btn-lg btn-block font-decoration-none text-uppercase" onClick={handleSignup}>SEND OTP</button>
                            <p className="text-center mt-3">
                                Already have an account? <Link to="/login" className="text-primary">Log In</Link>
                            </p>
                        </form>
                    )}

                    {step === "otp" && (
                        <OTPInput onSubmit={handleVerifyOTP} resendOTP={handleResendOTP} sentOTP={sentOTP} />
                    )}

                    {step === "customerType" && (
                        <div className="mt-4">
                            <h4>Select Customer Type</h4>
                            {customerTypeError && <div className="text-danger mb-2">{customerTypeError}</div>}
                            <div className="row">
                                {customerTypes.map((type, index) => (
                                    <div className="col-4 mt-3 text-center text-capitalize">
                                        <CustomerType
                                            key={index}
                                            index={index}
                                            name={type}
                                            onSelect={() => handleCustomerTypeSelection(type)}
                                            isSelected={customerType === type}
                                        />
                                    </div>
                                ))}
                            </div>
                            {customerType !== "individual" && (
                                <div className="row mt-3">
                                    <hr className="m-1" style={{ border: "1px solid black" }} />
                                    {/* Proof Name Input */}
                                    <div className="mb-2">
                                        <label htmlFor="proofName">Proof Name</label>
                                        <input
                                            type="text"
                                            placeholder="Enter Proof Name"
                                            className={`border border-1 rounded w-100 p-2 form-control ${proofNameError !== '' ? 'border-danger' : ''}`}
                                            id="proofName"
                                            onChange={e => handleProofNameChange(e.target.value)}
                                            value={proofName}
                                        />
                                        <div className="fw-bold text-danger text-capitalize small">{proofNameError}</div>
                                    </div>

                                    {/* GST Number Input */}
                                    <div className="mb-2">
                                        <label htmlFor="gstNo">GST Number</label>
                                        <input
                                            type="text"
                                            placeholder="Enter GST Number"
                                            className={`border border-1 rounded w-100 p-2 form-control ${gstNoError !== '' ? 'border-danger' : ''}`}
                                            id="gstNo"
                                            onChange={e => handleGSTNoChange(e.target.value)}
                                            value={gstNo}
                                        />
                                        <div className="fw-bold text-danger text-capitalize small">{gstNoError}</div>
                                    </div>

                                    {/* Proof Attachment Input */}
                                    <div className="mb-2">
                                        <label htmlFor="proofAttachment">Proof Attachment</label>
                                        <input
                                            type="file"
                                            accept=".jpg,.jpeg,.png,.webp"
                                            className={`border border-1 rounded w-100 p-2 form-control ${proofAttachmentError !== '' ? 'border-danger' : ''}`}
                                            id="proofAttachment"
                                            ref={imageElement}
                                            onChange={e => handleImage(e.target.files)}
                                        />
                                        <div className="fw-bold text-danger text-capitalize small">{proofAttachmentError}</div>
                                    </div>


                                </div>
                            )}
                            <button type="button" className="btn btn-primary btn-lg btn-block font-decoration-none text-uppercase mt-3" onClick={handleFinalSignup}>SIGN UP</button>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default CustomerSignup;
