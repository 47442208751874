import { nanoid } from "nanoid"
import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { api, customerReqConfig } from "../../include/api"
import { ADD, DELETE, EDIT, ERROR, SUCCESS } from "../../include/constant"
import { getCustomer, preventClick } from "../../include/function"
import { useNotification } from "../../Toast/ToastProvider"
import Error from "../add-ons/Error"
import Spinner from "../add-ons/Spinner"
import YesOrNo from "../add-ons/YesOrNo"
import AddressForm from "../common/AddressForm"

const CustomerAddress = () => {
    const user = getCustomer()
    const toast = useNotification()

    const [showYesOrNo, setShowYesOrNo] = useState(false)
    const [fetchLoading, setFetchLoading] = useState(true)
    const [deleteloading, setDeleteLoading] = useState(false)
    const [addressList, setAddressList] = useState([])
    const [type, setType] = useState('')
    const [id, setId] = useState('')

    useEffect(() => {
        if (user.id && user.id !== '') {
            getAddress(user.id)
        }
    }, [user.id])

    const getAddress = (id) => {
        setFetchLoading(true)
        api.get('/customer_address/?customerId=' + id, customerReqConfig()).then(response => {
            if (response.status === 200) {
                setAddressList(response.data.data)
            }
        }).catch(error => { }).finally(() => setFetchLoading(false))
    }

    const handleYes = () => {
        setDeleteLoading(true)
        api.delete('/customer_address/?id=' + id, customerReqConfig()).then(response => {
            if (response.status === 200) {
                setAddressList(addressList.filter(address => String(address.id) !== String(id)))
                toast({ type: SUCCESS, message: response.data.message })
                setShowYesOrNo(false)
            }
        }).catch(error => {
            error.response ?
                toast({ type: ERROR, message: error.response.data.message }) :
                toast({ type: ERROR, message: error.message })
        }).finally(() => setDeleteLoading(false))
    }

    const handleNo = () => {
        setShowYesOrNo(false)
        setId('')
        setType('')
    }

    const handleType = ([type, id]) => {
        setType(type)
        if (type === EDIT || type === DELETE) setId(id)
        if (type === DELETE) setShowYesOrNo(true)
    }

    const onSave = (data) => {
        if (type === ADD) {
            setAddressList(prev => [...prev, data])
        } else if (type === EDIT) {
            setAddressList(prev => {
                prev.slice()
                const index = prev.findIndex(a => a.id === id)
                prev[index] = data
                return prev
            })
        }
        setType('')
        setId('')
    }

    const onCancel = () => {
        setType('')
        setId('')
    }

    return <>
        {showYesOrNo && <YesOrNo zIndex={1040} headerText='Are you sure want to delete this address?' yesBtnText="Yes, Delete" handleYes={handleYes} handleNo={handleNo} loading={deleteloading} />}
        <div className="container my-3">
            <h5 className="mb-3">Address</h5>
            {type === ADD ? <AddressForm
                reqConfig={customerReqConfig()}
                type={type}
                userId={user.id}
                title="Add address"
                onSave={onSave}
                onCancel={onCancel}
            /> : <div className="py-3">
                <Link to="#" className="w-100 d-flex align-items-center small text-uppercase" onClick={e => preventClick(e, handleType, ADD)}>
                    <i className="fa fa-plus me-3" />
                    <span>Add New Address</span>
                </Link>
            </div>}
            {fetchLoading ?
                <div className="py-5"><Spinner color="danger" /></div> :
                addressList.length > 0 ? addressList.map(address => {
                    return (type === EDIT && address.id === id) ? <AddressForm
                        id={id}
                        onSave={onSave}
                        reqConfig={customerReqConfig()}
                        title="Edit Address"
                        type={type}
                        userId={user.id}
                        editAddress={address}
                        onCancel={onCancel}
                        key={nanoid()}
                    /> : <Address
                        key={nanoid()}
                        address={address}
                        handleType={handleType}
                    />
                }) : <Error mainText="Sorry, no address found!" secondaryText={<Link className="small hover-underline" to="#" onClick={e => preventClick(e, handleType, ADD)}>Add new address</Link>} />}
        </div>
    </>
}

const Address = ({ address, handleType }) => <div className="py-3 border-top">
    <span className="border d-inline-block px-2 text-uppercase small bg-light mb-2">{address.addressType}</span>
    <h6 className="d-flex align-items-center">
        <span className="small">{address.name}</span>
        <i className="fa fa-circle mx-2" style={{ fontSize: '.3rem' }} />
        <span className="small">{address.phoneNumber}</span>
        {address.alternatePhoneNumber && <>
            <i className="fa fa-circle mx-2" style={{ fontSize: '.3rem' }} />
            <span className="small text-truncate">{address.alternatePhoneNumber}</span>
        </>}
    </h6>
    <p className="m-0 mt-1 small">{address.address}, {address.locality}, {address.city}, {address.state} - {address.pin}</p>
    <Link to="#" className="text-primary mt-3 me-3 small fw-bold" onClick={e => preventClick(e, handleType, EDIT, address.id)}>Edit</Link>
    <Link to="#" className="text-primary mt-3 small fw-bold" onClick={e => preventClick(e, handleType, DELETE, address.id)}>Delete</Link>
</div>

export default CustomerAddress