import React, { useState } from "react"
import CustomerBody from "./Body"
import CustomerCategoryBar from "./CategoryBar"
import CustomerGroupSlides from "./CustomerGroupSlides"
import CustomerSecondNav from "./CutomerSecondNav"
import CustomerFooter from "./Footer"
import CustomerHeader from "./Header"
import CustomerSlide from "./Slide"

const CustomerHome = () => {

    const [section, setSection] = useState('')
    console.log(section);

    return <div className="fixed-top-bar">
        <CustomerHeader enableButtons enableSearch />
        <CustomerSecondNav setSection={setSection} />
        <CustomerSlide />
        <CustomerCategoryBar />
        <CustomerGroupSlides />
        {/* <CustomerBanner /> */}
        <CustomerBody section={section} />
        <CustomerFooter />
    </div>
}

export default CustomerHome