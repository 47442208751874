import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import Papa from "papaparse";
import { useEffect, useState } from "react";
import { api, superAdminReqConfig } from "../../include/api";
import "../../styles/Super.css";
import SuperAdminHeader from "./Header";


const ProductReport = () => {
    const [fetchLoading, setFetchLoading] = useState(true);
    const [productData, setProductData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");

    const getOrders = (afterGet) => {
        setFetchLoading(true);
        api.get('/product_stock_details/', superAdminReqConfig()).then(response => {
            if (response.status === 200) {
                setProductData(response.data.data);
                setFilteredData(response.data.data);
                if (afterGet) afterGet(response.data.data);
            }
        }).catch(error => {
            setProductData([]);
            setFilteredData([]);
            if (afterGet) afterGet([]);
        }).finally(() => setFetchLoading(false));
    };

    useEffect(() => {
        getOrders();
    }, []);

    // Handle Search Filter
    const handleSearch = (query) => {
        setSearchQuery(query);

        const filtered = productData.filter((data) => {
            return (
                data.productId?.toString().toLowerCase().includes(query?.toLowerCase()) ||
                data.productName?.toLowerCase().includes(query?.toLowerCase())
            );
        });

        setFilteredData(filtered);
    };

    // PDF Download
    const downloadPDF = () => {
        const doc = new jsPDF();
        const title = "Products Report";

        doc.setFontSize(18);

        const pageWidth = doc.internal.pageSize.getWidth();
        const titleWidth = doc.getTextWidth(title);
        const titleX = (pageWidth - titleWidth) / 2;

        doc.text(title, titleX, 10);
        autoTable(doc, {
            head: [['S.No', 'Product Id', 'Product Name', 'Category']],
            body: filteredData.map((product, i) => [
                i + 1,
                product.productId,
                product.productName,
                product.categoryNames,
            ])
        });
        doc.save('Product_stock.pdf');
    };

    // CSV Download
    const downloadCSV = () => {
        const csvData = filteredData.map((product, i) => ({
            'S.No': i + 1,
            'Product Id': product.productId,
            'Product Name': product.productName,
            'Category': product.categoryNames,
        }));

        const csv = Papa.unparse(csvData);
        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'Product_stock.csv');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };


    return (
        <SuperAdminHeader>
            <div className="fixed-top-bar">
                <div className="card">
                    <h5 className="m-2">Product Report</h5>
                    <div className="card-body">
                        <div className="row ">
                            <div className="search-section m-3 col-8">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Search by Product ID or Product Name"
                                    value={searchQuery}
                                    onChange={(e) => handleSearch(e.target.value)}
                                />
                            </div>
                            <div className="col m-3">
                                <button
                                    className="btn btn-success mx-2"
                                    onClick={downloadPDF}
                                    style={{ fontSize: "14px" }}
                                >
                                    <i class="fa-solid fa-download"></i> PDF
                                </button>
                                <button
                                    className="btn btn-success mx-2"
                                    onClick={downloadCSV}
                                    style={{ fontSize: "14px" }}
                                >
                                    <i class="fa-solid fa-download"></i> CSV
                                </button>
                            </div>
                        </div>

                        {/* Products Report Table */}
                        <div className="table-wrapper">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>S.No</th>
                                        <th>Product ID</th>
                                        <th>Product Name</th>
                                        <th>Category</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {filteredData.map((product, index) => (
                                        <tr key={product.productId}>
                                            <td>{index + 1}</td>
                                            <td>{product.productId}</td>
                                            <td>{product.productName}</td>
                                            <td>{product.categoryNames}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </SuperAdminHeader>
    );
};

export default ProductReport;
