import { nanoid } from "nanoid"
import React, { useCallback, useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { api, baseURL, customerReqConfig } from "../../include/api"
import { CANCELLED, CANCEL_REQUEST, DELIVERED, ERROR, ORDERED, PACKED, RETURN_APPROVED, RETURN_RECEIVED, RETURN_REQUEST, RETURN_SHIPPED, SHIPPED, SUCCESS } from "../../include/constant"
import { formateDate, getCustomer, isEmptyObj, isNum, preventClick } from "../../include/function"
import "../../styles/ProgressBar.css"
import { useNotification } from "../../Toast/ToastProvider"
import Modal from "../add-ons/Modal"
import Spinner from "../add-ons/Spinner"
import CustomerHeader from "./Header"

const CustomerServiceOrderDetails = () => {
    const { id } = useParams()
    const user = getCustomer()
    const { push } = useNavigate()

    const [showModal, setShowModal] = useState(false)
    const [fetchLoading, setFetchLoading] = useState(true)
    const [modalComponent, setModalComponent] = useState('')
    const [modalHeaderText, setMoadlHeaderText] = useState('')
    const [apiConfig, setApiConfig] = useState({})
    const [orderItem, setOrderItem] = useState({})

    const getApiConfig = () => {
        api.get('/config/', customerReqConfig()).then(response => {
            if (response.status === 200) {
                setApiConfig(response.data.data[0])
            }
        }).catch(error => { })
    }

    const getServiceOrderItem = useCallback((afterGet) => {
        setFetchLoading(true)
        api.get('/service_orders/?orderItemId=' + id + '&customerId=' + user.id, customerReqConfig()).then(response => {
            if (response.status === 200) {
                setOrderItem(response.data.data[0])
                if (afterGet) afterGet()
            }
        }).catch(error => {
            push('/profile/orders')
        }).finally(() => {
            setFetchLoading(false)
        })
    }, [id, user.id, push])

    const handleShowRateAndReviewModal = () => {
        setMoadlHeaderText(<>Rate &amp; Review Product</>)
        setModalComponent(<RateAndReview
            customerId={user.id}
            productId={orderItem.productId}
        />)
        setShowModal(true)
    }

    const handleShowCancelModal = () => {
        setMoadlHeaderText(<>Cancel Item</>)
        setModalComponent(<Cancel
            getServiceOrderItem={getServiceOrderItem}
            orderItemId={orderItem.id}
            setShowModal={setShowModal}
        />)
        setShowModal(true)
    }

    const handleShowReturnModal = () => {
        setMoadlHeaderText(<>Return Item</>)
        setModalComponent(<Return
            getServiceOrderItem={getServiceOrderItem}
            orderItemId={orderItem.id}
            setShowModal={setShowModal}
        />)
        setShowModal(true)
    }

    useEffect(() => {
        getApiConfig()
    }, [])

    useEffect(() => {
        getServiceOrderItem()
    }, [getServiceOrderItem])

    return <div className="fixed-top-bar">
        <div className="position-relative">
            {showModal && <Modal
                component={modalComponent}
                handleClose={setShowModal}
                headerText={modalHeaderText}
                zIndex={1050}
            />}
        </div>
        <CustomerHeader enableButtons />
        {fetchLoading ? <div className="py-5"><Spinner color="danger" /></div> : <div className="container my-4">
            <div className="row">
                <div className="col-12 col-lg-6 mb-4">
                    <div className="border py-3">
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    <img className="fit-contain w-100 hover-scale" src={orderItem.mainImage && baseURL + '/' + orderItem.mainImage} alt="" style={{ maxHeight: '200px' }} />
                                </div>
                                <div className="col-12">
                                    <h6 className="my-2">{orderItem.productName}</h6>
                                    <h6 className="fw-bold">&#8377;{Math.ceil(orderItem.amount)}</h6>
                                </div>
                                <div className="col-12 mb-3">
                                    <i className="fas fa-circle text-success blink me-2" style={{ fontSize: '.7rem' }} />
                                    <span className="h6 m-0 fw-bold text-capitalize">{orderItem.status}</span>
                                </div>
                                {(orderItem.status === RETURN_REQUEST || orderItem.status === CANCEL_REQUEST) && <><hr />
                                    <div className="col-12">
                                        <span className="small">{orderItem.userComment}</span>
                                    </div></>}
                                {orderItem.status === DELIVERED && orderItem.adminComment && <><hr />
                                    <div className="col-12">
                                        <span className="small">{orderItem.adminComment}</span>
                                    </div></>}
                                <div className="col-12 mt-3">
                                    {orderItem.status === ORDERED && <button className="btn btn-danger me-3" onClick={e => preventClick(e, handleShowCancelModal)}>Cancel</button>}
                                    {orderItem.status === DELIVERED && !isEmptyObj(apiConfig) && isNum(Number(apiConfig.returnDays)) && <button className="btn btn-primary me-3" onClick={e => preventClick(e, handleShowReturnModal)}>Return</button>}
                                    {orderItem.status === DELIVERED && <button className="btn btn-primary me-3" onClick={e => preventClick(e, handleShowRateAndReviewModal)}>Rate and Review</button>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-lg-6 mb-4">
                    <div className="border p-3">
                        <h6 className="text-dark fw-bold">Delivery Details</h6>
                        <span className="small text-dark fw-bold me-3">{orderItem.name}</span>
                        <span className=" border border-1 d-inline-block px-2 text-uppercase small  mb-2">{orderItem.addressType}</span>
                        <p className="small text-truncate">{orderItem.address}, {orderItem.locality}, {orderItem.city} - {orderItem.pin}, {orderItem.state}</p>
                        <p className="small text-dark fw-bold mb-1">Phone number</p>
                        <p className="small mb-1 text-truncate">
                            <i className="fas fa-fw fa-circle mx-2" style={{ fontSize: '.3rem' }} />
                            <span>{orderItem.phoneNumber}</span>
                        </p>
                        {orderItem.alternatePhoneNumber && <p className="small mb-1 text-truncate">
                            <i className="fas fa-fw fa-circle mx-2" style={{ fontSize: '.3rem' }} />
                            <span>{orderItem.alternatePhoneNumber}</span>
                        </p>}
                        <br />
                        {orderItem.emailId && <div className="w-50"><p className="small text-dark fw-bold mb-1">Email</p>
                            <p className="small mb-1 text-truncate">{orderItem.emailId}</p>
                        </div>}
                    </div>
                </div>
                <div className="col-12 col-lg-12 mb-4">
                    <div className="border p-3">
                        <h6 className="text-dark fw-bold">Order Status</h6>
                        <h6 className="my-2 text-truncate fw-bold text-capitalize">{ORDERED}</h6>
                        <OrderProgressBar orderItem={orderItem} />
                        {orderItem.cancelledAt ? (
                            <h6 className="mb-4 text-truncate small text-danger">This order has been cancelled.</h6>
                        ) : (
                            <h6 className="mb-4 text-truncate small">Your item is {orderItem.deliveredAt ? 'delivered' : 'in progress'}.</h6>
                        )}
                    </div>
                </div>
            </div>
        </div>}
    </div>
}

const RateAndReview = ({ productId, customerId }) => {
    const toast = useNotification()

    const [rating, setRating] = useState('')
    const [review, setReview] = useState('')
    const [fetchLoading, setFetchLoading] = useState(true)
    const [postLoading, setPostLoading] = useState(false)

    const getRatingAndReview = useCallback(() => {
        setFetchLoading(true)
        api.get('/rating_and_review/?productId=' + productId + '&customerId=' + customerId, customerReqConfig()).then(response => {
            if (response.status === 200) {
                setReview(response.data.data[0].review)
                setRating(response.data.data[0].rating)
            }
        }).catch(error => { }).finally(() => setFetchLoading(false))
    }, [customerId, productId])

    const addRatingAndReview = (e) => {
        e.preventDefault()

        const data = {
            customerId: customerId,
            productId: productId,
            rating: rating,
            review: review,
        }

        setPostLoading(true)
        api.post('/rating_and_review/', data, customerReqConfig()).then(response => {
            if (response.status === 200) {
                toast({ type: SUCCESS, message: response.data.message })
            }
        }).catch(error => {
            error.response ?
                toast({ type: ERROR, message: error.response.data.message }) :
                toast({ type: ERROR, message: error.message })
        }).finally(() => setPostLoading(false))
    }

    useEffect(() => {
        getRatingAndReview()
    }, [getRatingAndReview])

    return fetchLoading ? <div className="p-5"><Spinner color="danger" /></div> : <div className="w-100">
        <div className="d-flex align-items-center justify-content-between mb-3">
            <div>
                {[...Array(5)].map((_, index) => {
                    return <span key={nanoid()} className={`me-1 0 cursor-pointer ${String(rating) >= String(index + 1) ? 'text-yellow' : 'text-light'}`} onClick={() => setRating(index + 1)} style={{ textShadow: '0px 0px 2px #000', fontSize: '3rem' }}>&#9733;</span>
                })}
            </div>
            {postLoading ?
                <button className="btn btn-primary text-end d-flex align-items-center justify-content-center" onClick={e => e.preventDefault()}>
                    <Spinner color="white" spinnerSize="sm" />
                    <span className="ms-3">Apply</span>
                </button> :
                <button className="btn btn-primary text-end" onClick={e => addRatingAndReview(e)}>Apply</button>}
        </div>
        <textarea name="review" id="review" cols="30" rows="10" className="form-control" placeholder="Review" style={{ resize: 'none' }} onChange={e => setReview(e.target.value)} value={review} />
    </div>
}

const Cancel = ({ orderItemId, getServiceOrderItem, setShowModal }) => {
    const toast = useNotification()
    const [reason, setReason] = useState('')
    const [postLoading, setPostLoading] = useState(false)

    const cancelItem = () => {
        const data = {
            userComment: String(reason).trim(),
            status: CANCEL_REQUEST,
            orderItemId: orderItemId,
        }

        setPostLoading(true)
        api.put('/orders/?id=' + data.orderItemId + '&flag=changestatus', data, customerReqConfig()).then(response => {
            if (response.status === 200) {
                getServiceOrderItem(() => {
                    toast({ type: SUCCESS, message: response.data.message })
                    setShowModal(false)
                })
            }
        }).catch(error => {
            error.response ?
                toast({ type: ERROR, message: error.response.data.message }) :
                toast({ type: ERROR, message: error.message })
        }).finally(() => setPostLoading(false))
    }

    return <div className="w-100">
        <label htmlFor="reason">Reason <span className="text-danger">&#42;</span></label>
        <textarea name="reason" id="reason" cols="30" rows="5" className="form-control mb-3" placeholder="Reason" style={{ resize: 'none' }} onChange={e => setReason(e.target.value)} value={reason} />
        {(postLoading || String(reason).trim() === '') ?
            <button className="btn" style={{ cursor: "no-drop" }} onClick={e => e.preventDefault()}>Cancel</button> :
            <button className="btn btn-danger text-end" onClick={e => preventClick(e, cancelItem)}>Cancel</button>}
    </div>
}

const Return = ({ getServiceOrderItem, setShowModal, orderItemId }) => {
    const toast = useNotification()

    const [reason, setReason] = useState('')
    const [postLoading, setPostLoading] = useState(false)

    const returnItem = () => {

        const data = {
            userComment: String(reason).trim(),
            status: RETURN_REQUEST,
            orderItemId: orderItemId,
        }

        setPostLoading(true)
        api.put('/orders/?id=' + data.orderItemId + '&flag=changestatus', data, customerReqConfig()).then(response => {
            if (response.status === 200) {
                getServiceOrderItem(() => {
                    toast({ type: SUCCESS, message: response.data.message })
                    setShowModal(false)
                })
            }
        }).catch(error => {
            error.response ?
                toast({ type: ERROR, message: error.response.data.message }) :
                toast({ type: ERROR, message: error.message })
        }).finally(() => setPostLoading(false))
    }

    return <div className="w-100">
        <label htmlFor="reason">Reason <span className="text-danger">&#42;</span></label>
        <textarea name="reason" id="reason" cols="30" rows="5" className="form-control mb-3" placeholder="Reason" style={{ resize: 'none' }} onChange={e => setReason(e.target.value)} value={reason} />
        {(postLoading || String(reason).trim() === '') ?
            <button className="btn" style={{ cursor: "no-drop" }} onClick={e => e.preventDefault()}>Return</button> :
            <button className="btn btn-danger text-end" onClick={e => preventClick(e, returnItem)}>Return</button>}
    </div>
}

const OrderProgressBar = ({ orderItem }) => {
    const steps = [
        { label: ORDERED, date: orderItem.createTimestamp },
        { label: PACKED, date: orderItem.packedAt },
        { label: SHIPPED, date: orderItem.shippedAt },
        { label: CANCELLED, date: orderItem.cancelledAt },
        { label: DELIVERED, date: orderItem.deliveredAt },
        { label: RETURN_APPROVED, date: orderItem.returnApprovedAt },
        { label: RETURN_SHIPPED, date: orderItem.returnShippedAt },
        { label: RETURN_RECEIVED, date: orderItem.returnReceivedAt },
    ];

    const completedSteps = steps.filter(step => step.date);

    return (
        <div className="progress-container">
            {steps
                .filter(step => step.date)
                .map((step, index) => (
                    <div
                        key={index}
                        className={`progress-step ${completedSteps.includes(step) ? 'completed' : ''}`}
                    >
                        <div className="step-label">
                            <h6 className="fw-bold text-capitalize">{step.label}</h6>
                        </div>
                        <div class="progress-circle">
                            <div className={`step-circle ${step.date ? 'completed' : ''}`}></div>
                        </div>
                        <div className="step-date">
                            {step.date && (
                                <h6 className="small">{formateDate(step.date)}</h6>
                            )}
                        </div>
                    </div>
                ))}
        </div>
    );
};


export default CustomerServiceOrderDetails