import React, { useEffect } from "react"
import { removeFromLocalStorage } from "../../include/function"

const Logout = ({ setUser, userKey, tokenKey }) => {
    useEffect(() => {
        setTimeout(() => {
            removeFromLocalStorage(userKey)
            removeFromLocalStorage(tokenKey)
            setTimeout(() => {
                setUser({})
            }, 500)
        }, 500)
    }, [userKey, tokenKey, setUser])

    return <div className="p-3">Please wait...</div>
}

export default Logout
