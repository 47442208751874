import React from "react"
import { CUSTOMER, CUSTOMER_TOKEN } from "../../include/constant"
import Logout from "../common/Logout"

const CustomerLogout = ({ setCustomer }) => {
    return <Logout
        setUser={setCustomer}
        tokenKey={CUSTOMER_TOKEN}
        userKey={CUSTOMER}
    />
}

export default CustomerLogout
