// src/components/CustomerHeader.js
import React, { useState } from "react";
import { Dropdown } from 'react-bootstrap';
import { Link } from "react-router-dom";
import { getCustomer, isEmptyObj } from "../../include/function";
import { useCart } from "../context/UserCartCountContext";
import CustomerSideNavBar from "./CustomerSideNavBar";
import SearchBar from "./SearchBar";

// Notification Panel CSS
const notificationPanelStyle = {
    position: "absolute",
    right: "20px",
    top: "40px",
    width: "300px",
    backgroundColor: "#fff",
    borderRadius: "8px",
    zIndex: "1000",
    overflowY: "auto",
    maxHeight: "400px",
};

const CustomerHeader = ({ enableSearch = false, enableButtons = false }) => {
    const customer = getCustomer();
    const [toggleClass, setToggleClass] = useState('');
    const { totalCartItems } = useCart(); // Get totalCartItems from context
    const [showNotifications, setShowNotifications] = useState(false);

    const toggleNotifications = () => {
        setShowNotifications(!showNotifications);
    };

    const menuContent = !isEmptyObj(customer)
        ? [
            { url: "/profile/personal-info", text: "Profile", icon: "user" },
            { url: "/cart/' '/customer", text: "Cart", icon: "shopping-cart" },
            { url: "/wishlist", text: "Wishlist", icon: "heart" },
            { url: "/orders", text: "Orders", icon: "box" },
            { url: "/logout", text: "Logout", icon: "sign-out-alt" },
        ]
        : [
            { url: "/login", text: "Login", icon: "sign-in-alt" },
        ];

    return (
        <div>
            {enableButtons && (
                <div>
                    <CustomerSideNavBar
                        content={menuContent}
                        setToggleClass={setToggleClass}
                        toggleClass={toggleClass}
                    />
                </div>
            )}
            <header className="w-100  p-2 fixed-top shadow" style={{ backgroundColor: "#fff" }}>
                <div className="container-fluid m-0 h-100">
                    <div className="row h-100">
                        <div className="col-10 col-lg-10 d-flex align-items-center h-100" style={{ paddingLeft: "5%" }}>
                            {/* <i
                                className="block fas fa-bars h3 m-0 mt-1 cursor-pointer text-black mx-1 me-3"
                            onClick={() =>
                                setToggleClass(toggleClass === 'open' ? '' : 'open')
                            }
                            /> */}
                            <h3 className="my-0 d-flex align-items-center justify-content-center h-100 text-truncate me-2">
                                <Link
                                    to="/"
                                    className=" fw-bold text-uppercase  align-items-center"
                                >
                                    <img
                                        src={process.env.PUBLIC_URL + "/img/appname_1.png"}
                                        height="35px"
                                        width="155px"
                                        alt="appname"
                                        className="me-2"
                                    />
                                </Link>
                            </h3>
                            {enableSearch && <SearchBar />}
                        </div>
                        <div className="col-2 col-lg-2 h-100 d-flex align-items-center justify-content-end" style={{ paddingRight: "4%" }}>
                            {enableButtons && (
                                <div className="d-flex justify-content-end align-items-center h-100">
                                    {!isEmptyObj(customer)
                                        ? <ProfileMenu customer={customer} />
                                        : (<div className="hstack">
                                            <Link to="/login" className="btn btn-outline-black btn-sm me-2 mt-1">
                                                <i className="fas fa-sign-in-alt text-black" /> Login
                                            </Link>
                                        </div>)
                                    }

                                    <Link to="/cart/' '/customer" className="notification-icon me-3 mt-2 position-relative">
                                        <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                                            {totalCartItems}
                                        </span>
                                        <i className="fa-solid fa-cart-shopping" style={{ fontSize: "27px", color: "black" }}></i>
                                    </Link>

                                    {/* <Link to="/notification" className="notification-icon me-1 mt-2">
                                        <i className="fa-regular fa-bell" style={{ fontSize: "27px", color: "black" }}></i>
                                    </Link> */}

                                    {/* Notification Icon */}
                                    <Link onClick={toggleNotifications} className="notification-icon me-1 mt-2" style={{ position: "relative" }}>
                                        <i className="fa-regular fa-bell" style={{ fontSize: "27px", color: "black" }}></i>
                                        <span className="dot"></span>
                                    </Link>

                                    {/* Notification Panel */}
                                    {showNotifications && (
                                        <div className="notification-panel shadow-lg p-3" style={notificationPanelStyle}>
                                            <h5>Updates</h5>
                                            <div className="notification-section">
                                                <h6>New</h6>
                                                <NotificationItem title="This could be your vibe" time="9h" />
                                                <NotificationItem title="Explore ideas related to Multiple Product Showreel" time="13h" />
                                                <NotificationItem title="Brochure Design for you" time="23h" />
                                            </div>
                                            <hr />
                                            <div className="notification-section">
                                                <h6>Seen</h6>
                                                <NotificationItem title="Inspired by you" time="1d" />
                                                <NotificationItem title="Check out these ideas" time="2d" />
                                                <NotificationItem title="Web Design for you" time="2d" />
                                            </div>
                                        </div>
                                    )}

                                    {/* {!isEmptyObj(customer) ? (
                                        <Link to="/logout" className="btn btn-outline-black btn-sm ms-2 mt-2" >
                                            <i className="fas fa-sign-out-alt text-black" /> Logout
                                        </Link>
                                    ) : (
                                        <Link to="/login" className="btn btn-outline-black btn-sm ms-2 mt-2">
                                            <i className="fas fa-sign-in-alt text-black" /> Login
                                        </Link>
                                    )} */}

                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </header >
        </div >
    );
};

// Notification Item Component
const NotificationItem = ({ title, time }) => (
    <div className="d-flex align-items-center mb-3">
        <div style={{ marginRight: "10px", width: "40px", height: "40px", backgroundColor: "#f0f0f0", borderRadius: "8px" }}></div>
        <div>
            <p className="m-0" style={{ fontWeight: "bold" }}>{title}</p>
            <small style={{ color: "gray" }}>{time}</small>
        </div>
    </div>
);

const ProfileMenu = ({ customer }) => {
    return (
        <Dropdown className="me-3 mb-1">
            <Dropdown.Toggle variant="outline-black" id="profile-dropdown" className="ms-2 mt-2">
                <i className="fas fa-user-circle" /> {customer.name}
            </Dropdown.Toggle>

            <Dropdown.Menu>
                <Dropdown.Item as={Link} to="/profile/personal-info">
                    <i className="fas fa-user" /> My Profile
                </Dropdown.Item>
                <Dropdown.Item as={Link} to="/orders">
                    <i className="fas fa-box" /> Orders
                </Dropdown.Item>
                <Dropdown.Item as={Link} to="/wishlist">
                    <i className="fas fa-heart" /> Wishlist
                </Dropdown.Item>
                <Dropdown.Item as={Link} to="/logout">
                    <i className="fas fa-sign-out-alt" /> Logout
                </Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    );
}

export default CustomerHeader;
