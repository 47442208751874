import { nanoid } from "nanoid"
import React, { useEffect, useState } from "react"
import { api } from "../../include/api"
import { ADD, EDIT, ERROR, SUCCESS } from "../../include/constant"
import { preventArrow, preventClick, preventWheel, stateList, validatePhoneNumber, validatePincode } from "../../include/function"
import { useNotification } from "../../Toast/ToastProvider"

const AddressForm = ({ id, userId, type, reqConfig, title, onSave, editAddress, onCancel }) => {
    const toast = useNotification()

    const [saving, setSaving] = useState(false)
    const [name, setName] = useState('')
    const [nameError, setNameError] = useState('')
    const [phoneNumber, setPhoneNumber] = useState('')
    const [phoneNumberError, setPhoneNumberError] = useState('')
    const [alternatePhoneNumber, setAlternatePhoneNumber] = useState('')
    const [alternatePhoneNumberError, setAlternatePhoneNumberError] = useState('')
    const [locality, setLocality] = useState('')
    const [localityError, setLocalityError] = useState('')
    const [address, setAddress] = useState('')
    const [addressError, setAddressError] = useState('')
    const [city, setCity] = useState('')
    const [cityError, setCityError] = useState('')
    const [state, setState] = useState('')
    const [stateError, setStateError] = useState('')
    const [landmark, setLandmark] = useState('')
    const [landmarkError, setLandmarkError] = useState('')
    const [pin, setPin] = useState('')
    const [pinError, setPinError] = useState('')
    const [addressType, setAddressType] = useState('')
    const [addressTypeError, setAddressTypeError] = useState('')

    const handleName = (value) => {
        setNameError('')
        setName(value)
    }

    const handlePhoneNumber = (value) => {
        setPhoneNumberError('')
        setPhoneNumber(value)
    }

    const handleAlternatePhoneNumber = (value) => {
        setAlternatePhoneNumberError('')
        setAlternatePhoneNumber(value)
    }

    const handleLocality = (value) => {
        setLocalityError('')
        setLocality(value)
    }

    const handleAddress = (value) => {
        setAddressError('')
        setAddress(value)
    }

    const handleCity = (value) => {
        setCityError('')
        setCity(value)
    }

    const handleState = (value) => {
        setStateError('')
        setState(value)
    }

    const handleLandmark = (value) => {
        setLandmarkError('')
        setLandmark(value)
    }

    const handlePin = (value) => {
        setPinError('')
        setPin(value)
    }

    const handleAddressType = (value) => {
        setAddressTypeError('')
        setAddressType(value)
    }

    const handleSave = () => {
        let error = false

        const data = {
            id: String(id).trim(),
            customerId: String(userId).trim(),
            name: String(name).trim(),
            phoneNumber: String(phoneNumber).trim(),
            locality: String(locality).trim(),
            address: String(address).trim(),
            city: String(city).trim(),
            state: String(state).trim(),
            pin: String(pin).trim(),
            addressType: String(addressType).trim(),

            landmark: String(landmark).trim(),
            alternatePhoneNumber: String(alternatePhoneNumber).trim(),
        }

        if (data.name === '') {
            setNameError('Name required')
            error = true
        } else if (data.name.length > 100) {
            setNameError('Name is too long')
            error = true
        }

        if (data.phoneNumber === '') {
            setPhoneNumberError('Phone number required')
            error = true
        } else if (!validatePhoneNumber(data.phoneNumber)) {
            setPhoneNumberError('Invalid phone number')
            error = true
        }

        if (data.locality === '') {
            setLocalityError('Locality required')
            error = true
        } else if (data.locality.length > 300) {
            setLocalityError('Locality is too long')
            error = true
        }

        if (data.address === '') {
            setAddressError('Address required')
        } else if (data.address.length > 500) {
            setAddressError('Address is too long')
            error = true
        }

        if (data.city === '') {
            setCityError('City required')
        } else if (data.city.length > 300) {
            setCityError('City is too long')
            error = true
        }

        if (data.landmark !== '' && data.landmark.length > 300) {
            setLandmarkError('Landmark is too long')
            error = true
        }

        if (data.alternatePhoneNumber !== '' && !validatePhoneNumber(data.alternatePhoneNumber)) {
            setAlternatePhoneNumberError('Invalid phone number')
            error = true
        }

        if (data.state === '') {
            setStateError('State required')
            error = true
        }

        if (data.pin === '') {
            setPinError('Pincode required')
            error = true
        } else if (!validatePincode(data.pin)) {
            setPinError('Invalid pincode')
            error = true
        }

        if (data.addressType === '') {
            setAddressTypeError('Address type required')
            error = true
        }

        if (!error) {
            if (type === ADD) {
                setSaving(true)
                api.post('/customer_address/', data, reqConfig).then(response => {
                    if (response.status === 200) {
                        toast({ type: SUCCESS, message: response.data.message })
                        data.id = response.data.data.id
                        onSave(data)
                    }
                }).catch(error => {
                    error.response ?
                        toast({ type: ERROR, message: error.response.data.message }) :
                        toast({ type: ERROR, message: error.message })
                }).finally(() => setSaving(false))
            } else if (type === EDIT) {
                setSaving(true)
                api.put('/customer_address/?id=' + id, data, reqConfig).then(response => {
                    if (response.status === 200) {
                        toast({ type: SUCCESS, message: response.data.message })
                        onSave(data)
                    }
                }).catch(error => {
                    error.response ?
                        toast({ type: ERROR, message: error.response.data.message }) :
                        toast({ type: ERROR, message: error.message })
                }).finally(() => setSaving(false))
            }
        }
    }

    useEffect(() => {
        if (editAddress) {
            setName(editAddress.name)
            setPhoneNumber(editAddress.phoneNumber)
            setAlternatePhoneNumber(editAddress.alternatePhoneNumber || '')
            setLocality(editAddress.locality || '')
            setAddress(editAddress.address)
            setCity(editAddress.city)
            setState(editAddress.state)
            setLandmark(editAddress.landmark)
            setPin(editAddress.pin)
            setAddressType(editAddress.addressType)
        }
    }, [editAddress])

    return <div className={`py-3 ${type === EDIT ? 'border-top' : ''}`}>
        <h5>{title}</h5>
        <div className="row my-2">
            <div className="col-12 col-md-6 col-xl-4">
                <label htmlFor="name" className="small">Name</label>
                <input type="text" className={`form-control form-control-lg ${nameError !== '' && 'border-danger'}`} placeholder="Name" id="name" autoFocus required autoComplete="off" onChange={e => handleName(e.target.value)} value={name} />
                <span className="text-danger my-2 small fw-bold">{nameError}</span>
                <br />
            </div>
            <div className="col-12 col-md-6 col-xl-4">
                <label htmlFor="phoneNumber" className="small">Phone Number</label>
                <input type="number" className={`form-control form-control-lg ${phoneNumberError !== '' && 'border-danger'}`} placeholder="Phone Number" id="phoneNumber" required autoComplete="off" onFocus={e => preventWheel(e)} onKeyDown={e => preventArrow(e)} onChange={e => handlePhoneNumber(e.target.value)} value={phoneNumber} />
                <span className="text-danger my-2 small fw-bold">{phoneNumberError}</span>
                <br />
            </div>
            <div className="col-12">
                <label htmlFor="address" className="small">Address (Street and Area)</label>
                <textarea className={`form-control resize-none w-100 ${addressError !== '' && 'border-danger'}`} rows="5" id="address" placeholder="Address (Street and Area)" autoComplete="off" onChange={e => handleAddress(e.target.value)} value={address} />
                <span className="text-danger my-2 small fw-bold">{addressError}</span>
                <br />
            </div>
            <div className="col-12 col-md-6 col-xl-4">
                <label htmlFor="pin" className="small">Pincode</label>
                <input type="number" className={`form-control form-control-lg ${pinError !== '' && 'border-danger'}`} placeholder="Pincode" id="pin" required autoComplete="off" onFocus={e => preventWheel(e)} onKeyDown={e => preventArrow(e)} onChange={e => handlePin(e.target.value)} value={pin} />
                <span className="text-danger my-2 small fw-bold">{pinError}</span>
                <br />
            </div>
            <div className="col-12 col-md-6 col-xl-4">
                <label htmlFor="locality" className="small">Locality</label>
                <input type="text" className={`form-control form-control-lg ${localityError !== '' && 'border-danger'}`} placeholder="Locality" id="locality" required autoComplete="off" onChange={e => handleLocality(e.target.value)} value={locality} />
                <span className="text-danger my-2 small fw-bold">{localityError}</span>
                <br />
            </div>
            <div className="col-12 col-md-6 col-xl-4">
                <label htmlFor="city" className="small">City/District/Town</label>
                <input type="text" className={`form-control form-control-lg ${cityError !== '' && 'border-danger'}`} placeholder="City/District/Town" id="city" required autoComplete="off" onChange={e => handleCity(e.target.value)} value={city} />
                <span className="text-danger my-2 small fw-bold">{cityError}</span>
                <br />
            </div>
            <div className="col-12 col-md-6 col-xl-4">
                <label htmlFor="state" className="small">State</label>
                <select className={`form-control form-control-lg ${stateError !== '' && 'border-danger'}`} id="state" required onChange={e => handleState(e.target.value)} value={state} >
                    <option value="" disabled>Select State</option>
                    {stateList.map((state, index) => <option key={nanoid()} value={state}>{state}</option>)}
                </select>
                <span className="text-danger my-2 small fw-bold">{stateError}</span>
                <br />
            </div>
            <div className="col-12 col-md-6 col-xl-4">
                <label htmlFor="landmark" className="small">Landmark (Optional)</label>
                <input type="text" className={`form-control form-control-lg ${landmarkError !== '' && 'border-danger'}`} placeholder="Landmark" id="landmark" autoComplete="off" onChange={e => handleLandmark(e.target.value)} value={landmark} />
                <span className="text-danger my-2 small fw-bold">{landmarkError}</span>
                <br />
            </div>
            <div className="col-12 col-md-6 col-xl-4">
                <label htmlFor="alternatePhoneNumber" className="small">Alternate&nbsp;Phone&nbsp;(Optional)</label>
                <input type="number" className={`form-control form-control-lg ${alternatePhoneNumberError !== '' && 'border-danger'}`} placeholder="Alternate Phone" id="alternatePhoneNumber" autoComplete="off" onFocus={e => preventWheel(e)} onKeyDown={e => preventArrow(e)} onChange={e => handleAlternatePhoneNumber(e.target.value)} value={alternatePhoneNumber} />
                <span className="text-danger my-2 small fw-bold">{alternatePhoneNumberError}</span>
                <br />
            </div>
            <div className="col-12 col-xl-8">
                <label className="small">Address Type</label>
                <div className="row">
                    <div className="col-12 col-sm-6 mb-3">
                        <label htmlFor="home" className={`small form-control form-control-lg d-flex justify-content-between align-items-center cursor-pointer ${addressType === 'home' ? 'bg-primary text-white fw-bold' : ''}`}>
                            <span>Home</span>
                            <input type="radio" className="form-check-input" name="addressType" id="home" checked={addressType === 'home'} onChange={e => handleAddressType('home')} />
                        </label>
                    </div>
                    <div className="col-12 col-sm-6 mb-3">
                        <label htmlFor="work" className={`small form-control form-control-lg d-flex justify-content-between align-items-center cursor-pointer ${addressType === 'work' ? 'bg-primary text-white fw-bold' : ''}`}>
                            <span>Work</span>
                            <input type="radio" className="form-check-input" name="addressType" id="work" checked={addressType === 'work'} onChange={e => handleAddressType('work')} />
                        </label>
                    </div>
                </div>
                <span className="text-danger my-2 small fw-bold">{addressTypeError}</span>
                <br />
            </div>
        </div>
        <div className="my-2 w-100 d-flex align-items-center">
            {saving ?
                <button className="btn btn-primary btn-lg me-3" onClick={e => e.preventDefault()}>Save</button> :
                <button className="btn btn-primary btn-lg me-3" onClick={e => preventClick(e, handleSave)}>Save</button>}
            <button className="btn btn-lg" onClick={e => preventClick(e, onCancel)}>Cancel</button>
        </div>
    </div>
}
export default AddressForm