import React from "react"
import { Link } from "react-router-dom"
import { preventClick } from "../../include/function"

const Modal = ({ zIndex, component, headerText, handleClose }) => {
    return <div className="position-fixed start-0 top-0 vh-100 vw-100 d-flex align-items-center justify-content-center" style={{ zIndex: zIndex, backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
        <div className="container">
            <div className="bg-white rounded shadow-sm p-4">
                <div className="d-flex align-items-center justify-content-between pe-3">
                    <h6 className="fw-bold m-0">{headerText}</h6>
                    <Link to="#" className="d-block" onClick={e => preventClick(e, () => handleClose())}><i className="fa fa-times fa-lg text-danger" /></Link>
                </div>
                <hr />
                <div className="py-3">{component}</div>
            </div>
        </div>
    </div>
}

export default Modal