import React, { useCallback, useEffect, useState } from "react"
import { Link, useLocation } from "react-router-dom"
import { getCustomer } from "../../include/function"
import CustomerHeader from "./Header"
import { nanoid } from "nanoid"
import CustomerPersonalInfo from "./PersonalInfo"
import CustomerOrders from "./Orders"
import CustomerAddress from "./Address"
import CustomerChangePassword from "./ChangePassword"

const CustomerProfile = () => {
    const { pathname } = useLocation()
    const user = getCustomer()
    const [body, setBody] = useState('')


    const menuContent = useCallback(() => {
        return [
            {
                url: "/profile/personal-info",
                text: "Profile",
                icon: "user",
                body: <CustomerPersonalInfo />
            },
            {
                url: "/profile/orders",
                text: "Orders",
                icon: "shopping-cart",
                body: <CustomerOrders />
            },
            {
                url: "/profile/address",
                text: "Address",
                icon: "map-marker-alt",
                body: <CustomerAddress />
            },
            {
                url: "/profile/change-password",
                text: "Change Password",
                icon: "key",
                body: <CustomerChangePassword />
            }
        ]
    }, [])



    useEffect(() => {
        menuContent().forEach(menu => {
            // console.log(menu.url)
            console.log(pathname)
            if (pathname === menu.url) {

                setBody(menu.body)
                // console.log(menu.url)
            }
        })
    }, [menuContent, pathname])

    return <div className="fixed-top-bar">
        <CustomerHeader enableSearch enableButtons/>
        <div className="container my-3">
            <div className="row">
                <div className="col-12 col-lg-3">
                    <div className="p-2 border d-flex align-items-center shadow-sm">
                        <img className="img-xs rounded-circle border border-1 me-3" src={process.env.PUBLIC_URL + '/img/profile.png'} alt="" />
                        <h6 className="m-0 text-truncate">Hi, {user.name}</h6>
                    </div>
                    <div className="d-block d-lg-none border my-3 shadow-sm overflow-auto hide-scrollbar" style={{ whiteSpace: "nowrap" }}>
                        {menuContent().map(menu => {
                            return <TopNavBarContent
                                icon={menu.icon}
                                text={menu.text}
                                url={menu.url}
                                pathname={pathname}
                                key={nanoid()}
                            />
                        })}
                    </div>
                    <div className="d-none d-lg-block border my-3 shadow-sm">
                        {menuContent().map(menu => {
                            return <MenuContent
                                icon={menu.icon}
                                text={menu.text}
                                url={menu.url}
                                pathname={pathname}
                                key={nanoid()}
                            />
                        })}
                    </div>
                </div>
                <div className="col-12 col-lg-9">
                    <div className="p-2 border shadow-sm">{body}</div>
                </div>
            </div>
        </div>
    </div>
}

const MenuContent = ({ url, icon, text, pathname }) => {
    return <Link to={url} className={`${url === pathname ? 'bg-primary' : ''} d-block py-3 border-bottom container`}>
        <div className="row">
            <div className="col-2">
                <i className={`fas fa-${icon} ${url === pathname ? 'text-white' : 'text-primary'}`} />
            </div>
            <div className="col-10">
                <span className={`small text-uppercase fw-bold ${url === pathname ? 'text-white' : 'text-muted'}`}>{text}</span>
            </div>
        </div>
    </Link>
}

const TopNavBarContent = ({ url, icon, text, pathname }) => {
    return <Link to={url} className={`${pathname === url ? 'prof-side-active' : ''} d-inline-block px-3 py-2`}>
        <i className={`fas fa-${icon} text-primary me-3`} />
        <span className="text-muted small text-uppercase fw-bold">{text}</span>
    </Link>
}

export default CustomerProfile