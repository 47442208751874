import React, { useEffect, useState } from 'react';
import { Button, Card, Form, Collapse } from 'react-bootstrap';
import Select from 'react-select'; // Import react-select
import { api, superAdminReqConfig } from '../../include/api';
import { ERROR, SUCCESS } from '../../include/constant';
import { useNotification } from '../../Toast/ToastProvider';

const LoyaltyPointsSettings = () => {
    const toast = useNotification();
    const [loyaltyData, setLoyaltyData] = useState([]);
    const [names, setNames] = useState([]); // This will hold product and category data
    const [formData, setFormData] = useState({
        customerType: '',
        rangeFrom: '',
        rangeTo: '',
        points: '',
        productId: '',
        brandId: '',
        id: '', 
    });
    const [open, setOpen] = useState(false);
    const [isEditing, setIsEditing] = useState(false); 

    useEffect(() => {
        const fetchNames = async () => {
            try {
                const response = await api.get('/product_cat_name/', superAdminReqConfig());
                setNames(response.data.data); // Store product and category data
            } catch (error) {
                console.error("Error fetching product category names:", error);
            }
        };

        fetchNames();
    }, []);

    useEffect(() => {
        fetchLoyaltyData();
    }, []);

    const fetchLoyaltyData = async () => {
        try {
            const response = await api.get('/loyalty_points/', superAdminReqConfig());
            if (response.status === 200) {
                setLoyaltyData(response.data.data);
            }
        } catch (error) {
            toast({
                type: ERROR,
                message: error.response?.data?.message || error.message,
            });
        }
    };

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const handleProductChange = (selectedOption) => {
        setFormData((prevData) => ({
            ...prevData,
            productId: selectedOption?.productId || '',
        }));
    };

    const handleBrandChange = (selectedOption) => {
        setFormData((prevData) => ({
            ...prevData,
            brandId: selectedOption?.categoryId || '',
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            let response;
            if (isEditing) {
                response = await api.put(`/loyalty_points/?id=${formData.id}`, formData, superAdminReqConfig());
            } else {
                response = await api.post('/loyalty_points/', formData, superAdminReqConfig());
            }
            
            if (response.status === 200) {
                toast({ type: SUCCESS, message: isEditing ? 'Loyalty points updated successfully' : 'Loyalty points added successfully' });
                setLoyaltyData((prevData) => {
                    if (isEditing) {
                        return prevData.map(item => item.id === formData.id ? response.data.data : item);
                    }
                    return [...prevData, response.data.data];
                });
                fetchLoyaltyData();
                resetForm();
            }
        } catch (error) {
            toast({ type: ERROR, message: error.response?.data?.message || error.message });
        }
    };

    const handleSetEditValues = (id) => {
        const loyaltyPoint = loyaltyData.find(data => data.id === id);
        if (loyaltyPoint) {
            setFormData(loyaltyPoint);
            setIsEditing(true); 
            setOpen(true);
        }
    };

    const onEditDelete = () => {
        fetchLoyaltyData();
    };

    // Prepare options for react-select
    const productOptions = names.map(product => ({
        value: product.productId,
        label: product.productName,
        productId: product.productId,
        categoryId: product.categoryId
    }));

    const brandOptions = names.map(category => ({
        value: category.categoryId,
        label: category.categoryName,
        categoryId: category.categoryId
    }));

    const resetForm = () => {
        setFormData({
            customerType: '',
            rangeFrom: '',
            rangeTo: '',
            points: '',
            productId: '',
            brandId: '',
            id: '', 
        });
        setIsEditing(false); 
        setOpen(false); 
    };

    return (
        <Card className="m-3">
            <Card.Header>
                <Button
                    className='fs-5 fw-bold text-primary'
                    variant="link"
                    onClick={() => setOpen(!open)}
                    aria-controls={`collapse-form`}
                    aria-expanded={open}
                >
                    Loyalty Points
                </Button>
            </Card.Header>
            <Collapse in={open}>
                <div id='collapse-form'>
                    <div className='row ms-2'>
                        {loyaltyData.length > 0 ? (
                            loyaltyData.map((d) => (
                                <SettingsView
                                    key={d.id}
                                    loyaltyPoints={d}
                                    onEditDelete={onEditDelete}
                                    toast={toast}
                                    handleSetEditValues={() => handleSetEditValues(d.id)}
                                />
                            ))
                        ) : (
                            <p>No loyalty points available.</p>
                        )}
                    </div>
                    <Card.Body>
                        <Form onSubmit={handleSubmit}>
                            <Form.Group controlId="customerType">
                                <Form.Label>Customer Type</Form.Label>
                                <Form.Control as="select" name="customerType" value={formData.customerType} onChange={handleChange} required>
                                    <option value="">Select Customer Type</option>
                                    <option value="individual">Individual</option>
                                    <option value="engineer">Engineer</option>
                                    <option value="contractor">Contractor</option>
                                    <option value="sub-dealer">Sub-dealer</option>
                                    <option value="builder">Builder</option>
                                    <option value="promoter">Promoter</option>
                                </Form.Control>
                            </Form.Group>

                            <Form.Group controlId="brandId" className="mt-3">
                                <Form.Label>Brand</Form.Label>
                                <Select
                                    options={brandOptions}
                                    onChange={handleBrandChange}
                                    placeholder="Select Brand"
                                    value={brandOptions.find(option => option.categoryId === formData.brandId) || null}
                                    isSearchable
                                />
                            </Form.Group>

                            <Form.Group controlId="productId" className="mt-3">
                                <Form.Label>Product</Form.Label>
                                <Select
                                    options={productOptions}
                                    onChange={handleProductChange}
                                    placeholder="Select Product"
                                    value={productOptions.find(option => option.productId === formData.productId) || null}
                                    isSearchable
                                />
                            </Form.Group>

                            <Form.Group controlId="rangeFrom" className="mt-3">
                                <Form.Label>Range From</Form.Label>
                                <Form.Control type="number" name="rangeFrom" value={formData.rangeFrom} onChange={handleChange} required />
                            </Form.Group>

                            <Form.Group controlId="rangeTo" className="mt-3">
                                <Form.Label>Range To</Form.Label>
                                <Form.Control type="number" name="rangeTo" value={formData.rangeTo} onChange={handleChange} required />
                            </Form.Group>

                            <Form.Group controlId="points" className="mt-3">
                                <Form.Label>Points</Form.Label>
                                <Form.Control type="number" name="points" value={formData.points} onChange={handleChange} required />
                            </Form.Group>

                            <Button variant="primary" type="submit" className="mt-4">
                                Save
                            </Button>
                        </Form>
                    </Card.Body>
                </div>
            </Collapse>
        </Card>
    );
};

const SettingsView = ({ loyaltyPoints, onEditDelete, toast, handleSetEditValues }) => {
    const handleDeleteSettings = async () => {
        if (loyaltyPoints.id) {
            try {
                const response = await api.delete('/loyalty_points/?id=' + loyaltyPoints.id, superAdminReqConfig());
                if (response.status === 200) {
                    toast({ type: SUCCESS, message: response.data.message });
                    onEditDelete();
                }
            } catch (error) {
                toast({ type: ERROR, message: error.response?.data?.message || error.message });
            }
        }
    };

    return (
        <div className="col-12 col-md-6 col-lg-4 col-xl-3 p-2">
            <div className="border shadow-sm p-2">
                <h6 className="d-flex align-items-center justify-content-between fw-bold">
                    <div className="text-truncate">
                        <i className={`fas fa-circle blink text-success`} style={{ fontSize: '.7rem' }} />
                        <span className="ps-2">Loyalty Points</span>
                    </div>
                    <span className="small d-flex align-items-center hover-underline">
                        <i className="fas fa-pencil-alt fa-sm p-2 text-primary" onClick={handleSetEditValues} />
                        <i className="fas fa-trash-alt fa-sm ps-2 text-danger" onClick={handleDeleteSettings} />
                    </span>
                </h6>
                <hr className="my-2" />
                <p className="small mb-1 text-muted">
                    <i className="fas fa-key fa-md fa-fw text-primary" />
                    <span className="ps-2 text-truncate">{loyaltyPoints.customerType}</span>
                </p>
                <p className="small mb-1 text-muted">
                    <i className="fas fa-square-check fa-md fa-fw text-primary" />
                    <span className="ps-2 text-truncate">{loyaltyPoints.points}</span>
                </p>
            </div>
        </div>
    );
};



export default LoyaltyPointsSettings;
