import { useState, useEffect } from "react";
import { api, superAdminReqConfig } from "../../include/api";
import "../../styles/Super.css";
import SuperAdminHeader from "./Header";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import Papa from "papaparse";


const ServiceReport = () => {
    const [fetchLoading, setFetchLoading] = useState(true);
    const [serviceData, setServiceData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");

    const getOrders = (afterGet) => {
        setFetchLoading(true);
        api.get('/service_stock_details/', superAdminReqConfig()).then(response => {
            if (response.status === 200) {
                setServiceData(response.data.data);
                setFilteredData(response.data.data);
                if (afterGet) afterGet(response.data.data);
            }
        }).catch(error => {
            setServiceData([]);
            setFilteredData([]);
            if (afterGet) afterGet([]);
        }).finally(() => setFetchLoading(false));
    };

    useEffect(() => {
        getOrders();
    }, []);

    // Handle Search Filter
    const handleSearch = (query) => {
        setSearchQuery(query);

        const filtered = serviceData.filter((data) => {
            return (
                data.serviceId?.toString().toLowerCase().includes(query?.toLowerCase()) ||
                data.serviceName?.toLowerCase().includes(query?.toLowerCase()) 
            );
        });

        setFilteredData(filtered);
    };

    // PDF Download
    const downloadPDF = () => {
        const doc = new jsPDF();
        const title = "Service Stock Report";

        doc.setFontSize(18);

        const pageWidth = doc.internal.pageSize.getWidth();
        const titleWidth = doc.getTextWidth(title);
        const titleX = (pageWidth - titleWidth) / 2;

        doc.text(title, titleX, 10);
        autoTable(doc, {
            head: [['S.No', 'Service Id', 'Service Name', 'Service Category']],
            body: filteredData.map((service, i) => [
                i + 1,
                service.serviceId,
                service.serviceName,
                service.categoryNames,
            ])
        });
        doc.save('service_stock.pdf');
    };

    // CSV Download
    const downloadCSV = () => {
        const csvData = filteredData.map((service, i) => ({
            'S.No': i + 1,
            'Service Id': service.serviceId,
            'Service Name': service.serviceName,
            'Service Category': service.categoryNames,
        }));

        const csv = Papa.unparse(csvData);
        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'service_stock.csv');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };


    return (
        <SuperAdminHeader>
            <div className="fixed-top-bar">
                <div className="card">
                    <h5 className="m-2">Service Report</h5>
                    <div className="card-body">
                        <div className="row ">
                        <div className="search-section m-3 col-8">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Search by service ID or service Name"
                                            value={searchQuery}
                                            onChange={(e) => handleSearch(e.target.value)}
                                        />
                                    </div>
                            <div className="col m-3">
                                <button
                                    className="btn btn-success mx-2"
                                    onClick={downloadPDF}
                                    style={{ fontSize: "14px" }}
                                >
                                    <i class="fa-solid fa-download"></i> PDF
                                </button>
                                <button
                                    className="btn btn-success mx-2"
                                    onClick={downloadCSV}
                                    style={{ fontSize: "14px" }}
                                >
                                    <i class="fa-solid fa-download"></i> CSV
                                </button>
                            </div>
                        </div>

                        {/* services Report Table */}
                        <div className="table-wrapper">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>S.No</th>
                                        <th>service ID</th>
                                        <th>service Name</th>
                                        <th>Category</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {filteredData.map((service, index) => (
                                        <tr key={service.serviceId}>
                                            <td>{index + 1}</td>
                                            <td>{service.serviceId}</td>
                                            <td>{service.serviceName}</td>
                                            <td>{service.categoryNames}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </SuperAdminHeader>
    );
};

export default ServiceReport;
