import React from "react"
import { ADMIN, ADMIN_TOKEN } from "../../include/constant"
import Logout from "../common/Logout"

const AdminLogout = ({ setAdmin }) => {
    return <Logout
        setUser={setAdmin}
        tokenKey={ADMIN_TOKEN}
        userKey={ADMIN}
    />
}

export default AdminLogout
