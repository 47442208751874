import React from "react";
import Confetti from "react-confetti";
import { Link } from "react-router-dom";
import { useWindowSize } from "react-use";
import CustomerHeader from "./Header";


const OrderPlaced = () => {
    // Get window dimensions for Confetti effect
    const { width, height } = useWindowSize();

    return (
        <div className="fixed-top-bar">
            <CustomerHeader enableSearch enableButtons />
            {/* Confetti animation */}
            {/* <Confetti 
            width={width} 
            height={height} 
            numberOfPieces={200} 
            gravity={0.1} 
            wind={0.01} 
        /> */}
            <Confetti width={width} height={height} recycle={false} />
            <div className="container mb-3 mt-5 text-center">
                <h2 className="text-center">Thanks for your order!</h2>
                <h2 className="text-center">Order Placed Successfully</h2>
                <div className="text-center">
                    <Link to='/orders' className="btn btn-primary">View Orders</Link>
                </div>
            </div>
        </div>
    );
};

export default OrderPlaced;
