import { useState, useEffect } from "react";
import { api, baseURL, adminReqConfig } from "../../include/api";
import "../../styles/Super.css";
import AdminHeader from "./Header";

const AdminBrand = () => {
    const [fetchLoading, setFetchLoading] = useState(true);
    const [brandList, setBrandList] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");

    useEffect(() => {
        getBrand();
    }, []);

    const getBrand = (afterGet) => {
        setFetchLoading(true);
        api.get("/category/", adminReqConfig()).then((response) => {
            if (response.status === 200) {
                setBrandList(response.data.data);
                setFilteredData(response.data.data);
                if (afterGet) afterGet(response.data.data);
            }
        }).catch((error) => {
            setBrandList([]);
            if (afterGet) afterGet([]);
        }).finally(() => setFetchLoading(false));
    };

    // Handle Search Filter
    const handleSearch = (query) => {
        setSearchQuery(query);

        const filtered = brandList.filter((data) => {
            return data.name?.toLowerCase().includes(query?.toLowerCase());
        });

        setFilteredData(filtered);
    };

    return (
        <AdminHeader>
            <div className="fixed-top-bar">
                <div className="card">
                    <h5 className="m-2">Brand</h5>
                    <div className="card-body">
                        <div className="row">
                            <div className="search-section m-3 col-8">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Search by Brand Name"
                                    value={searchQuery}
                                    onChange={(e) => handleSearch(e.target.value)}
                                />
                            </div>
                        </div>

                        {/* Products Report Table */}
                        {fetchLoading ? (
                            <div className="text-center my-5">
                                <div className="spinner-border" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>
                            </div>
                        ) : (
                            <div className="table-wrapper">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>S.No</th>
                                            <th>Image</th>
                                            <th>Name</th>
                                            <th>Parent</th>
                                            <th>Description</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {filteredData.filter(f => String(f.parentId) === '-1').map((brand, index) => (
                                            <tr key={brand.id}>
                                                <td>{index + 1}</td>
                                                <td style={{ width: "30%" }}>
                                                    <img src={baseURL + "/" + brand.imageUrl} alt="" className="" style={{ width: "30%" }} />
                                                </td>
                                                <td>{brand.name}</td>
                                                <td>{brand.name === brand.parentName ? "Itself" : brand.parentName}</td>
                                                <td>{brand.description}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </AdminHeader>
    );
};

export default AdminBrand;
