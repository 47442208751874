import React from "react"
import { useParams } from "react-router-dom"
import { EDIT } from "../../include/constant"
import CategoryForm from "../common/CategoryForm"
import SuperAdminHeader from "./Header"

const SuperAdminEditCategory = ({ from }) => {
    const { id } = useParams()

    return <SuperAdminHeader >
        <div className="fixed-top-bar">
            <CategoryForm
                id={id}
                title={"Edit " + from}
                type={EDIT}
                from={from}
            />
        </div>
    </SuperAdminHeader>
}

export default SuperAdminEditCategory