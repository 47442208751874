import { ADMIN, ADMIN_TOKEN, CART, CHECKOUT, CUSTOMER, CUSTOMER_TOKEN, SERVICE_CART, SUPER_ADMIN, SUPER_ADMIN_TOKEN } from "./constant"

export const preventArrow = (e) => {
    if (e.which === 38 || e.which === 40) {
        e.preventDefault()
    }
}

export const preventClick = (e, func, ...param) => {
    if (e) e.preventDefault()
    if (func) func(param)
}

export const preventWheel = (e) => {
    e.target.addEventListener("wheel", function (e) {
        e.preventDefault()
    })
}

export const addOne = (value) => {
    value = Number(value)
    return ++value
}

export const subtractOne = (value) => {
    value = Number(value)
    return value > 1 ? --value : value
}

export const getTotal = (array, item) => {
    let total = 0
    array.forEach(i => {
        total = total + Number(item(i))
    })
    return total
}

export const discountPrice = (price, discount, mode) => {
    price = Number(price)
    discount = Number(discount)
    if (mode === 'percent') return price - ((discount / 100) * price)
    else if (mode === 'amount') return price - discount
}

export const discountValue = (price, discount, mode) => {
    let discountValue = 0
    if (discount && discount !== '') {
        price = Number(price)
        discount = Number(discount)
        if (mode === 'percent') {
            discountValue = (discount / 100) * price
            discountValue = Math.floor(discountValue)
        } else if (mode === 'amount') return Number(discount)
    }
    return Number(discountValue)
}

export const groupBy = (list, keyGetter) => {
    const map = new Map()
    list.forEach((item) => {
        const key = keyGetter(item)
        const collection = map.get(key)
        if (!collection) map.set(key, [item])
        else collection.push(item)
    })
    return map
}

export const today = () => {
    const date = new Date()
    let today = []
    today[2] = String(date.getDate()).padStart(2, '0')
    today[1] = String(date.getMonth() + 1).padStart(2, '0')
    today[0] = date.getFullYear()
    return today.join('-')
}

export const isEmptyObj = (variable) => {
    if (variable && typeof variable === 'object' && Object.entries(variable).length === 0) return true
    return false
}

export const ratingCount = (ratingAndReview, rating) => {
    if (ratingAndReview && ratingAndReview.length > 0) {
        let star = 0
        ratingAndReview.map(rr => rr.rating).forEach(element => {
            if (Number(element) === rating) star = star + 1
        })
        return star
    }
    return 0
}

export const ratingAvg = (ratingAndReview, rating) => {
    if (ratingAndReview && ratingAndReview.length > 0) {
        let starCount = 0
        let totalCount = 0
        ratingAndReview.map(rr => rr.rating).forEach(element => {
            if (Number(element) !== 0) {
                if (Number(element) === rating) starCount = starCount + 1
                totalCount = totalCount + 1
            }
        })
        return starCount > 0 ? ((starCount / totalCount) * 100).toFixed(1) : 0
    }
    return 0
}

export const totalRatingAvg = (ratingAndReview) => {
    if (ratingAndReview && ratingAndReview.length > 0) {
        let totalCount = 0
        let totalRating = 0
        ratingAndReview.map(rr => rr.rating).forEach(element => {
            if (Number(element) !== 0) {
                totalCount = totalCount + 1
                totalRating = totalRating + Number(element)
            }
        })
        return (totalCount !== 0 && totalRating !== 0) ? (totalRating / totalCount).toFixed(1) : 0
    }
    return 0
}

export const getCustomer = () => getFromLocalStorage(CUSTOMER, {})
export const getAdmin = () => getFromLocalStorage(ADMIN, {})
export const getSuperAdmin = () => getFromLocalStorage(SUPER_ADMIN, {})
export const getCheckout = () => getFromLocalStorage(CHECKOUT, {})

export const getCustomerToken = () => getFromLocalStorage(CUSTOMER_TOKEN, '')
export const getAdminToken = () => getFromLocalStorage(ADMIN_TOKEN, '')
export const getSuperAdminToken = () => getFromLocalStorage(SUPER_ADMIN_TOKEN, '')

export const removeFromLocalStorage = (string) => localStorage.removeItem(string)
export const setToLocalStorage = (key, value) => localStorage.setItem(key, JSON.stringify(value))

export const getCart = () => getFromLocalStorage(CART, [])
export const getServiceCart = () => getFromLocalStorage(SERVICE_CART, [])

export const plusOrMinus = (string) => String(string) === 'plus' ? <>&#43;</> : String(string) === 'minus' ? <>&#8722;</> : ''
export const mode = (mode, value) => String(mode) === 'percent' ? <>{value}&#37;</> : String(mode) === 'amount' ? <>&#8377;{value}</> : ''
export const formateDate = (date, options = { year: 'numeric', month: 'short', day: '2-digit', hour: '2-digit', minute: '2-digit' }) => new Intl.DateTimeFormat('en-US', options).format(new Date(date))
export const validateEmail = (email) => /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(String(email).toLowerCase())
export const validatePhoneNumber = (number) => /^[6-9]{1}[0-9]{9}$/.test(String(number))
export const validatePincode = (pin) => /^[1-9][0-9]{5}$/.test(String(pin))
export const isNum = (number) => /^[0-9]+$/.test(number)
export const setCart = (cart) => localStorage.setItem('cart', JSON.stringify(cart))
export const emptyCart = () => {
    localStorage.removeItem(CART)
    localStorage.removeItem(CHECKOUT)
}

export const setServiceCart = (cart) => localStorage.setItem(SERVICE_CART, JSON.stringify(cart))
export const emptyServiceCart = () => {
    localStorage.removeItem(SERVICE_CART)
    // localStorage.removeItem(CHECKOUT)
}

export const stateList = ["Andaman and Nicobar Islands", "Andhra Pradesh", "Arunachal Pradesh", "Assam", "Bihar", "Chandigarh", "Chhattisgarh", "Dadra and Nagar Haveli and Daman and Diu", "Delhi", "Goa", "Gujarat", "Haryana", "Himachal Pradesh", "Jammu and Kashmir", "Jharkhand", "Karnataka", "Kerala", "Ladakh", "Lakshadweep", "Madhya Pradesh", "Maharashtra", "Manipur", "Meghalaya", "Mizoram", "Nagaland", "Odisha", "Puducherry", "Punjab", "Rajasthan", "Sikkim", "Tamil Nadu", "Telangana", "Tripura", "Uttar Pradesh", "Uttarakhand", "West Bengal"]
export const now = () => new Date().getTime()
export const req = (string) => String(string) + ' required'
export const tooLong = (string) => String(string) + ' is too long'
export const getTimeStamp = (date) => new Date(String(date)).getTime()
export const getCurrentTimeStamp = () => new Date().getTime()
export const diffDays = (fromDate, toDate) => {
    fromDate = getTimeStamp(formateDate(fromDate))
    toDate = getTimeStamp(formateDate(toDate))
    return Number(Math.round((fromDate - toDate) / (1000 * 3600 * 24)))
}
export const diffDaysFromNow = (date) => {
    date = getTimeStamp(formateDate(date))
    let now = getCurrentTimeStamp()
    return Number(Math.round((now - date) / (1000 * 3600 * 24)))
}

const getFromLocalStorage = (string, dataType) => {
    const data = localStorage.getItem(string)
    return data ? JSON.parse(data) : dataType
}