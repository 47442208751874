import React, { useState } from "react";
import AdminTopNavBar from "../common/AdminTopNavBar";
import '../../styles/Admin.css';
import AdminFooter from "../common/AdminFooter";
import AdminSideBar from "./AdminSideBar";
import { getAdmin } from "../../include/function";
import getUserMenuItems from "./AdminMenuContent";

const AdminHeader = ({ children }) => {
    const user = getAdmin();
    const homeLink = '/admin';
    const [isSidebarMinimized, setIsSidebarMinimized] = useState(false);

    const toggleSidebar = () => setIsSidebarMinimized(!isSidebarMinimized);

    let menuContent = getUserMenuItems()

    return (
        <>
            <AdminSideBar menuContent={menuContent} isMinimized={isSidebarMinimized} />
            <AdminTopNavBar toggleSidebar={toggleSidebar} isSidebarMinimized={isSidebarMinimized} homeLink={homeLink} />
            <div className="content-wrapper p-0" style={{ marginLeft: isSidebarMinimized ? "80px" : "250px", flex: "1" }}>
                <section className="content">
                    {children} 
                </section>
            </div>
            <AdminFooter isSidebarMinimized={isSidebarMinimized} />
        </>
    );
};

export default AdminHeader;
