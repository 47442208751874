import React, { useEffect, useState } from 'react';
import * as Feather from 'react-feather';
import { api, superAdminReqConfig } from '../../include/api';
import '../../styles/Flyout.css'; // For styling

const Flyout = ({ isVisible, onClose, children, orderId, customerId, shippingId }) => {

    const [orderData, setOrderData] = useState([])
    const [customerData, setCustomerData] = useState([])
    const [shippingData, setShippingData] = useState([])

    useEffect(() => {
        if (orderId) {
            api.get(`/orders/?id=${orderId}`, superAdminReqConfig()).then(response => {
                if (response.status === 200) {
                    setOrderData(response.data.data)
                    // if (afterGet) afterGet()
                }
            }).catch(error => {
                setOrderData([])
            }).finally()
        }
    }, [orderId])

    useEffect(() => {
        if (customerId) {
            api.get(`/customer/?id=${customerId}`, superAdminReqConfig()).then(response => {
                if (response.status === 200) {
                    setCustomerData(response.data.data)
                    // if (afterGet) afterGet()
                }
            }).catch(error => {
                setCustomerData([])
            }).finally()
        }
    }, [customerId])

    useEffect(() => {
        if (shippingId) {
            api.get(`/shipping/?id=${shippingId}`, superAdminReqConfig()).then(response => {
                if (response.status === 200) {
                    setShippingData(response.data.data)
                    // if (afterGet) afterGet()
                }
            }).catch(error => {
                setShippingData([])
            }).finally()
        }
    }, [shippingId])

    const handleClose = () => {
        onClose()
        setOrderData([])
        setCustomerData([])
        setShippingData([])
    }

    return (
        <div className={`flyout-container ${isVisible ? 'visible' : 'hidden'}`}>
            <div className="flyout-content">
                <button className="close-btn" onClick={handleClose}>Close <i><Feather.XSquare /></i></button>
                {children}
                {orderData?.length > 0 && <div className='overflow-auto'>
                    <h4 className="my-2">Order Details</h4>
                    <div className="card mt-2 overflow-auto">
                        {/* <label className="m-1">Order Id: {orderData[0]?.id}</label>
                        <label className="m-1">Order Date&Time: {orderData[0]?.createTimestamp}</label>
                        <label className="m-1">Customer: {orderData[0]?.name}</label>
                        <label className="m-1">Status: {orderData[0]?.status}</label>
                        <label className="m-1">Payment Method: {orderData[0]?.paymentMode}</label>
                        <label className="m-1">Total Items: {orderData[0]?.totalItems}</label>
                        <label className="m-1">Total Discount: {orderData[0]?.totalDiscount}</label>
                        <label className="m-1">Total Delivery Charge: {orderData[0]?.totalDeliveryCharge}</label>
                        <label className="m-1">Total Amount: {orderData[0]?.totalAmount}</label> */}
                        <table>
                            <tbody>
                                <tr>
                                    <td className="m-1">Order Id:</td>
                                    <td className="m-1">{orderData[0]?.id}</td>
                                </tr>
                                <tr>
                                    <td className="m-1">Order Date&Time:</td>
                                    <td className="m-1">{orderData[0]?.createTimestamp}</td>
                                </tr>
                                <tr>
                                    <td className="m-1">Customer:</td>
                                    <td className="m-1">{orderData[0]?.name}</td>
                                </tr>
                                {/* <tr>
                                    <td className="m-1">Status:</td>
                                    <td className="m-1">{orderData[0]?.status}</td>
                                </tr>
                                <tr>
                                    <td className="m-1">Payment Method:</td>
                                    <td className="m-1">{orderData[0]?.paymentMode}</td>
                                </tr> */}
                                <tr>
                                    <td className="m-1">Total Items:</td>
                                    <td className="m-1">{orderData[0]?.totalItems}</td>
                                </tr>
                                <tr>
                                    <td className="m-1">Total Discount:</td>
                                    <td className="m-1">{orderData[0]?.totalDiscount}</td>
                                </tr>
                                <tr>
                                    <td className="m-1">Total Delivery Charge:</td>
                                    <td className="m-1">{orderData[0]?.totalDeliveryCharge}</td>
                                </tr>
                                <tr>
                                    <td className="m-1">Total Amount:</td>
                                    <td className="m-1">{orderData[0]?.totalAmount}</td>
                                </tr>
                            </tbody>
                        </table>


                        <div className='mt-2'>
                            <h5 className='text-uppercase'>Customer Details:</h5>
                            <label className="m-1">Name: {orderData[0]?.name}</label>
                            <label className="m-1">Email Id: {orderData[0]?.emailId}</label>

                            <label className="m-1">Address: {orderData[0]?.address || ''},<br />{orderData[0]?.city || ''},<br />{orderData[0]?.state || ''},<br />{orderData[0]?.phoneNumber || ''}<br /></label>
                            <label className="m-1">Landmark: {orderData[0]?.landmark}</label>
                            <label className="m-1">Locality: {orderData[0]?.locality}</label>
                            <label className="m-1">Pincode: {orderData[0]?.pin}</label>

                        </div>
                        <h5 className='text-uppercase'>Product Details:</h5>

                        {orderData[0].orderItems.length > 0 && orderData[0].orderItems.map((items, i) => {
                            return <div className='mt-2'>
                                <h4 className='text-uppercase'>{items.ProductName}</h4>
                                <img src={items.mainImage} alt='product img' style={{ width: '40px', height: '40px' }}></img>
                                <label className="m-1">Address: {items?.address || ''},<br />{items.city || ''},<br />{items.state || ''},<br />{items.phoneNumber || ''}<br /></label>
                                <label className="m-1">Qty: {items.qty}</label>
                                <label className="m-1">Price: {items.price}</label>
                                <label className="m-1">Status: {items.status}</label>

                            </div>
                        })}
                    </div>
                </div>
                }

                {customerData?.length > 0 && <div>
                    <h4 className="my-2 ">Customer Details</h4>
                    <div className="card mt-2">
                        <label className="m-1">Customer Name: {customerData[0]?.name}</label>

                        <label className="m-1">Email Id: {customerData[0]?.emailId || ''}</label>
                        <label className="m-1">Phone Number : {customerData[0]?.phoneNumber || ''}</label>
                        <label className="m-1">Loyal Points : 5</label>
                        <label className="m-1">Status : {customerData[0]?.status}</label>
                        {customerData[0].addressList.length > 0 && customerData[0].addressList.map((add, i) => {
                            return <div className='mt-2'>
                                <h4 className='text-uppercase'>{add.addressType}</h4>
                                <label className="m-1">Address: {add?.address || ''},<br />{add.city || ''},<br />{add.state || ''},<br />{add.phoneNumber || ''}<br /></label>
                            </div>
                        })}
                    </div>
                </div>}

            </div>
        </div>
    );
};

export default Flyout;
