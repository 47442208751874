import React from "react"
import { superAdminReqConfig } from "../../include/api"
import Login from "../common/Login"

const SuperAdminLogin = ({ handleSuperAdmin }) => {
    const apiUrl = '/super/'
    const reqConfig = superAdminReqConfig()
    return <Login
        handleUser={handleSuperAdmin}
        apiUrl={apiUrl}
        reqConfig={reqConfig}
    />
}

export default SuperAdminLogin