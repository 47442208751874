import { useState } from "react"
import { preventClick } from "../../include/function"
import Spinner from "./Spinner"

const YesOrNo = ({ zIndex, handleYes, handleNo, headerText, yesBtnText, loading }) => {
    const [popDown, setPopDown] = useState(false)

    const yes = () => handleYes()

    const no = () => {
        setPopDown(true)
        setTimeout(() => {
            handleNo()
        }, 100);
    }

    return <div className="position-fixed start-0 top-0 vh-100 w-100 d-flex align-items-center justify-content-center p-3" style={{ zIndex: zIndex, backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
        <div className={`bg-white p-3 rounded border border-1 shadow-sm ${popDown ? 'pop-down' : 'pop-up'}`}>
            <h6 className="text-center mb-5">{headerText}</h6>
            <div className="d-flex align items-center justify-content-center">
                {loading ?
                    <button className="btn btn-primary btn-lg mx-2 d-flex align-items-center justify-content-center" onClick={e => e.preventDefault()}>
                        <Spinner spinnerSize="sm" color="light" />
                        <span className="ms-3">{yesBtnText}</span>
                    </button> :
                    <button className="btn btn-primary btn-lg mx-2" onClick={e => preventClick(e, yes)}>{yesBtnText}</button>}
                <button className="btn btn-light btn-lg mx-2" onClick={e => preventClick(e, no)}>Cancel</button>
            </div>
        </div>
    </div>
}

export default YesOrNo