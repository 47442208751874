import { useState, useEffect } from "react";
import "../../styles/Super.css";
import SuperAdminHeader from "./Header";
import { api, superAdminReqConfig } from "../../include/api";

const CustomerLoyaltyPoints = () => {
    const [loyaltyData, setLoyaltyData] = useState([]);
    
    const [fetchLoading, setFetchLoading] = useState(false);

    const getCustomerPoints = () => {
        setFetchLoading(true); 
        api.get('/customer_points/', superAdminReqConfig())
            .then(response => {
                if (response.status === 200 && response.data.data) {
                    setLoyaltyData(response.data.data);
                }
            })
            .catch(error => {
                console.error("Error fetching customer points:", error);
                setLoyaltyData([]);
            })
            .finally(() => {
                setFetchLoading(false);
            });
    };

    useEffect(() => {
        getCustomerPoints();
    }, []);

    return (
        <SuperAdminHeader>
            <div className="fixed-top-bar">
                <div className="card">
                    <h5 className="m-2">Customer Loyalty Points</h5>
                    <div className="card-body">
                        {fetchLoading ? (
                            <p>Loading...</p>
                        ) : (
                            <div className="table-wrapper">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>S.No</th>
                                            <th>Customer Name</th>
                                            <th>Total Points</th>
                                            <th>Point History</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {loyaltyData.length > 0 ? (
                                            loyaltyData.map((customer, index) => (
                                                <tr key={customer.customerId}>
                                                    <td>{index + 1}</td>
                                                    <td>{customer.customerName}</td>
                                                    <td>{customer.totalPoints}</td>
                                                    <td>
                                                        <table className="table table-bordered">
                                                            <thead>
                                                                <tr>
                                                                    <th>Date</th>
                                                                    <th>Points Earned</th>
                                                                    {/* <th>Reason</th> */}
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {customer.pointHistory.map((entry, idx) => (
                                                                    <tr key={idx}>
                                                                        <td className="h-50">{entry.date}</td>
                                                                        <td className="h-50">{entry.pointsEarned}</td>
                                                                        {/* <td className="h-50">{entry.reason}</td> */}
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </table>
                                                    </td>
                                                </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <td colSpan="4">No data available</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </SuperAdminHeader>
    );
};

export default CustomerLoyaltyPoints;
