import { React, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import appVersion from "../common/AppVersion.json";

const SuperSideBar = ({ menuContent, isMinimized }) => {
    const location = useLocation();

    return (
        <aside
            className={`overflow-auto main-sidebar sidebar-light-primary ${isMinimized ? "minimized" : ""}`}
            style={{ width: isMinimized ? "80px" : "250px", position: "fixed", height: "100vh" }}
        >
            <div className="sidebar">
                <div className="user-panel mt-3 pb-3 mb-2 d-flex justify-content-center align-items-middle" style={{ height: "49px" }}>
                    <div className="image">
                        <img src={process.env.PUBLIC_URL + "/img/profile.png"} className="img-circle elevation-2" alt="User" style={{ width: "50px", height: "50px" }} />
                    </div>
                    {!isMinimized && (
                        <div className="info" style={{ margin: "10px 0 10px 0" }}>
                            {/* <Link to="#" className="d-block text-light ms-3">
                                MY SHOP
                            </Link> */}
                            <Link to="#" className="d-block text-light ms-3">
                                BUILDTON
                            </Link>
                        </div>
                    )}
                </div>
                <hr style={{ height: '3px', border: 'none', backgroundColor: "white", margin: "0px" }} />

                <nav className="mt-2">
                    <ul
                        className={`nav nav-sidebar flex-column text-light ${isMinimized ? "minimized-text" : ""}`}
                        data-widget="treeview"
                        data-accordion="false"
                    >
                        {menuContent?.map((menuItem) => {
                            const { Icon } = menuItem;
                            const isActive = location.pathname === menuItem.url;

                            return (
                                <div key={menuItem.key}>
                                    {menuItem.children ? (
                                        <MenuContent
                                            menuItem={menuItem}
                                            isMinimized={isMinimized}
                                            location={location}
                                        />
                                    ) : (
                                        <li className="nav-item">
                                            <Link to={menuItem.url || ''} className={`nav-link text-light d-flex ${isActive ? 'active' : ''}`} style={{ paddingTop: "18px", fontSize: "18px" }}>
                                                {Icon && <Icon style={{ marginRight: isMinimized ? "0px" : "5px" }} />}
                                                {!isMinimized && <p>{menuItem.text}</p>}
                                            </Link>
                                        </li>
                                    )}
                                </div>
                            );
                        })}
                    </ul>
                </nav>
                <footer className="border-top border-primary">
                    <p className="text-center text-light mt-2 mb-2">App version {appVersion.version}</p>
                </footer>
            </div>
        </aside>
    );
};

const MenuContent = ({ menuItem, isMinimized, location }) => {
    const Icon = menuItem.Icon;
    const [showSubMenu, setShowSubMenu] = useState(false);

    useEffect(() => {
        // Check if any child item's URL matches the current path
        if (menuItem.children?.some(child => child.url === location.pathname)) {
            setShowSubMenu(true);
        }
    }, [location.pathname, menuItem.children]);

    const handleToggleMenuClick = () => {
        setShowSubMenu(!showSubMenu);
    };

    const isActive = location.pathname === menuItem.url;

    return (
        <li className="nav-item">
            <Link to={"#"} className={`nav-link text-light d-flex ${isActive ? 'active' : ''}`} onClick={handleToggleMenuClick} style={{ fontSize: "18px" }}>
                <div className="d-flex align-items-center justify-content-between py-0" style={{ width: '100%' }}>
                    <div className="d-flex align-items-center py-0">
                        {Icon && <Icon style={{ marginRight: isMinimized ? "0px" : "10px", marginLeft: isMinimized ? "0px" : "-2px" }} />}
                        {!isMinimized && (
                            <p className="mt-3">
                                {menuItem.text}
                            </p>
                        )}
                    </div>
                    {!isMinimized && (
                        <div className="chevron-icon" style={{ marginLeft: "auto" }}>
                            <i className={`fas ${!showSubMenu ? 'fa-angle-right' : 'fa-angle-down'}`}></i>
                        </div>
                    )}
                </div>
            </Link>
            {!isMinimized && (
                <ul className="nav nav-treeview" style={{ display: showSubMenu ? 'block' : 'none' }}>
                    {menuItem.children?.map((childItem, index2) => {
                        const isChildActive = location.pathname === childItem.url;
                        return (
                            <li className={`nav-item ${isChildActive ? 'active' : ''}`} key={index2}>
                                <Link to={childItem.url || '#'} className={`nav-link text-light d-flex ${isChildActive ? 'active' : ''}`}>
                                    <i className="fas fa-angle-right nav-icon d-flex" style={{ marginLeft: "20px" }}></i>
                                    <p style={{ marginTop: "-4px", marginLeft: "10px" }}>{childItem.text}</p>
                                </Link>
                            </li>
                        );
                    })}
                </ul>
            )}
        </li>
    );
};

export default SuperSideBar;
