import React from "react"
import { ADD } from "../../include/constant"
import ProductForm from "../common/ProductForm"
import SuperAdminHeader from "./Header"

const SuperAdminAddProduct = () => {
    return <SuperAdminHeader >
        <div className="fixed-top-bar">
            <ProductForm
                title="Add Product"
                type={ADD}
                from='super'
            />
        </div>
    </SuperAdminHeader>
}

export default SuperAdminAddProduct