import React from "react"
import { adminReqConfig } from "../../include/api"
import { getAdmin } from "../../include/function"
import ChangePassword from "../common/ChangePassword"

const AdminChangePassword = () => {
    const user = getAdmin()
    const apiUrl = '/shop/?id=' + user.id + '&flag=changepassword'
    const reqConfig = adminReqConfig()

    return <ChangePassword
        apiUrl={apiUrl}
        reqConfig={reqConfig}
    />
}

export default AdminChangePassword