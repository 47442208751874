import React from "react"
import { adminReqConfig } from "../../include/api"
import Login from "../common/Login"

const AdminLogin = ({ handleAdmin }) => {
    const apiUrl = '/shop/login/'
    const reqConfig = adminReqConfig()
    return <Login
        handleUser={handleAdmin}
        apiUrl={apiUrl}
        reqConfig={reqConfig}
    />
}

export default AdminLogin