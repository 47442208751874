import { nanoid } from "nanoid"
import React, { useCallback, useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { api, baseURL, customerReqConfig } from "../../include/api"
import { getCustomer } from "../../include/function"
import Error from "../add-ons/Error"
import Spinner from "../add-ons/Spinner"
import CustomerHeader from "./Header"

const CustomerOrders = ({ from }) => {
    const user = getCustomer()

    const [loading, setLoading] = useState(true)
    const [orders, setOrders] = useState([])
    const [serviceOrders, setServiceOrders] = useState([])

    const getOrders = useCallback(() => {
        setLoading(true)
        api.get('/orders/?customerId=' + user.id, customerReqConfig()).then(response => {
            if (response.status === 200) {
                setOrders(response.data.data)
            }
        }).catch(error => {
            setOrders([])
        }).finally(() => setLoading(false))
    }, [user.id])

    const getServiceOrders = useCallback(() => {
        setLoading(true)
        api.get('/service_orders/?customerId=' + user.id, customerReqConfig()).then(response => {
            if (response.status === 200) {
                setServiceOrders(response.data.data)
            }
        }).catch(error => {
            setServiceOrders([])
        }).finally(() => setLoading(false))
    }, [user.id])

    useEffect(() => {
        getOrders()
        getServiceOrders()
    }, [getOrders, getServiceOrders])

    return (
        <div className={from === "orders" ? "fixed-top-bar" : ""}>
            {from === "orders" && <CustomerHeader enableSearch enableButtons />}
            <div className="container my-3">
                <h5 className="mb-3">Orders</h5>
                {loading ? (
                    <div className="py-5"><Spinner color="danger" /></div>
                ) : (
                    <div className="row">
                        {orders?.length > 0 ? (
                            orders?.map(order => (
                                <OrderCard key={order.id} order={order} fromWhere='product' />
                            ))
                        ) : (
                            <Error mainText="No orders found!" />
                        )}
                    </div>
                )}
            </div>
            <hr></hr>
            <div className="container my-3">
                <h5 className="mb-3">Service Orders</h5>
                {loading ? (
                    <div className="py-5"><Spinner color="danger" /></div>
                ) : (
                    <div className="row">
                        {serviceOrders?.length > 0 ? (
                            serviceOrders?.map(order => (
                                <OrderCard key={order.id} order={order} fromWhere='service' />
                            ))
                        ) : (
                            <Error mainText="No service orders found!" />
                        )}
                    </div>
                )}
            </div>
        </div>
    )
}

const OrderCard = ({ order, fromWhere }) => {
    const formatDate = (timestamp) => {
        const date = new Date(timestamp)
        const day = String(date.getDate()).padStart(2, '0')
        const month = String(date.getMonth() + 1).padStart(2, '0')
        const year = date.getFullYear()

        return `${day}-${month}-${year}`
    }

    return (
        <div className="col-12 col-md-6 col-lg-4">
            <div className="card mb-4">
                <div className="card-header">
                    <h6>Orders Info</h6>
                    <p className="mb-0 text-muted">Placed on {formatDate(order.createTimestamp)}</p>
                </div>
                <div className="card-body">
                    {order.orderItems.map(item => (
                        <OrderItem key={nanoid()} item={item} fromWhere={fromWhere} />
                    ))}
                </div>
                <div className="card-footer">
                    <strong>Total Price: &#8377;{order.totalAmount}</strong>
                </div>
            </div>
        </div>
    )
}

const OrderItem = ({ item, fromWhere }) => {
    return (
        <Link to={fromWhere === 'service' ? "/service-order-details/" + item.id : "/order-details/" + item.id} className="d-block p-2 border-bottom">
            <div className="d-flex align-items-center">
                <img
                    className="me-3"
                    style={{ width: '50px', height: '50px', objectFit: 'contain' }}
                    src={item.mainImage && baseURL + '/' + item.mainImage}
                    alt={item.productName}
                />
                <div className="flex-grow-1" style={{ overflow: 'hidden' }}>
                    <h6
                        className="mb-1 text-truncate"
                        style={{
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis'
                        }}
                    >
                        {item.productName}
                    </h6>
                    <span className="text-muted small">
                        &#8377;{fromWhere === 'service'
                            ? Math.ceil(item.amount)
                            : Math.ceil(item.amount + (item.deliveryCharge))
                        }

                    </span>
                </div>
                <div className="text-end">
                    <span className={`badge  bg-success`}>
                        {item.status}
                    </span>
                </div>
            </div>
        </Link>
    )
}


export default CustomerOrders