import { nanoid } from "nanoid"
import React, { useCallback, useEffect, useState } from "react"
import * as Feather from "react-feather"
import { Link, useNavigate, useParams } from "react-router-dom"
import { api, baseURL, customerReqConfig } from "../../include/api"
import { CHECKOUT, ERROR, SUCCESS } from "../../include/constant"
import { discountPrice, getCart, getCustomer, isEmptyObj, ratingAvg, ratingCount, setToLocalStorage } from "../../include/function"
import { useNotification } from "../../Toast/ToastProvider"
import Spinner from "../add-ons/Spinner"
import { useCart } from "../context/UserCartCountContext"
import CustomerHeader from "./Header"

const CustomerProduct = () => {
    const { id } = useParams()
    const toast = useNotification()
    const navigate = useNavigate()
    const { fetchCartCount } = useCart(); // Get totalCartItems from context

    const [localCart, setLocalCart] = useState(getCart())
    const [fetchLoading, setFetchLoading] = useState(true)
    const [product, setProduct] = useState({})
    const [selectedImage, setSelectedImage] = useState('')
    const [images, setImages] = useState([])
    const [cartData, setCartData] = useState([])
    const [wishlistData, setWishlistData] = useState({})

    const customer = getCustomer();

    const getProduct = useCallback(() => {
        setFetchLoading(true)
        api.get('/product/?id=' + id, customerReqConfig()).then(response => {
            if (response.status === 200) {
                setProduct(response.data.data[0])
            }
        }).catch(error => {
            setProduct([])
        }).finally(() => setFetchLoading(false))
    }, [id])

    const fetchcart = () => {
        if (customer) {
            api.get('/cart/?customerId=' + customer.id, customerReqConfig()).then(response => {
                if (response.status === 200) {
                    setCartData(response.data.data)
                }
            }).catch(error => {
                setCartData([])
            }).finally()
        } else {
            setCartData([])
        }
    }

    const getWishlist = () => {
        setFetchLoading(true)
        api.get('/wishlist/?customerId=' + customer.id, customerReqConfig()).then(response => {
            if (response.status === 200) {
                setWishlistData(response.data.data)
            }
        }).catch(error => {
            setWishlistData({})
        }).finally(() => setFetchLoading(false))
    }

    useEffect(() => {
        fetchcart()
        getWishlist()
    }, [])

    const isInCart = () => !isEmptyObj(customer)
        ? cartData && cartData[0] ? cartData[0].cartItems?.filter(c => String(c.productId) === String(id)).length > 0 : false
        : localCart.filter(c => String(c.id) === String(id)).length > 0
    // const isInCart = () => localCart.filter(c => String(c.id) === String(id)).length > 0

    console.log(cartData);
    console.log(product);
    // console.log(isInCart());

    const handleCheckout = async () => {
        // console.log(cartData);
        let data = []
        await api.get('/cart/?customerId=' + customer.id, customerReqConfig()).then(response => {
            if (response.status === 200) {
                const result = response.data.data
                data = result
            }
        }).catch(error => {
        })
        console.log(data);

        // setPlaceOrderLoading(true)
        if (data && data[0]?.cartItems) {
            const activeOrderItems = data[0]?.cartItems
                .filter(oi => oi.status === 'active' && oi.shopStatus === 'active')
                .map(m => {
                    let productsDisValue = 0;

                    if (m.discountMode === 'percent') {
                        // Calculating discount amount for given percent using price
                        productsDisValue += (parseFloat(m.discountValue) / 100) * parseFloat(m.price);
                    } else if (m.discountMode === 'amount') {
                        productsDisValue += parseFloat(m.discountValue);
                    }

                    return {
                        ...m,
                        amount: (parseFloat(m.price) - productsDisValue)
                    };
                });

            console.log(activeOrderItems);

            let totalItemsPrice = activeOrderItems.reduce((prev, curr) => {
                return prev + (curr.price ? parseFloat(curr.price) : 0)
            }, 0)
            let totalDiscount = activeOrderItems.reduce((prev, curr) => {
                let productsDisValue = 0;

                if (curr.discountMode === 'percent') {
                    // Calculating discount amount for given percent using price
                    productsDisValue += (parseFloat(curr.discountValue) / 100) * parseFloat(curr.price);
                } else if (curr.discountMode === 'amount') {
                    productsDisValue += parseFloat(curr.discountValue);
                }
                return prev + parseFloat(productsDisValue)
            }, 0)
            let totalDeliveryCharge = activeOrderItems.reduce((prev, curr) => {
                return prev + (curr.price ? parseFloat(curr.deliveryCharge) : 0)
            }, 0)
            let totalAmount = totalItemsPrice - totalDiscount + totalDeliveryCharge
            let totalItems = activeOrderItems.length
            const checkout = {
                billAmount: totalItemsPrice,
                totalDiscount: totalDiscount,
                totalDeliveryCharge: totalDeliveryCharge,
                totalAmount: totalAmount,
                totalItems: totalItems,
                orderItems: activeOrderItems
            }
            console.log(checkout);

            await setToLocalStorage(CHECKOUT, checkout)
            setTimeout(() => {
                navigate('/checkout/product')
            }, 2000)
        }

    }

    const handleAddToCart = (e, from) => {

        if (!isEmptyObj(customer)) {
            if (cartData.length > 0 && !isInCart()) {
                //cart exist new item adding
                console.log('innn');
                let productsDisValue = 0;
                if (product.discountMode === 'percent') {
                    //calculating dis amt for given percent using price
                    productsDisValue += parseFloat(product.discountValue) / 100 * parseFloat(product.price)
                } else if (product.discountMode === 'amount') {
                    productsDisValue += parseFloat(product.discountValue)
                }

                let itemsPrice = (cartData[0].itemsPrice ? parseFloat(cartData[0].itemsPrice) : 0) + (product.price ? parseFloat(product.price) : 0);
                let totalDiscountValue = (cartData[0].totalDiscountValue ? parseFloat(cartData[0].totalDiscountValue) : 0) + productsDisValue;
                let totalDeliveryCharge = (cartData[0].totalDeliveryCharge ? parseFloat(cartData[0].totalDeliveryCharge) : 0) + (product.deliveryCharge ? parseFloat(product.deliveryCharge) : 0);

                const data = {
                    customerId: cartData[0].customerId,
                    itemsPrice: itemsPrice,
                    totalDiscountValue: totalDiscountValue,
                    discountMode: product.discountMode ? product.discountMode : cartData[0].discountMode,
                    totalDeliveryCharge: totalDeliveryCharge,
                    totalPrice: itemsPrice - totalDiscountValue + totalDeliveryCharge,
                    // totalPrice: (cartData[0].totalPrice ? parseFloat(cartData[0].totalPrice) : 0) + (product.price ? parseFloat(product.price) : 0) - (product.discountValue) + (product.deliveryCharge),
                    cartItems: [
                        ...cartData[0].cartItems,
                        {
                            productId: product.id,
                            qty: 1,
                            price: product.price,
                            discountMode: product.discountMode,
                            discountValue: product.discountValue,
                            deliveryCharge: product.deliveryCharge,
                        }

                    ]
                }
                //
                api.put('/cart/?id=' + cartData[0].id, data, customerReqConfig()).then(response => {
                    if (response.status === 200) {
                        toast({ type: SUCCESS, message: response.data.message })
                        fetchcart()
                        // setTimeout(() => {
                        //     window.location.reload();
                        // }, 3000); // 3000 ms = 3 seconds delay
                        fetchCartCount()
                        if (from === 'buynow') handleCheckout()
                    }
                }).catch(error => {
                    error.response ?
                        toast({ type: ERROR, message: error.response.data.message }) :
                        toast({ type: ERROR, message: error.message })
                }).finally()
            } else if (cartData.length > 0 && isInCart()) {
                //cart exist, item also exist qty increase

                let productsDisValue = 0;
                if (product.discountMode === 'percent') {
                    //calculating dis amt for given percent using price
                    productsDisValue += parseFloat(product.discountValue) / 100 * parseFloat(product.price)
                } else if (product.discountMode === 'amount') {
                    productsDisValue += parseFloat(product.discountValue)
                }

                let cartItemIndex = cartData[0].cartItems.findIndex(c => String(c.productId) === String(product.productId))
                cartData[0].cartItems[cartItemIndex].qty += 1;

                let itemsPrice = (cartData[0].itemsPrice ? parseFloat(cartData[0].itemsPrice) : 0) + (product.price ? parseFloat(product.price) : 0);
                let totalDiscountValue = (cartData[0].totalDiscountValue ? parseFloat(cartData[0].totalDiscountValue) : 0) + productsDisValue;
                let totalDeliveryCharge = (cartData[0].totalDeliveryCharge ? parseFloat(cartData[0].totalDeliveryCharge) : 0);

                const data = {
                    customerId: cartData[0].customerId,
                    itemsPrice: itemsPrice,
                    totalDiscountValue: totalDiscountValue,
                    discountMode: (product.discountMode ? product.discountMode : cartData[0].discountMode),
                    totalDeliveryCharge: totalDeliveryCharge,
                    totalPrice: itemsPrice - totalDiscountValue + totalDeliveryCharge,
                    cartItems: [
                        ...cartData[0].cartItems,
                    ]
                }
                //
                api.put('/cart/?id=' + cartData[0].id, data, customerReqConfig()).then(response => {
                    if (response.status === 200) {
                        toast({ type: SUCCESS, message: response.data.message })
                        fetchcart()
                        fetchCartCount()
                        if (from === 'buynow') handleCheckout()
                    }
                }).catch(error => {
                    error.response ?
                        toast({ type: ERROR, message: error.response.data.message }) :
                        toast({ type: ERROR, message: error.message })
                }).finally()
            } else if (cartData.length === 0 && !isInCart()) {
                //No cart exist new cart and item adding

                let totalDiscountValue = 0;
                if (product.discountMode === 'percent') {
                    //calculating dis amt for given percent using price
                    totalDiscountValue += parseFloat(product.discountValue) / 100 * parseFloat(product.price)
                } else if (product.discountMode === 'amount') {
                    totalDiscountValue += parseFloat(product.discountValue)
                }

                const data = {
                    customerId: customer ? customer.id : '0',
                    itemsPrice: product.price,
                    totalDiscountValue: totalDiscountValue,
                    discountMode: product.discountMode,
                    totalDeliveryCharge: product.deliveryCharge,
                    totalPrice: parseFloat(product.price) - (product.discountValue ? parseFloat(product.discountValue) : 0) + (product.deliveryCharge ? parseFloat(product.deliveryCharge) : 0),
                    cartItems: [
                        {
                            productId: product.id,
                            qty: 1,
                            price: product.price,
                            discountMode: product.discountMode,
                            discountValue: product.discountValue,
                            deliveryCharge: product.deliveryCharge,
                        }
                    ]
                }
                //
                api.post('/cart/', data, customerReqConfig()).then(response => {
                    if (response.status === 200) {
                        fetchcart()
                        toast({ type: SUCCESS, message: response.data.message })
                        // setOrderPlaced(true)
                        fetchCartCount()
                        if (from === 'buynow') handleCheckout()
                    }
                }).catch(error => {
                    error.response ?
                        toast({ type: ERROR, message: error.response.data.message }) :
                        toast({ type: ERROR, message: error.message })
                }).finally()
            }
        } else if (isEmptyObj(customer)) {
            navigate('/login')

            // if (!isInCart()) {
            //     let cartItem = {
            //         id: id,
            //         qty: 1,
            //         // productId: id,
            //         price: product.price,
            //         discountMode: product.discountMode,
            //         discountValue: product.discountValue,
            //         deliveryCharge: product.deliveryCharge,
            //     }
            //     let cart = [...localCart, cartItem]
            //     setLocalCart(cart)
            //     setCart(cart)//local storage 
            // }
        }

    }

    const IsProductWishlisted = wishlistData?.products?.find(f => String(f.id) === String(id))

    const wishListHandler = () => {
        if (!IsProductWishlisted) {

            const data = {
                customerId: customer.id,
                productId: id
            }

            api.post('/wishlist/', data, customerReqConfig()).then(response => {
                if (response.status === 200) {
                    getWishlist()
                    toast({ type: SUCCESS, message: response.data.message })
                    // setOrderPlaced(true)
                }
            }).catch(error => {
                error.response ?
                    toast({ type: ERROR, message: error.response.data.message }) :
                    toast({ type: ERROR, message: error.message })
            }).finally()
        } else {

            toast({ type: SUCCESS, message: 'Item already exists in wishlist' })

            // api.delete(`/wishlist/?customerId=${customer.id}&productId=${product.productId}`, customerReqConfig()).then(response => {
            //     if (response.status === 200) {
            //         getWishlist()
            //         toast({ type: SUCCESS, message: response.data.message })
            //         // setOrderPlaced(true)
            //     }
            // }).catch(error => {
            //     error.response ?
            //         toast({ type: ERROR, message: error.response.data.message }) :
            //         toast({ type: ERROR, message: error.message })
            // })
        }

    }

    useEffect(() => {
        getProduct()
    }, [getProduct])

    useEffect(() => {
        if (!isEmptyObj(product)) {
            setImages(product.images ? [product.mainImage, ...product.images.map(image => image.imageUrl)] : [product.mainImage])
        }
    }, [product])

    return <div className="fixed-top-bar overflow-hidden">
        <CustomerHeader enableSearch enableButtons />
        <div className="container my-3">
            {fetchLoading ? <div className="py-5"><Spinner color="danger" /></div> :
                <div className="row">
                    <div className="col-12 col-xl-4 my-2">
                        <div className="w-100 border border-1 d-flex justify-content-between flex-column">
                            <div className="text-end m-2">
                                {/* Share Icon */}
                                <i
                                    className="fa fa-share-alt cursor-pointer"
                                    onClick={() => {
                                        const productLink = window.location.href; // Get the current page URL
                                        navigator.clipboard.writeText(productLink); // Copy to clipboard
                                        alert('Product link copied to clipboard!'); // Optional: show an alert or custom toast
                                    }}
                                    style={{ fontSize: '17px', color: '#555' }}
                                ></i>
                            </div>
                            <div className="p-3">
                                <div className="row">
                                    <div className="col-3">
                                        {images.map(image => {
                                            const src = baseURL + '/' + image;
                                            return (
                                                <div className="box-sm border border-1 my-2 cursor-pointer hover-border overflow-hidden p-1 scale"
                                                    onMouseEnter={e => setSelectedImage(src)}
                                                    key={nanoid()}>
                                                    <img src={src} className="w-100 h-100 fit-contain" alt="" />
                                                </div>
                                            );
                                        })}
                                    </div>
                                    <div className="col-9">
                                        <img
                                            src={selectedImage !== '' ? selectedImage : baseURL + '/' + product.mainImage}
                                            className="w-100 fit-contain"
                                            alt=""
                                            style={{ minHeight: '50px', maxHeight: '500px' }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="m-2 d-flex justify-content-between align-items-center">
                                <div>
                                    <i><Feather.Heart size={16} strokeWidth={1.6} /></i>
                                    <Link to='#' className="ms-1 text-primary text-decoration-underline" onClick={wishListHandler}>
                                        Add to Wishlist
                                    </Link>
                                </div>

                            </div>
                            <div className="text-center bg-light d-flex justify-content-around border-top">
                                {isInCart()
                                    // ? <button className="btn btn-primary btn-lg btn-block" onClick={e => e.preventDefault()}>Added</button>
                                    ? <Link to="/cart/' '/customer" className="btn btn-primary btn-lg btn-block">Go to cart</Link>
                                    : (cartData.length > 0
                                        ? cartData[0].editable === 1 && <>
                                            <div className="">
                                                <button
                                                    className="btn text-truncate text-left btn-add-to-cart"
                                                    onClick={e => handleAddToCart(e)}
                                                >Add to cart</button>
                                                <button
                                                    className="btn text-right btn-buy-now"
                                                    onClick={e => handleAddToCart(e, 'buynow')}
                                                >Buy Now</button>
                                            </div>
                                        </>
                                        : <>
                                            <div className="button-container">
                                                <button
                                                    className="btn btn-add-to-cart"
                                                    onClick={e => handleAddToCart(e)}
                                                >
                                                    Add to cart
                                                </button>
                                                <button
                                                    className="btn btn-buy-now"
                                                    onClick={e => handleAddToCart(e, 'buynow')}
                                                >
                                                    Buy Now
                                                </button>
                                            </div>

                                        </>)
                                }
                            </div>
                        </div>
                    </div>

                    <div className="col-12 col-xl-8 my-2">
                        <div className="px-3 py-2 border border-1">
                            <h4 className="my-2">{product.name}</h4>
                            {!product.discountValue || product.discountValue === '' || Number(product.discountValue) === 0 ?
                                <h4 className="fw-bold">&#8377;{Math.ceil(product.price)}</h4> :
                                <div>
                                    <span className="h4 fw-bold">&#8377;{Math.ceil(discountPrice(product.price, product.discountValue, product.discountMode))}</span>
                                    <del className="h4 text-muted mx-3">&#8377;{Math.ceil(product.price)}</del>
                                    <span className="fw-bold text-success fs-6">{product.discountLabel}</span>
                                </div>}
                            <div className="badge bg-success">{product.rating} &#9733;</div>
                            <p className="mt-3 remove-margin-bottom" dangerouslySetInnerHTML={{ __html: product.description }} />
                            <hr />
                            <ul>
                                {(product.warrantyMode !== '' && Number(product.warrantyMode) !== 0) && <li><span className="my-1 small">{product.warrantyLabel}</span></li>}
                                {(product.deliveryCharge !== '' && Number(product.deliveryCharge) !== 0) && <li><span className="my-1 small">&#8377;{product.deliveryCharge} Delivery charge</span></li>}
                            </ul>
                        </div>
                        {product.ratingAndReview?.length > 0 && <div className="mt-2 border border-1">
                            <div className="px-3 py-2  border-bottom">
                                <h6 className="m-0 text-muted">Ratings &amp; Reviews</h6>
                            </div>
                            <div className="px-3 py-2 border-bottom">
                                <div className="row">
                                    <div className="col d-flex align-items-center justify-content-center">
                                        <div className="p-4">
                                            <h1 className="text-dark text-center">{product.rating} &#9733;</h1>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="py-4">
                                            <p className=" mb-1">
                                                <span>5 &#9733;</span>
                                                <span style={{ width: '120px', height: '5px' }} className="mx-3 rounded-pill  d-inline-block position-relative">
                                                    <span style={{ width: ratingAvg(product.ratingAndReview, 5) + '%' }} className=" h-100 d-inline-block position-absolute top-0 start-0 rounded-pill"></span>
                                                </span>
                                                <span>{ratingCount(product.ratingAndReview, 5)}</span>
                                            </p>
                                            <p className=" mb-1">
                                                <span>4 &#9733;</span>
                                                <span style={{ width: '120px', height: '5px' }} className="mx-3 rounded-pill  d-inline-block position-relative">
                                                    <span style={{ width: ratingAvg(product.ratingAndReview, 4) + '%' }} className=" h-100 d-inline-block position-absolute top-0 start-0 rounded-pill"></span>
                                                </span>
                                                <span>{ratingCount(product.ratingAndReview, 4)}</span>
                                            </p>
                                            <p className=" mb-1">
                                                <span>3 &#9733;</span>
                                                <span style={{ width: '120px', height: '5px' }} className="mx-3 rounded-pill  d-inline-block position-relative">
                                                    <span style={{ width: ratingAvg(product.ratingAndReview, 3) + '%' }} className=" h-100 d-inline-block position-absolute top-0 start-0 rounded-pill"></span>
                                                </span>
                                                <span>{ratingCount(product.ratingAndReview, 3)}</span>
                                            </p>
                                            <p className=" mb-1">
                                                <span>2 &#9733;</span>
                                                <span style={{ width: '120px', height: '5px' }} className="mx-3 rounded-pill  d-inline-block position-relative">
                                                    <span style={{ width: ratingAvg(product.ratingAndReview, 2) + '%' }} className="bg-warning h-100 d-inline-block position-absolute top-0 start-0 rounded-pill"></span>
                                                </span>
                                                <span>{ratingCount(product.ratingAndReview, 2)}</span>
                                            </p>
                                            <p className=" mb-1">
                                                <span>1 &#9733;</span>
                                                <span style={{ width: '120px', height: '5px' }} className="mx-3 rounded-pill  d-inline-block position-relative">
                                                    <span style={{ width: ratingAvg(product.ratingAndReview, 1) + '%' }} className="bg-danger h-100 d-inline-block position-absolute top-0 start-0 rounded-pill"></span>
                                                </span>
                                                <span>{ratingCount(product.ratingAndReview, 1)}</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {product.ratingAndReview.map((rr, index) => {
                                return rr.review && Number(rr.rating) !== 0 && <div className="px-3 py-2 border-bottom" key={nanoid()}>
                                    <div className="d-flex justify-content-start align-items-center">
                                        <div color="success">{rr.rating} &#9733;</div>
                                        <span className="text-dark fw-bold  ms-2">{rr.name}</span>
                                    </div>
                                    <p className="m-0 my-2">{rr.review}</p>
                                </div>
                            })}
                        </div>}
                    </div>
                </div>}
        </div>
    </div>
}

export default CustomerProduct