import React from "react"
import { customerReqConfig } from "../../include/api"
import { getCustomer } from "../../include/function"
import ChangePassword from "../common/ChangePassword"

const CustomerChangePassword = () => {
    const user = getCustomer()
    const apiUrl = '/customer/?id=' + user.id + '&flag=changePassword'
    const reqConfig = customerReqConfig()

    return <ChangePassword
        apiUrl={apiUrl}
        reqConfig={reqConfig}
    />
}

export default CustomerChangePassword