import { nanoid } from "nanoid";
import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Switch from 'react-switch';
import { api, baseURL, superAdminReqConfig } from "../../include/api";
import { getSuperAdmin } from "../../include/function";
import Error from "../add-ons/Error";
import Spinner from "../add-ons/Spinner";
import SuperAdminHeader from "./Header";

const SuperAdminProduct = () => {
    const user = getSuperAdmin();

    const [fetchingProducts, setFetchingProducts] = useState(true);
    const [productList, setProductList] = useState([]);
    const [activeTab, setActiveTab] = useState('active');
    const [loadingStatusChange, setLoadingStatusChange] = useState(false);

    // const canAddProduct = user?.roleProperties?.some(rp => rp.pageUrl === "/admin/product" && rp.add === "true") || user.createdBy === 'super';
    // const canEditProduct = user?.roleProperties?.some(rp => rp.pageUrl === "/admin/product" && rp.edit === "true") || user.createdBy === 'super';

    const getProducts = useCallback(() => {
        setFetchingProducts(true);
        api.get('/product/', superAdminReqConfig()).then(response => {
            if (response.status === 200) {
                setProductList(response.data.data);
            }
        }).catch(error => {
            setProductList([]);
            console.error("Error fetching products:", error);
        }).finally(() => setFetchingProducts(false));
    }, []);

    useEffect(() => {
        getProducts();
    }, [getProducts]);

    // Handles the status change of the product
    const handleStatusChange = (productId, newStatus) => {
        setLoadingStatusChange(true);
        api.put(`/product_status/?id=${productId}`, { status: newStatus }, superAdminReqConfig())
            .then(response => {
                if (response.status === 200) {
                    getProducts();
                }
            })
            .catch(error => {
                console.error("Error updating product status:", error);
            })
            .finally(() => setLoadingStatusChange(false));
    };

    // Filter products based on the selected tab (active/inactive/not approved)
    const filteredProducts = productList.filter(product => product.status === activeTab);

    return (
        <>
            <SuperAdminHeader>
                <div className="fixed-top-bar" style={{ marginBottom: "10%" }}>
                    <div className="container my-1 mx-1">
                        <div className="py-3 d-flex justify-content-between align-items-center">
                            <h5 className="m-0">Product</h5>
                            <Link to="/super/add-product" className="btn btn-success">Add new product</Link>
                        </div>
                        <div className="mb-3">
                            <ul className="nav nav-tabs" id="myTab" role="tablist">
                                {['active', 'inactive', 'not approved'].map(tab => (
                                    <li className="nav-item" role="tab" key={tab}>
                                        <button
                                            className={`nav-link ${activeTab === tab ? 'active' : ''}`}
                                            onClick={() => setActiveTab(tab)}
                                        >
                                            {tab.charAt(0).toUpperCase() + tab.slice(1)}
                                        </button>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        {fetchingProducts ? (
                            <Spinner color="danger" spinnerSize="lg" />
                        ) : (
                            filteredProducts.length > 0 ? (
                                <div className="row tab-content">
                                    {filteredProducts.map((product) => (
                                        <Product
                                            product={product}
                                            // canEditProduct={canEditProduct}
                                            canEditProduct={true}
                                            handleStatusChange={handleStatusChange}
                                            loadingStatusChange={loadingStatusChange}
                                            key={product.id}
                                        />
                                    ))}
                                </div>
                            ) : (
                                <Error
                                    mainText="No product found!"
                                    secondaryText={<Link to="/super/add-product">Add New product</Link>}
                                // secondaryText={canAddProduct ? <Link to="/super/add-product">Add New product</Link> : null}
                                />
                            )
                        )}
                    </div>
                </div>
            </SuperAdminHeader>
        </>
    );
};

const Product = ({ product, canEditProduct, handleStatusChange, loadingStatusChange }) => {
    const getNextStatus = (currentStatus) => {
        switch (currentStatus) {
            case 'not approved':
                return 'active';
            case 'active':
                return 'inactive';
            case 'inactive':
                return 'active';
            default:
                return currentStatus;
        }
    };

    const isActive = product.status === 'active';
    const isNotApproved = product.status === 'not approved';

    return (
        <div className="col-12 col-sm-6 col-lg-4 p-2">
            <div className="border shadow-sm w-100 h-100 d-block">
                <div className="img-nor w-100 p-3 border-bottom d-flex justify-content-center">
                    <img src={`${baseURL}/${product.mainImage}`} alt={product.name} className="fit-contain w-100 h-100" />
                </div>
                <div className="p-2">
                    <h6 className="d-flex align-items-center justify-content-between fw-bold">
                        <div className="text-truncate">
                            <i className={`fas fa-circle blink ${isActive ? 'text-success' : product.status === 'inactive' ? 'text-danger' : 'text-warning'}`} style={{ fontSize: '.7rem' }} />
                            <span className="ps-2">{product.name}</span>
                        </div>
                        {canEditProduct && (
                            <Link to={`/admin/edit-product/${product.id}`} className="small d-flex align-items-center hover-underline">Edit <i className="fas fa-pencil-alt fa-sm ps-2" /></Link>
                        )}
                    </h6>
                    <div className="mb-1 text-truncate ps-2">
                        <span className="badge bg-success">{product.rating} &#9733;</span>
                        <span className="small text-muted fw-bold ms-3">&#8377;{product.price}</span>
                    </div>
                    <ul className="small text-muted ps-2 m-0">
                        <li className="text-truncate">
                            Delivery Charge: {(product.deliveryCharge && product.deliveryCharge !== 0) ? <>&#8377;{product.deliveryCharge}</> : 'FREE'}
                        </li>
                        <li className="text-truncate">
                            Offer: {product.discountValue !== 0 && product.discountLabel ? product.discountLabel : 'No Offer'}
                        </li>
                        <li className="text-truncate">
                            Warranty: {product.warrantyPeriod !== 0 && product.warrantyLabel ? product.warrantyLabel : 'No Warranty'}
                        </li>
                    </ul>
                </div>
                <div className="p-2 hide-scrollbar" style={{ overflowX: 'auto', whiteSpace: 'nowrap' }}>
                    {product.images && product.images.length > 0 && product.images.map(image => (
                        <ProductImage
                            src={`${baseURL}/${image.imageUrl}`}
                            key={nanoid()}
                        />
                    ))}
                </div>
                <div className="p-2 d-flex justify-content-center align-items-center">
                    {isNotApproved ? (
                        <button
                            className="btn btn-success"
                            onClick={() => handleStatusChange(product.id, getNextStatus(product.status))}
                            disabled={loadingStatusChange} // Disable during loading
                        >
                            Approve
                        </button>
                    ) : (
                        <>
                            <Switch
                                checked={isActive}
                                onChange={() => handleStatusChange(product.id, getNextStatus(product.status))}
                                disabled={loadingStatusChange} // Disable during loading
                                onColor="#4caf50"
                                offColor="#f44336"
                                onHandleColor="#ffffff"
                                offHandleColor="#ffffff"
                                checkedIcon={<div style={checkedIconStyle}></div>}
                                uncheckedIcon={<div style={uncheckedIconStyle}></div>}
                                handleDiameter={22}
                                height={28}
                                width={48}
                                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                            />
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

const checkedIconStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    fontSize: 12,
    color: 'white',
    paddingRight: 2,
    paddingLeft: 2,
    transition: 'transform 0.3s ease',
    transform: 'translateX(-2px)' // Position for active state
};

const uncheckedIconStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    fontSize: 12,
    color: 'white',
    paddingRight: 2,
    paddingLeft: 2,
    transition: 'transform 0.3s ease',
    transform: 'translateX(2px)' // Position for inactive state
};

const ProductImage = ({ src }) => {
    return (
        <div className="form-control me-2 p-0 d-inline-block" style={{ width: '60px', height: '60px' }}>
            <img src={src} alt="" className="fit-contain w-100 h-100" />
        </div>
    );
};

export default SuperAdminProduct;
