import { nanoid } from "nanoid"
import React, { useEffect, useState } from "react"
import { api, superAdminReqConfig } from "../../include/api"
import { ERROR, SUCCESS } from "../../include/constant"
import { isEmptyObj, preventClick } from "../../include/function"
import { useNotification } from "../../Toast/ToastProvider"
import Error from "../add-ons/Error"
import Spinner from "../add-ons/Spinner"
import SuperAdminHeader from "./Header"

const SuperAdminHomePage = () => {
    const toast = useNotification()

    const [postAndPutloading, setPostAndPutloading] = useState(false)
    const [fetchLoading, setFetchLoading] = useState(true)
    const [showForm, setShowForm] = useState(false)
    const [selectedProducts, setSelectedProducts] = useState([])
    const [selectedCategoryId, setSelectedCategoryId] = useState('')
    const [homeCategoryList, setHomeCategoryList] = useState([])
    const [categoryName, setCategoryName] = useState('')

    useEffect(() => {
        getHomeCategoryList()
    }, [])

    const getHomeCategoryList = (afterGet) => {
        setFetchLoading(true)
        api.get('/category/?flag=getHomeAndChilds', superAdminReqConfig()).then(response => {
            if (response.status === 200) {
                setHomeCategoryList(response.data.data[0])
                if (afterGet) afterGet(response.data.data[0])
            }
        }).catch(error => {
            setHomeCategoryList([])
            if (afterGet) afterGet([])
        }).finally(() => setFetchLoading(false))
    }
    const handleCancel = () => setShowForm(false)

    const handleSave = () => {
        const data = {
            categoryId: selectedCategoryId,
            productIdArray: selectedProducts,
        }

        setPostAndPutloading(true)
        api.post('/prod_cat_mapping/?flag=homeCatChange', data, superAdminReqConfig()).then(response => {
            if (response.status === 200) {
                getHomeCategoryList(() => {
                    handleCancel()
                    toast({ type: SUCCESS, message: response.data.message })
                })
            }
        }).catch(error => {
            error.response ?
                toast({ type: ERROR, message: error.response.data.message }) :
                toast({ type: ERROR, message: error.message })
        }).finally(() => setPostAndPutloading(false))
    }

    return <SuperAdminHeader >
        <div className="fixed-top-bar">
            <div className="container my-2 ">
                <div className="py-3">
                    <h5 className="m-0">Home Page</h5>
                </div>
                {showForm && <div className="d-flex align-items-center">
                    {postAndPutloading ?
                        <button className="btn btn-primary me-3 d-flex align-items-center justify-content-center" onClick={e => e.preventDefault()}>
                            <Spinner spinnerSize="sm" color="white" />
                            <span className="ms-2">Save</span>
                        </button> :
                        <button className="btn btn-primary me-3" onClick={e => preventClick(e, handleSave)}>Save</button>}
                    <button className="btn btn-light" onClick={e => preventClick(e, handleCancel)}>Cancel</button>
                </div>}
            </div>
            <div className="container my-3">
                {showForm ? <EditHomePage
                    selectedProducts={selectedProducts}
                    setSelectedProducts={setSelectedProducts}
                    categoryName={categoryName}
                /> : fetchLoading ?
                    <Spinner color="danger" /> :
                    !isEmptyObj(homeCategoryList) ?
                        <div className="row">
                            {homeCategoryList.childs.map((child, index) => {
                                return <CategoryList
                                    child={child}
                                    setSelectedCategoryId={setSelectedCategoryId}
                                    setSelectedProducts={setSelectedProducts}
                                    setShowForm={setShowForm}
                                    setCategoryName={setCategoryName}
                                    key={nanoid()}
                                />
                            })}
                        </div> : <Error mainText="No results found!" />}
            </div>
        </div>
    </SuperAdminHeader>
}

const CategoryList = ({ child, setSelectedProducts, setSelectedCategoryId, setShowForm, setCategoryName }) => {
    const showDetails = () => {
        if (child.products && child.products.length > 0) {
            const selectedProducts = child.products.map(product => product.productId)
            setSelectedProducts(selectedProducts)
        } else setSelectedProducts([])
        setSelectedCategoryId(child.id)
        setCategoryName(child.name)
        setShowForm(true)
    }

    return <div className="col-12 mb-5">
        <div className="w-100 mb-2 border border-1 shadow-sm p-3 bg-hover cursor-pointer" onClick={() => showDetails()}>
            <h6 className="fw-bold">{child.name}</h6>
            <ul>

            </ul>
            {child.products && child.products.map((product, index) => {
                return <Product
                    name={product.name}
                    shopName={product.shopName}
                    key={nanoid()}
                />
            })}
        </div>
    </div>
}

const Product = ({ shopName, name }) => {
    return <li><span className="text-truncate">{shopName} - {name}</span></li>
}

const EditHomePage = ({ selectedProducts, setSelectedProducts, categoryName }) => {
    const [shops, setShops] = useState([])
    const [products, setProducts] = useState([])
    const [fetchLoading, setFetchLoading] = useState(true)

    useEffect(() => {
        getShops()
        getProducts()
    }, [])

    const getShops = (afterGet) => {
        setFetchLoading(true)
        api.get('/shop/', superAdminReqConfig()).then(response => {
            if (response.status === 200) {
                setShops(response.data.data)
                if (afterGet) afterGet(response.data.data)
            }
        }).catch(error => {
            setShops([])
            if (afterGet) afterGet([])
        }).finally(() => setFetchLoading(false))
    }

    const getProducts = (afterGet) => {
        setFetchLoading(true)
        api.get('/product/', superAdminReqConfig()).then(response => {
            if (response.status === 200) {
                setProducts(response.data.data)
                if (afterGet) afterGet(response.data.data)
            }
        }).catch(error => {
            setProducts([])
            if (afterGet) afterGet([])
        }).finally(() => setFetchLoading(false))
    }

    return fetchLoading ? <div className="p-5"><Spinner color="danger" /></div> : <div className="w-100">
        <h5>{categoryName}</h5>
        {shops.map((shop, index) => <ShopList
            products={products}
            selectedProducts={selectedProducts}
            setSelectedProducts={setSelectedProducts}
            shop={shop}
            key={nanoid()}
        />)}
    </div>
}

const ShopList = ({ shop, products, selectedProducts, setSelectedProducts }) => {
    return <div className="w-100 border border-1 shadow-sm p-3 my-2">
        <h6>{shop.name}</h6>
        {products.map((product, index) => String(shop.id) === String(product.shopId) && <ProductList
            product={product}
            selectedProducts={selectedProducts}
            setSelectedProducts={setSelectedProducts}
            key={nanoid()}
        />)}
    </div>
}

const ProductList = ({ product, selectedProducts, setSelectedProducts }) => {
    const [checked, setChecked] = useState(false)

    useEffect(() => {
        if (selectedProducts) {
            selectedProducts.forEach(id => {
                if (String(id) === String(product.id)) {
                    setChecked(true)
                }
            })
        }
    }, [product.id, selectedProducts])

    const handleCheck = () => {
        if (checked) setSelectedProducts(selectedProducts.filter(id => String(id) !== String(product.id)))
        else setSelectedProducts([...selectedProducts, product.id])
    }

    return <div className="d-flex align-items-center">
        <input type="checkbox" name={product.name} id={product.name + product.id} className="m-0 mx-3 form-check-input" onChange={e => handleCheck()} checked={checked} />
        <span className="text-truncate">{product.name}</span>
    </div>
}

export default SuperAdminHomePage