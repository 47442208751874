import React from "react"
import ChangePassword from "./ChangePassword"
import AdminHeader from "./Header"


const AdminProfile = () => {
    return <>
        <AdminHeader><div className="fixed-top-bar">
        <ChangePassword />
        </div>
        </AdminHeader>
    </>
}

export default AdminProfile