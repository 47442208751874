import { nanoid } from "nanoid"
import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { api, baseURL, superAdminReqConfig } from "../../include/api"
import Error from "../add-ons/Error"
import Spinner from "../add-ons/Spinner"
import SuperAdminHeader from "./Header"

const SuperAdminBrand = () => {
    const [fetchLoading, setFetchLoading] = useState(true)
    const [brandList, setBrandList] = useState([])

    useEffect(() => {
        getBrand()
    }, [])

    const getBrand = (afterGet) => {
        setFetchLoading(true)
        api.get("/category/", superAdminReqConfig()).then(response => {
            if (response.status === 200) {
                setBrandList(response.data.data)
                if (afterGet) afterGet(response.data.data)
            }
        }).catch(error => {
            setBrandList([])
            if (afterGet) afterGet([])
        }).finally(() => setFetchLoading(false))
    }

    return <SuperAdminHeader >
        <div className="fixed-top-bar">
            <div className="container mt-2 mb-5">
                <div className="py-3 d-flex justify-content-between align-items-center">
                    <h5 className="m-0">Brand</h5>
                    <Link to="/super/add-brand" className="btn btn-success" >Add new Brand</Link>
                </div>
                {fetchLoading ?
                    <Spinner color="danger" spinnerSize="lg" /> :
                    brandList.length > 0 ?
                        <div className="row">
                            {brandList.filter(f => String(f.parentId) === '-1').map((brand, index) => {
                                return <Brand brand={brand} key={nanoid()} />
                            })}
                        </div> : <Error mainText="No brand found!" secondaryText={<Link to="/super/add-brand">Add New brand</Link>} />}
            </div>
        </div>
    </SuperAdminHeader>
}

const Brand = ({ brand }) => {
    return <div className="col-12 col-md-6 col-lg-4 col-xl-3 p-2">
        <div className="border shadow-sm w-100 h-100 d-block">
            <div className="img-md w-100 p-3 border-bottom d-flex justify-content-center">
                <img src={baseURL + "/" + brand.imageUrl} alt="" className="fit-contain w-100 h-100" />
            </div>
            <div className="p-2">
                <h6 className="d-flex align-items-center justify-content-between fw-bold">
                    <div className="text-truncate">{brand.name}</div>
                    <Link to={`/super/edit-brand/${brand.id}`} className="small d-flex align-items-center hover-underline">Edit <i className="fas fa-pencil-alt fa-sm ps-2" />
                    </Link>
                </h6>
                {/* <p className="small mb-1 text-truncate text-muted">Parent: {brand.name === brand.parentName ? "Itself" : brand.parentName}</p> */}
                <p className="text-truncate small m-0 remove-margin-bottom" >{brand.description}</p>
            </div>
        </div>
    </div>
}

export default SuperAdminBrand